import React, {useEffect, useState} from "react";
import { Container, Spinner } from "react-bootstrap";
import { validateModuleAccess } from "./utils/InstancePy";


export default function ValidateLoader(props) {
  const [isValid, setIsValid] = useState(false);
  
  useEffect(()=>{
    validateModuleAccess(props.location.pathname).then((callResp)=>{
      if(callResp){
        setIsValid(callResp)
      }else{
        props.history.push("/modulos")
      }
    }).catch(error=>console.log(error));
  },[]);

  return (
    <>
      <Container fluid={true} style={{ 
        backgroundColor: "#FFFFFF",
        position: "fixed",
        zIndex: 99999,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: (isValid ? "None" : "") 
        }}>
        <Spinner animation="border" role="status" style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-50px",
              marginLeft: "-50px",
              width: "100px",
              height: "100px",
              color: "#137b43"
        }}>
          <span className="sr-only">Cargando...</span>
        </Spinner>
      </Container>
    </>
  )
}