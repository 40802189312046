import React, { useState, useEffect } from 'react';
import { Container, Card, Spinner } from 'react-bootstrap';
import { instance_py } from '../utils/InstancePy';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import NavBar from '../NavBar';
import ValidateLoader from '../ValidateLoader';
import ModalPagosProveedores from './ModalPagosProveedores';

import $ from 'jquery';
import './ListaProcesosContratacion.css'
import Axios from 'axios';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

function formatAmount(cell, row, rowIndex, formatExtraData) {
  return cell !== null ? parseFloat(cell).toLocaleString('en') : 0;
};

function formatLongString(cell, row, rowIndex, formatExtraData) {
  return (
    <>
      {cell.length > 50 ?
        <div className="input-group">
          <input size={20} type="text" value={cell ? cell : 'N/E'} className="form-control" data-toggle="tooltip" data-placement="bottom" title={cell ? cell : 'N/E'} readonly
            style={{
              background: "transparent",
              border: "1px solid transparent",
              borderBottom: "1px solid #212529",
              color: "#212529",
            }} />
        </div>
        : <></>
      }
    </>
  );
}

const SpinnerLoader = (props) => {
  return (
    <Spinner animation="border" role="status" style={{
      position: props.position,
      top: "50%",
      left: "50%",
      marginTop: "-50px",
      marginLeft: "-50px",
      width: "100px",
      height: "100px",
      color: "#137b43"
    }}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  )
}

SpinnerLoader.defaultProps = {
  position: "absolute",
  // horizontal: false,
  //size: "xl"
};

export default function ListaProcesosContratacion(props) {
  const [arrProcesosContratacion, setArrProcesosContratacion] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [proceso, setProceso] = useState({});

  const columns = [
    //{ dataField: 'id', text: 'ID', sort: true },
    { dataField: 'numero_contrato', text: 'Número de Contrato', sort: true, filter: textFilter() },
    { dataField: 'nombre', text: 'Nombre', formatter: formatLongString, filter: textFilter() },
    { dataField: 'numero_proceso', text: 'Número del Proceso', sort: true, filter: textFilter() },
    {
      dataField: 'proveedor', text: 'Proveedor', sort: true, events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.proveedor !== null) {
            setProceso(row);
            setModalShow(true);
          }
        }
      },
      classes: "table-cell-effect", filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <div>{cell ? cell : 'N/E'}</div>
        )
      }
    },
    { dataField: 'estado', text: 'Estado', sort: true, filter: textFilter() },
    { dataField: 'fecha_inicio', text: 'Fecha de Inicio', sort: true, filter: textFilter() },
    { dataField: 'fecha_inicio_tentativa', text: 'Fecha Tentativa de Inicio', sort: true, filter: textFilter() },
    { dataField: 'avance_tdr', text: 'Avance del TDR', formatter: formatAmount, sort: true, filter: textFilter() },
    { dataField: 'porcentaje_avance', text: 'Porcentaje de Avance', sort: true, filter: textFilter() },
    { dataField: 'total_contrato', text: 'Monto Total del Contrato', formatter: formatAmount, sort: true, filter: textFilter() },
    { dataField: 'departamento', text: 'Departamento', sort: true, filter: textFilter() },
    { dataField: 'es_migrado', text: 'Contrato Migrado', sort: true, filter: textFilter() },
    { dataField: 'ced_ruc', text: 'Cédula/RUC', filter: textFilter() },
    { dataField: 'presupuesto_anual', text: 'Presupuesto Anual', formatter: formatAmount, sort: true, filter: textFilter() },
    { dataField: 'monto_referencial', text: 'Monto Referencial', formatter: formatAmount, sort: true, filter: textFilter() },
    { dataField: 'es_plurianual', text: 'Certificado Plurianual', sort: true, filter: textFilter() },
    { dataField: 'anticipo', text: 'Anticipo', formatter: formatAmount, sort: true, filter: textFilter() },
  ];

  useEffect(() => {
    listProcesoContratacion();
  }, [])


  function listProcesoContratacion() {
    instance_py.get('/v1/egob/financier/listProcesosContratacion')
      .then(resp => {
        setArrProcesosContratacion(resp.data.data);
      })
      .catch(error => {
        alert(error);
      });
  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const ExportCSVButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div style={{ marginBottom: 10 }}>
        <button style={{ backgroundColor: 'rgb(21, 122, 68)' }} className="btn btn-success"
          onClick={handleClick}>Exportar a CSV
        </button>
        <button style={{ backgroundColor: 'rgb(21, 122, 68)', marginLeft: 10 }}
          className="btn btn-success"
          onClick={() => exportToCSV(arrProcesosContratacion, 'Procesos_de_Contratacion')}>Exportar a Excel
        </button>
      </div>
    );
  };

  const contentTableProcesosContratacion = ({ paginationProps, paginationTableProps }) => {

    return (
      <div>
        <ToolkitProvider
          keyField="id"
          columns={columns}
          data={arrProcesosContratacion}
          search
          exportCSV={{
            fileName: 'procesos_de_contratacion.csv',
            onlyExportFiltered: true,
            exportAll: false,
            noAutoBOM: false,
            blobType: "data:text/csv;charset=utf-8"
          }}
        >
          {
            toolkitprops => (
              <div>
                <ExportCSVButton {...toolkitprops.csvProps} />
                      {/* <div className='table-wrap'></div> */}
                      <BootstrapTable                    
                        bootstrap4
                        classes='table-responsive'
                        filter={filterFactory()}
                        striped
                        hover
                        // wrapperClasses='table-wrap'
                        {...toolkitprops.baseProps}
                        {...paginationTableProps}
                      />
              </div>
            )
          }
        </ToolkitProvider>
      </div>)
  };


  return (
    <>
      <ValidateLoader {...props} />
      <NavBar {...props} />
      <Container style={{ marginTop: 15 }}>
        <div style={{ padding: "0.5rem 0rem" }}>
          <Card>
            <Card.Header>
              <Card.Title>
                <h2 style={{ textAlign: "center" }}>Procesos de Contratación</h2>
              </Card.Title>
            </Card.Header>
          </Card>
        </div>
        {arrProcesosContratacion.length > 0 ?
          <>
            <PaginationProvider
              pagination={
                paginationFactory()
              }
            >{contentTableProcesosContratacion}
            </PaginationProvider>
            <>
              {modalShow && <ModalPagosProveedores
                show={modalShow}
                onHide={() => setModalShow(false)}
                proceso={proceso}
                setProceso={setProceso}
              />
              }
            </>
          </>
          : <SpinnerLoader />
        }
      </Container>
    </>
  );
}

