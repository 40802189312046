import {
  Container,
  Button,
  Form,
  Col,
  Alert,
  Spinner,
  Image,
  Toast,
  Row,
  Modal,
  Badge,
  Table,
  Card,
} from "react-bootstrap";
import Recaptcha from "react-recaptcha";
import React, { useState } from "react";
import LogoGPM from "./assets/imgs/logo.png";
import instance from "./utils/Instance";
import { isIdentificationValid } from "./utils/Validator";

export default function FormularioOnline() {
  const [isverifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [numeroCedula, setNumeroCedula] = useState("");
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [institucion, setInstitucion] = useState("");
  const [cargo, setCargo] = useState("");
  const [email, setEmail] = useState("");
  const [validIdentificacion, IsValidIdentificacion] = useState(false);

  function recaptchaLoaded() {
    console.log("capcha successfully loaded");
  }

  function verifyCallback(response) {
    if (response) {
      setVerifiedCaptcha(true);
    }
  }

  function sendInfFormulario() {}

  function toInputUppercase(e) {
    e.target.value = ("" + e.target.value).toUpperCase();
  }

  return (
    <Container style={{ marginTop: 15 }}>
      <Alert variant="success" style={{ backgroundColor: "#157a44" }}>
        <Alert.Heading
          style={{ justifyContent: "center", textAlign: "center" }}
        >
          <p style={{ color: "white", fontSize: "larger", fontWeight: "700" }}>
            Formulario para registro de asistencia Avances en la formulacion del
            Plan de Desarrollo y Ordenamiento Territorial
          </p>
        </Alert.Heading>
        <div style={{ justifyContent: "center", textAlign: "center" }}>
          <Image
            style={{ textAlign: "center", filter: "brightness(500%)" }}
            src={LogoGPM}
            fluid
          />
        </div>
        <hr />
      </Alert>
      <div>
        <p style={{ fontWeight: "bold" }}>
          Avances en la formulacion del Plan de Desarrollo y Ordenamiento
          Territorial MANABI 5i
        </p>
        <ul>
          <li>Lugar: Universidad Tecnica de Manabi</li>
          <li>Fecha Inicio: 12/08/2021 </li>
          <li>Fecha Finalizacion: 12/08/2021 </li>
          <li>Numero de horas: 4 horas</li>
          <li>Modalidad: Presencial</li>
          <li>Horario: 09h:00 - 13h:00</li>
        </ul>
      </div>
      <Card border="success" className="pr-0 pl-0 col-md-12 mb-4">
        <Card.Header style={{ fontWeight: "bold" }}>
          Registro de informacion
        </Card.Header>
        <Card.Body>
          <div className="row">
            <Form.Group as={Col} md="6">
              <Form.Label>Numero de cedula</Form.Label>
              <Form.Control
                type="text"
                placeholder="INGRESE INFORMACION ..."
                value={numeroCedula}
                required
                isInvalid={!validIdentificacion}
                isValid={validIdentificacion && numeroCedula.length === 10}
                onChange={(event) => {
                  setNumeroCedula(event.target.value);
                  if (event.target.value.length === 10) {
                    IsValidIdentificacion(
                      isIdentificationValid(event.target.value.trim())
                    );
                  }
                }}
                maxLength={10}
                minLength={10}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Nombres Completos</Form.Label>
              <Form.Control
                type="text"
                placeholder="INGRESE INFORMACION ..."
                value={nombreCompleto}
                onChange={(event) => setNombreCompleto(event.target.value)}
                onInput={toInputUppercase}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Institucion a la que pertenece</Form.Label>
              <Form.Control
                type="text"
                placeholder="INGRESE INFORMACION ..."
                value={institucion}
                onChange={(event) => setInstitucion(event.target.value)}
                onInput={toInputUppercase}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Cargo</Form.Label>
              <Form.Control
                type="text"
                placeholder="INGRESE INFORMACION ..."
                value={cargo}
                onChange={(event) => setCargo(event.target.value)}
                onInput={toInputUppercase}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Correo electronico</Form.Label>
              <Form.Control
                type="text"
                placeholder="INGRESE INFORMACION ..."
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>
          </div>
          <Button onClick={sendInfFormulario} variant="success">
            Enviar informacion
          </Button>
        </Card.Body>
      </Card>
      <Recaptcha
        sitekey="6LfKEN0bAAAAAOSHudjMdu6Z19Bs4oHmW77mc6Xc"
        render="explicit"
        onloadCallback={recaptchaLoaded}
        verifyCallback={verifyCallback}
        hl="es"
        size="normal"
      />
    </Container>
  );
}
