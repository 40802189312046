import { instance_py } from "../../utils/InstancePy";

export const updateTransaction = async (id) => {
  try {
    const response = await instance_py.patch(
      `/v1/print_roll/transactions/validate/${id}`,
      {},
      {
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    window.open(url);

    return response;
  } catch (error) {
    console.error("Error al actualizar la transacción:", error);
    return { error: error };
  }
};
