import React, { Component } from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import instance from './utils/Instance';
import LogoGPM from './assets/imgs/logo.png';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { decodeJWT_RS256 } from './utils/Utilities';
import NavBar from './NavBar';
import ValidateLoader from './ValidateLoader';

const columns = [
  { dataField: 'cedula', text: 'Número de cédula', sort: true },
  { dataField: 'nombrecompleto', text: 'Nombres Completos' },
  { dataField: 'fechanacimiento', text: 'Fecha Nacimiento' },
  { dataField: 'edad', text: 'Edad' },
  { dataField: 'etnia', text: 'Etnia' },
  { dataField: 'sexo', text: 'Sexo' },
  { dataField: 'direcciondomiciliaria', text: 'Dirección Domiciliaria' },
  { dataField: 'correo', text: 'Correo Personal' },
  { dataField: 'estadocivil', text: 'Estado Civil' },
  { dataField: 'tiposangre', text: 'Tipo Sangre' },
  { dataField: 'telefonoconvencional', text: 'Telf. Convencional' },
  { dataField: 'telefonomovil', text: 'Telf. Celular' },
  { dataField: 'formacionacademica', text: 'Formación Académica' },
  { dataField: 'cargoactual', text: 'Cargo Actual' },
  { dataField: 'numerohijos', text: 'Número Hijos' },
  { dataField: 'cedulaconyugue', text: 'Número de cédula Conyugue' },
  { dataField: 'nombrecompletoconyugue', text: 'Nombres Apellidos Conyugue' },
  { dataField: 'especializacion', text: 'Especialización' },
  { dataField: 'rolempleado', text: 'Rol' },
  { dataField: 'fecharegistro', text: 'Fecha Actualización' },
];

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export default class ListaDonaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrPersonasTalentoHumano: [],
      informationUser: {}
    }
  }

  componentDidMount() {
    const { history } = this.props;
    this.listDonaciones();
    const configuration = localStorage.getItem('auth-jwt');
    if (configuration) {
      const objectJwt = decodeJWT_RS256(configuration);
      if (objectJwt.exp < Date.now() / 1000) {
        localStorage.clear('auth-jwt');
        history.push('/login');
      } else {
        this.setState({
          informationUser: objectJwt.sub !== undefined ? objectJwt.sub : objectJwt.data 
        });
      }
    }
  }

  exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  listDonaciones = () => {
    instance.get('talentohumano/obtenerdatospersonaactualizada')
      .then(resp => {
        const { response } = resp.data;
        this.setState({ arrPersonasTalentoHumano: response });
      })
      .catch(error => {
        alert('Hubo un error');
      });
  }

  render() {
    const { arrPersonasTalentoHumano, informationUser } = this.state;
    const { history } = this.props;

    const ExportCSVButton = (props) => {
      const handleClick = () => {
        props.onExport();
      };

      return (
        <div>
          <button style={{ marginTop: 5, backgroundColor: 'rgb(21, 122, 68)' }} className="btn btn-success" onClick={handleClick}>Exportar a CSV</button>
          <button style={{ marginTop: 5, backgroundColor: 'rgb(21, 122, 68)', marginLeft: 10 }} className="btn btn-success" onClick={() => this.exportToCSV(arrPersonasTalentoHumano, 'lista_empleados')}>Exportar a Excel</button>
        </div>
      );
    };

    const SearchBar = (props) => {
      let input;
      const handleClick = () => {
        props.onSearch(input.value);
      };

      return (
        <div>
          <input
            className="form-control"
            style={{ marginTop: 8 }}
            ref={n => input = n}
            type="text"
            onChange={handleClick}
            placeholder='Realice su busqueda ..'
          />
        </div>
      );
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <ToolkitProvider
          keyField="fecharegistro"
          columns={columns}
          data={arrPersonasTalentoHumano}
          search
          exportCSV={{
            fileName: 'actualizacion-talentohumano-gpm.csv',
            onlyExportFiltered: true,
            exportAll: false,
            noAutoBOM: false,
            blobType: "data:text/csv;charset=utf-8"
          }}
        >
          {
            toolkitprops => (
              <div>
                <ExportCSVButton {...toolkitprops.csvProps} />
                <SearchBar {...toolkitprops.searchProps} />
                <BootstrapTable
                  bootstrap4
                  classes='table-responsive'
                  striped
                  hover
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );

    return (
      <>
      <ValidateLoader {...this.props} />
      <NavBar {...this.props} />
        <Container style={{ marginTop: 15 }}>
          <PaginationProvider
            pagination={
              paginationFactory()
            }
          >{contentTable}
          </PaginationProvider>
        </Container>
      </>
    );
  }
}