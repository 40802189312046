import axios from "axios";
const AxiosInstance = axios.create({
  baseURL: "https://apiservices.manabi.gob.ec/api-encuestas_psicosociales",
  //baseURL: "http://localhost:1515",
  timeout: 8000,
  headers: {
    Accept: "application/json",
  },
});

const validateDocument = async (document, year = new Date().getFullYear()) => {
  const response = await AxiosInstance.get(
    `/public_servant/validate_document`,
    {
      params: {
        document,
        year,
      },
    }
  );
  return response;
};

const sendPsychosocialSurvey = async (data) => {
  const response = await AxiosInstance.post("/poll_response/", data);
  return response;
};

const getDepartments = async () => {
  const response = await AxiosInstance.get("/department/");
  return response;
};

const getPollGroupQuestions = async () => {
  const response = await AxiosInstance.get("/poll_group_questions/");
  return response;
};

export {
  validateDocument,
  sendPsychosocialSurvey,
  getDepartments,
  getPollGroupQuestions,
};
