import React from 'react';
import './assets/styles/marker.css';


const Marker = (props) => {
  debugger;
  const { color, name, id, onHeaderClick } = props;


  return (
    <div>
      <div
        className="pin bounce"
        style={{ backgroundColor: color, cursor: 'pointer' }}
        title={name}
        onClick={() => {
          onHeaderClick(true);
        }}
      />
      <div className="pulse" />
    </div>
  );
};

export default Marker;