import React, { Component } from "react";
import {
  Alert,
  Container,
  Form,
  Image,
  Col,
  Tab,
  Nav,
  Row,
  Spinner,
  Button,
  Modal,
  Badge,
} from "react-bootstrap";
import Recaptcha from "react-recaptcha";

import LogoGPM from "./assets/imgs/logo.png";
import { currentDate } from "./utils/Validator";
import instance from "./utils/Instance";

const ciudades = [
  "24 DE MAYO",
  "BOLÍVAR",
  "CHONE",
  "EL CARMEN",
  "FLAVIO ALFARO",
  "JAMA",
  "JARAMIJO",
  "JIPIJAPA",
  "JUNÍN",
  "MANTA",
  "MONTECRISTI",
  "OLMEDO",
  "PAJÁN",
  "PEDERNALES",
  "PICHINCHA",
  "PORTOVIEJO",
  "PUERTO LÓPEZ",
  "ROCAFUERTE",
  "SAN VICENTE",
  "SANTA ANA",
  "SUCRE",
  "TOSAGUA",
];

const optionsGlobalAnswers = [
  { value: 4, descripcion: "Completamente de Acuerdo" },
  { value: 3, descripcion: "Parcialmente de Acuerdo" },
  { value: 2, descripcion: "Poco de acuerdo" },
  { value: 1, descripcion: "En desacuerdo" },
];

export default class EncuestaOcupacional extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  state = {
    fecha: currentDate(),
    provincia: "MANABI",
    validated: false,
    ciudad: "",
    areatrabajo: "",
    nivelinstruccion: "",
    tiempoantiguedadempresa: "",
    rangoedad: "",
    autoidentificacionetnica: "",
    genero: "",
    isSubmitted: false,
    isverifiedCaptcha: false,
    arrGrupos: [],
    showAlert: true,
    showAutorizacionEncuesta: true,
    cedulaidentidad: "",
    isSubmitAuth: false,
    sentsurvey: false,
  };

  componentDidMount() {
    this._isMounted = true;
    // const { cedulaidentidad } = this.state;
    // const cedulaStorage = JSON.parse(localStorage.getItem(`cedula`));

    // if (!cedulaidentidad) {
    //   //this.setState({ cedulaidentidad: cedulaStorage })
    //   //this.setState({ cedulaidentidad: '' })
    // }

    // instance.get(`/cuestionarioencuesta/listar_cuestionarioencuesta/${cedulaStorage || '0'}`)
    //   .then(resp => {
    //     if (resp.data.message.result === true && resp.data.message.status === 'success') {
    //       this.setState({
    //         arrGrupos: resp.data.response.grupos,
    //         sentsurvey: resp.data.response.sentsurvey,
    //       })
    //     }
    //   })
    //   .catch(error => { });

    // const userInformation = JSON.parse(localStorage.getItem(`user_information_${cedulaStorage}`));

    // if (userInformation) {
    //   this.setState({
    //     ciudad: userInformation.ciudad || '',
    //     areatrabajo: userInformation.areatrabajo || '',
    //     nivelinstruccion: userInformation.nivelinstruccion || '',
    //     tiempoantiguedadempresa: userInformation.tiempoantiguedadempresa || '',
    //     rangoedad: userInformation.rangoedad || '',
    //     autoidentificacionetnica: userInformation.autoidentificacionetnica || '',
    //     genero: userInformation.genero || ''
    //   })
    // }
  }

  handleAuthAccess = (event) => {
    event.preventDefault();
    const { cedulaidentidad } = this.state;
    const cedula = cedulaidentidad.trim();
    if (cedula.length === 10) {
      this.setState({ isSubmitAuth: true });
      instance
        .post("/cuestionarioencuesta/obtener_personanomina", { cedula })
        .then((resp) => {
          const { response, message } = resp.data;
          this.setState({ isSubmitAuth: false });
          if (response && message.status === "success") {
            this.setState({
              showAutorizacionEncuesta: false,
              sentsurvey: message.sentsurvey,
            });
            if (message.sentsurvey === "1") {
              alert(
                "Estimado usuario, usted ya ha ingresado y enviado la encuesta.\nGracias por su colaboración."
              );
              window.location.reload();
            }
            //localStorage.setItem('cedula', cedulaidentidad);
            // ! ------------------------------------
            instance
              .get(
                `/cuestionarioencuesta/listar_cuestionarioencuesta/${
                  cedulaidentidad || "0"
                }`
              )
              .then((resp) => {
                if (
                  resp.data.message.result === true &&
                  resp.data.message.status === "success"
                ) {
                  this.setState({
                    arrGrupos: resp.data.response.grupos,
                    // sentsurvey: resp.data.response.sentsurvey,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });

            const userInformation = JSON.parse(
              localStorage.getItem(`user_information_${cedulaidentidad}`)
            );

            if (userInformation) {
              this.setState({
                ciudad: userInformation.ciudad || "",
                areatrabajo: userInformation.areatrabajo || "",
                nivelinstruccion: userInformation.nivelinstruccion || "",
                tiempoantiguedadempresa:
                  userInformation.tiempoantiguedadempresa || "",
                rangoedad: userInformation.rangoedad || "",
                autoidentificacionetnica:
                  userInformation.autoidentificacionetnica || "",
                genero: userInformation.genero || "",
              });
            }
            // ! ------------------------------------
          } else if (!response && message.status === "not_found") {
            alert(
              "Estimado usuario(a) no se encontró resultados con el número de cédula ingresado, por favor verifique y vuelva a intentarlo."
            );
          }
        })
        .catch((err) => {
          this.setState({ isSubmitAuth: false });
          alert(
            "Estimado usuario(a) ha ocurrido un error al verificar los datos, por favor contacte con la Dirección de Tecnología"
          );
          console.error(JSON.stringify(err));
        });
    } else {
      alert(
        "Estimado usuario(a) el número de cédula ingresado es erróneo, por favor verifique y vuelva a intentarlo."
      );
    }
  };

  handleKeyPress = (event) => {
    // console.log(event.key);
    if (event.key === "Enter") {
      this.handleAuthAccess(event);
    }
  };

  renderModalAutorizacionEncuesta = (
    title = "CUESTIONARIO DE EVALUACIÓN PSICOSOCIAL EN ESPACIOS LABORALES",
    textButton = "ACCEDER A LA ENCUESTA"
  ) => {
    const { showAutorizacionEncuesta, cedulaidentidad, isSubmitAuth } =
      this.state;
    return (
      <Modal centered show={showAutorizacionEncuesta}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center" }}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Estimado profesional, con el fin de comprobar que pertenece a la
            institución y acceder a la encuesta, debe ingresar y verificar su
            número de cédula.{" "}
          </p>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Número de cédula:</Form.Label>
              <Form.Control
                required
                type="text"
                maxLength={10}
                placeholder="1234567890"
                value={cedulaidentidad}
                onKeyDown={this.handleKeyPress}
                onChange={(event) => {
                  this.setState({ cedulaidentidad: event.target.value });
                }}
                pattern="[0-9]{10,13}"
              />
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="col"
            style={{ background: "#155724" }}
            onClick={this.handleAuthAccess}
          >
            {!isSubmitAuth && textButton}
            {isSubmitAuth && <Spinner animation="border" variant="info" />}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  recaptchaLoaded() {
    console.log("capcha successfully loaded");
  }

  verifyCallback(response) {
    if (response) {
      if (this._isMounted) {
        this.setState({
          isverifiedCaptcha: true,
        });
      }
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    const {
      isverifiedCaptcha,
      fecha,
      provincia,
      ciudad,
      areatrabajo,
      nivelinstruccion,
      tiempoantiguedadempresa,
      rangoedad,
      autoidentificacionetnica,
      genero,
      cedulaidentidad,
      sentsurvey,
    } = this.state;
    if (sentsurvey == "0") {
      if (isverifiedCaptcha) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          this.setState({ validated: true });
        } else {
          event.preventDefault();
          event.stopPropagation();
          this.setState({ isSubmitted: true });

          const questions = JSON.parse(
            localStorage.getItem(`questions_${cedulaidentidad}`)
          );
          const ponderacionTotal = questions
            .map((question) => question.ponderacion)
            .reduce((previus, current) => previus + current);

          const userInformation = {
            id_usuario: cedulaidentidad,
            fecharesolucionencuesta: fecha,
            provincia,
            ciudad,
            areatrabajo,
            nivelinstruccion,
            tiempoantiguedadempresa,
            rangoedad,
            autoidentificacionetnica,
            genero,
            ponderaciontotal: ponderacionTotal,
          };

          instance
            .post("/cuestionarioencuesta/registrar_cuestionariorespuesta", {
              userInformation,
              questions,
            })
            .then((resp) => {
              const { response, message } = resp.data;
              if (response && message.status === "success") {
                this.setState({
                  isSubmitted: false,
                  validated: false,
                });

                localStorage.removeItem(`user_information_${cedulaidentidad}`);
                localStorage.removeItem(`questions_${cedulaidentidad}`);
                localStorage.removeItem("cedula");

                alert("Su encuesta se realizo de manera satisfactoria!");

                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              } else {
                alert(
                  "Estimado usuario, ha finalizado el tiempo para enviar su encuesta !"
                );
                this.setState({
                  isSubmitted: false,
                  validated: false,
                });
              }
            })
            .catch((error) => {
              alert(
                "Estimado usuario(a) ha ocurrido un error al enviar los datos, por favor contacte con la Dirección de Tecnología"
              );
              this.setState({ isSubmitted: false, validated: false });
            });
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
        alert(
          "Tenemos que verificar que no eres un robot. Por favor, completa el siguiente control de seguridad !"
        );
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
      alert("Estimado usuario(a) usted ya realizo el envío de la encuesta !");
    }
  };

  onChange(e, key) {
    const { cedulaidentidad } = this.state;
    const value = e.target.value;
    this.setState({ [key]: value });

    let userInformation = {};
    const storageInf = localStorage.getItem(
      `user_information_${cedulaidentidad}`
    );
    if (storageInf) {
      userInformation = JSON.parse(storageInf);
    }

    const newStateUserInformation = {
      ...userInformation,
      [key]: value,
    };

    localStorage.setItem(
      `user_information_${cedulaidentidad}`,
      JSON.stringify(newStateUserInformation)
    );
  }

  setAssignStorage(event, grupo_id, pregunta_id) {
    const { cedulaidentidad } = this.state;
    let questions = [];
    const storageInf = localStorage.getItem(`questions_${cedulaidentidad}`);
    if (storageInf) {
      questions = JSON.parse(storageInf);
    }
    const index = questions.findIndex(
      (question) => question.pregunta_id === parseInt(pregunta_id)
    );

    const currentAnswer = questions[index];
    if (currentAnswer) {
      currentAnswer.ponderacion = parseInt(event.target.value);
      questions[index] = currentAnswer;
    } else {
      questions.push({
        pregunta_id: parseInt(pregunta_id),
        ponderacion: parseInt(event.target.value),
        grupo_id: parseInt(grupo_id),
        choosen_id: event.target.id,
      });
    }

    localStorage.setItem(
      `questions_${cedulaidentidad}`,
      JSON.stringify(questions)
    );
  }

  render() {
    const {
      fecha,
      validated,
      provincia,
      ciudad,
      areatrabajo,
      nivelinstruccion,
      tiempoantiguedadempresa,
      rangoedad,
      autoidentificacionetnica,
      genero,
      isSubmitted,
      arrGrupos,
      cedulaidentidad,
      sentsurvey,
      showAutorizacionEncuesta,
    } = this.state;
    const questions = JSON.parse(
      localStorage.getItem(`questions_${cedulaidentidad}`)
    );
    const userInformation = JSON.parse(
      localStorage.getItem(`user_information_${cedulaidentidad}`)
    );
    //const cedulaStorage = JSON.parse(localStorage.getItem('cedula'));
    // const cedulaStorage = this.state.cedulaidentidad;

    return (
      <Container style={{ marginTop: 15 }}>
        {showAutorizacionEncuesta && this.renderModalAutorizacionEncuesta()}
        <Alert variant="success" style={{ backgroundColor: "#157a44" }}>
          <Alert.Heading
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <p
              style={{ color: "white", fontSize: "larger", fontWeight: "700" }}
            >
              CUESTIONARIO DE EVALUACIÓN PSICOSOCIAL EN ESPACIOS LABORALES
            </p>
          </Alert.Heading>
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <Image
              style={{ textAlign: "center", filter: "brightness(500%)" }}
              src={LogoGPM}
              fluid
            />
          </div>
          <hr />
        </Alert>
        <div>
          {/* Instrucciones para completar el cuestionario:
          <ul>
            <li>1. El cuestionario es anónimo es decir no se solicita información personal sobre el participante.</li>
            <li>2. La información obtenida es confidencial es decir que se ha de guardar, mantener y emplear con estricta cautela la información obtenida.</li>
            <li>3. Completar todo el cuestionario, requiere entre 15 a 20 minutos.</li>
            <li>4. Antes de responder, leer detenidamente cada pregunta y opción de respuesta. En este punto es necesario identificar y valorar todos aquellos factores del
          ámbito psicosocial que pueden representar un riesgo para la salud y el bienestar laboral.</li>
            <li>5. Marcar la respuesta que considere que describe mejor su situación. Es obligatorio contestar todos los ítems del cuestionario, en caso de error seleccionar nuevamente la respuesta que considere correcta.</li>
            <li>6. Evitar distracciones mientras completa el cuestionario, en caso de inquietud, solicitar asistencia al facilitador.</li>
            <li>7. El cuestionario tiene una sección denominada “observaciones y comentarios”, que puede ser utilizada por los participantes en caso de sugerencias u
            opiniones.</li>
            </ul>
          Muchas gracias por su colaboración */}

          <strong>Instrucciones para completar el cuestionario:</strong>
          <ol>
            <li>
              El cuestionario es anónimo es decir no se solicita información
              personal sobre el participante.
            </li>
            <li>
              La información obtenida es confidencial es decir que se ha de
              guardar, mantener y emplear con estricta cautela la información
              obtenida.
            </li>
            <li>
              Completar todo el cuestionario requiere entre 15 a 20 minutos.
            </li>
            <li>
              Antes de responder, leer detenidamente cada pregunta y opción de
              respuesta. En este punto es necesario identificar y valorar todos
              aquellos factores del ámbito psicosocial que pueden representar un
              riesgo para la salud y el bienestar laboral.
            </li>
            <li>
              Marcar la respuesta que considere que describe mejor su situación.
              Es obligatorio contestar todos los ítems del cuestionario, en caso
              de error en la respuesta encerrar en un círculo la misma y
              seleccionar nuevamente la respuesta.
            </li>
            <li>No existen respuestas correctas o incorrectas.</li>
            <li>
              Evitar distracciones mientras completa el cuestionario, en caso de
              inquietud, solicitar asistencia al facilitador.
            </li>
            <li>
              El cuestionario tiene una sección denominada “observaciones y
              comentarios”, que puede ser utilizada por los participantes en
              caso de sugerencias u opiniones.
            </li>
            <li>
              Los resultados finales de la evaluación serán socializados
              oportunamente a los participantes.
            </li>
          </ol>

          <strong>Muchas gracias por su colaboración</strong>
        </div>
        <Form
          noValidate
          validated={validated}
          onSubmit={this.handleSubmit}
          style={{ marginTop: 15 }}
        >
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Fecha:</Form.Label>
              <Form.Control disabled type="text" defaultValue={fecha} />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Provincia:</Form.Label>
              <Form.Control disabled type="text" defaultValue={provincia} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Ciudad:</Form.Label>
              <Form.Control
                as="select"
                required
                value={userInformation ? userInformation.ciudad : ciudad}
                onChange={(event) => this.onChange(event, "ciudad")}
              >
                <option></option>
                {ciudades.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Ciudad.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom04">
              <Form.Label>Area de Trabajo:</Form.Label>
              <Form.Control
                as="select"
                required
                value={
                  userInformation ? userInformation.areatrabajo : areatrabajo
                }
                onChange={(event) => this.onChange(event, "areatrabajo")}
              >
                <option></option>
                <option value="ADMINISTRATIVA">ADMINISTRATIVA</option>
                <option value="OPERATIVA">OPERATIVA</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Area de Trabajo.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Nivel más alto de instrucción:</Form.Label>
              <Form.Control
                as="select"
                required
                value={
                  userInformation
                    ? userInformation.nivelinstruccion
                    : nivelinstruccion
                }
                onChange={(event) => this.onChange(event, "nivelinstruccion")}
              >
                <option></option>
                <option value="Ninguno">Ninguno</option>
                <option value="Educación básica">Educación básica</option>
                <option value="Educación media">Educación media</option>
                <option value="Bachillerato">Bachillerato</option>
                <option value="Técnico / Tecnológico">
                  Técnico / Tecnológico
                </option>
                <option value="Tercer nivel">Tercer nivel</option>
                <option value="Cuarto nivel">Cuarto nivel</option>
                <option value="Otro">Otro</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese nivel más alto de instrucción.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>
                Antigüedad, años de experiencia dentro de la empresa o
                institución:
              </Form.Label>
              <Form.Control
                as="select"
                required
                value={
                  userInformation
                    ? userInformation.tiempoantiguedadempresa
                    : tiempoantiguedadempresa
                }
                onChange={(event) =>
                  this.onChange(event, "tiempoantiguedadempresa")
                }
              >
                <option></option>
                <option value="0-2">0-2 años</option>
                <option value="3-10">3-10 años</option>
                <option value="11-20">11-20 años</option>
                <option value="21-60">Igual o superior a 21 </option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Antigüedad, años de experiencia dentro de la empresa o
                institución.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Edad del trabajador o servidor:</Form.Label>
              <Form.Control
                as="select"
                required
                value={userInformation ? userInformation.rangoedad : rangoedad}
                onChange={(event) => this.onChange(event, "rangoedad")}
              >
                <option></option>
                <option value="16-24">16-24 años</option>
                <option value="25-34">25 34 años</option>
                <option value="35-43">35-43 años</option>
                <option value="44-52">44-52 años</option>
                <option value="53-100">Igual o superior a 53</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Edad del trabajador o servidor.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Auto-identificación étnica:</Form.Label>
              <Form.Control
                as="select"
                required
                value={
                  userInformation
                    ? userInformation.autoidentificacionetnica
                    : autoidentificacionetnica
                }
                onChange={(event) =>
                  this.onChange(event, "autoidentificacionetnica")
                }
              >
                <option></option>
                <option value="Indigena">Indígena</option>
                <option value="Mestizo">Mestizo/a</option>
                <option value="Montubio">Montubio/a</option>
                <option value="Afroecuatoriano">Afro - ecuatoriano</option>
                <option value="Blanco">Blanco/a:</option>
                <option value="Otro">Otro</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Auto-identificación étnica.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Género del trabajador o servidor:</Form.Label>
              <Form.Control
                as="select"
                required
                value={userInformation ? userInformation.genero : genero}
                onChange={(event) => this.onChange(event, "genero")}
              >
                <option></option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Género del trabajador o servidor.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <Nav
                  variant="pills"
                  className="flex-column"
                  style={{
                    padding: "1.5rem .5rem",
                    margin: "1rem .25rem",
                    border: "2.8px solid #007bff",
                    borderRadius: "4px",
                  }}
                >
                  {arrGrupos.map((grupo, idx) => (
                    <Nav.Item aria-labelledby="1" key={idx}>
                      <Nav.Link
                        aria-labelledby="1"
                        key={idx}
                        eventKey={grupo.nivel}
                      >
                        <a style={{ fontSize: 30 }}>{idx + 1}</a>
                        <br></br>
                        {grupo.descripcion}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {arrGrupos.map((grupo, idx) => (
                    <Tab.Pane key={idx} eventKey={grupo.nivel}>
                      {grupo.preguntas.map((pregunta, idxlast) => {
                        let indexAnswerStorage = 0;
                        if (questions) {
                          const questionWeighting = questions
                            .filter(
                              (question) =>
                                question.pregunta_id ===
                                parseInt(pregunta.id_pregunta)
                            )
                            .map((question) => question.ponderacion);

                          if (questionWeighting) {
                            indexAnswerStorage = questionWeighting[0];
                          }
                        }
                        return (
                          <>
                            <Form.Row key={`${idx}${idxlast}`}>
                              <Col sm={8}>
                                <li key={`${idxlast}${idx}`}>
                                  {pregunta.descripcion}
                                </li>
                              </Col>
                              <Col sm={4}>
                                <Form.Control
                                  as="select"
                                  required
                                  default=""
                                  onChange={(event) =>
                                    this.setAssignStorage(
                                      event,
                                      grupo.id_grupo,
                                      pregunta.id_pregunta
                                    )
                                  }
                                  id={`id_${grupo.id_grupo}_${pregunta.id_pregunta}`}
                                  name={`name_${grupo.id_grupo}_${pregunta.id_pregunta}`}
                                >
                                  <option></option>
                                  {optionsGlobalAnswers.map((option, idx) => {
                                    return (
                                      <option
                                        key={idx}
                                        value={option.value}
                                        selected={
                                          option.value === indexAnswerStorage
                                        }
                                      >
                                        {option.descripcion}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  Seleccione al menos una opcion.
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Row>
                            <hr></hr>
                          </>
                        );
                      })}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <Recaptcha
            sitekey="6LfKEN0bAAAAAOSHudjMdu6Z19Bs4oHmW77mc6Xc"
            render="explicit"
            onloadCallback={this.recaptchaLoaded}
            verifyCallback={this.verifyCallback}
            hl="es"
            size="normal"
          />
          <Row style={{ justifyContent: "center" }}>
            <Button
              variant="primary"
              type="submit"
              style={{ marginBottom: 15 }}
              disabled={sentsurvey === "1" ? true : false}
            >
              {!isSubmitted && "Enviar Datos"}
              {isSubmitted && <Spinner animation="border" variant="info" />}
            </Button>
          </Row>
        </Form>
      </Container>
    );
  }
}
