import React, { Component } from "react";
import {
  Container,
  Button,
  Form,
  Col,
  Alert,
  Spinner,
  Image,
  Toast,
  Row,
  Modal,
  Badge,
  Table,
} from "react-bootstrap";
import LogoGPM from "./assets/imgs/logo.png";
import GoogleMapReact, { OverlayView } from "google-map-react";
import Marker from "./Marker";
import "./assets/styles/map.css";
import dataPuntoInternet from "./data/puntointernetdata.json";

const a = [1, 2, 3, 4, 5];
const cantones = [
  {
    eje: "EJE NORTE",
    canton: "EL CARMEN",
  },
  {
    eje: "EJE NORTE",
    canton: "CHONE",
  },
  {
    eje: "EJE CENTRO SUR",
    canton: "BOLÍVAR",
  },
  { eje: "", canton: "PORTOVIEJO" },
  { eje: "", canton: "JUNÍN" },
  { eje: "", canton: "FLAVIO ALFARO" },
  { eje: "", canton: "SANTA ANA" },
  { eje: "", canton: "24 DE MAYO" },
  { eje: "", canton: "JIPIJAPA" },
  { eje: "", canton: "PAJÁN" },
  { eje: "", canton: "SAN VICENTE" },
  { eje: "", canton: "SUCRE" },
  { eje: "", canton: "PEDERNALES" },
  { eje: "", canton: "BOLÍVAR" },
  { eje: "", canton: "PICHINCHA" },
  { eje: "", canton: "JAMA" },
  { eje: "", canton: "TOSAGUA" },
  { eje: "", canton: "OLMEDO" },
  { eje: "", canton: "SAN VICENTE" },
  { eje: "", canton: "PUERTO LÓPEZ" },
  { eje: "", canton: "MANTA" },
  { eje: "", canton: "ROCAFUERTE" },
  { eje: "", canton: "JARAMIJO" },
  { eje: "", canton: "MONTECRISTI" },
];

const STYLES = {
  overlayView: {
    background: "white",
    border: "1px solid #ccc",
    padding: 15,
  },
};

export default class PuntosEjesInternet extends Component {
  constructor(props) {
    super(props);
    this.onShowModal = this.onShowModal.bind(this);
  }

  handleSubmit = (event) => {
    // console.log(event)
  };

  getMapOptions = (maps) => {
    return {
      disableDefaultUI: true,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
      ],
    };
  };

  state = {
    validated: false,
    ejevial: "",
    canton: "",
    parroquia: "",
    showModal: false,
    currentObject: {},
  };

  onShowModal = (status) => {
    this.setState({ showModal: status });
  };

  render() {
    const { validated, ejevial, canton, parroquia, showModal } = this.state;
    return (
      <div style={{ marginTop: 15 }}>
        <Alert variant="success" style={{ backgroundColor: "#157a44" }}>
          <Alert.Heading
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <p
              style={{ color: "white", fontSize: "larger", fontWeight: "700" }}
            >
              Puntos de Internet por Ejes
            </p>
          </Alert.Heading>
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <Image
              style={{ textAlign: "center", filter: "brightness(500%)" }}
              src={LogoGPM}
              fluid
            />
          </div>
          <hr />
        </Alert>
        <div className="map">
          {/* <h2 className="map-h2">Come Visit Us At Our Campus</h2> */}
          <div className="google-map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyBYk3965gbHR7E19dwodb6G9TxIaXXWbaM",
              }}
              defaultCenter={{ lat: -1.166672, lng: -80.841098 }}
              defaultZoom={11}
              options={this.getMapOptions}
            >
              {dataPuntoInternet.map((puntoInternet, idx) => (
                <Marker
                  key={idx}
                  lat={puntoInternet.LATITUD}
                  lng={puntoInternet.LONGITUD}
                  name={puntoInternet.INSTITUCION}
                  color="#155724"
                  onHeaderClick={() => {
                    this.setState({ currentObject: puntoInternet });
                    this.onShowModal(true);
                  }}
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
        <Modal centered show={showModal} onHide={() => this.onShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Información</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center" }}>
              <Badge
                style={{
                  background: "#155724",
                  color: "white",
                  marginBottom: 0,
                  textAlign: "center",
                }}
              >
                {this.state.currentObject.INSTITUCION}
              </Badge>
            </p>
            <ul>
              <li>Eje Vial: {this.state.currentObject.EJES_VIALES}</li>
              <li>Cantón: {this.state.currentObject.CANTON}</li>
              <li>Parroquia: {this.state.currentObject.PARROQUIA}</li>
              <li>Poblacion: {this.state.currentObject.POB_EST}</li>
              <li>
                Rural Urbano Marginal:{" "}
                {this.state.currentObject.RURAL_URBANO_MARGINAL}
              </li>
            </ul>
          </Modal.Body>
        </Modal>
        {/* <Form noValidate validated={validated} onSubmit={this.handleSubmit} style={{ marginTop: 15 }}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="validationCustom04">
              <Form.Label>Ejes Viales:</Form.Label>
              <Form.Control
                as="select"
                required
                value={ejevial}
                onChange={event => this.setState({ ejevial: event.target.value })}
              >
                <option></option>
                <option value='EJE NORTE'>EJE NORTE</option>
                <option value='EJE CENTRO SUR'>EJE CENTRO SUR</option>
                <option value='EJE CENTRO NORTE'>EJE CENTRO NORTE</option>
                <option value='EJE SUR'>EJE SUR</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Eje Vial.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Cantón:</Form.Label>
              <Form.Control
                as="select"
                required
                value={canton}
                onChange={event => this.setState({ canton: event.target.value })}
              >
                <option></option>
                <option value='TODO MANABI'>TODO MANABI</option>
                {cantones.map(canton => (
                  <option value={canton.canton}>{canton.canton}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese el Cantón.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Parroquia:</Form.Label>
              <Form.Control
                as="select"
                required
                value={parroquia}
                onChange={event => this.setState({ parroquia: event.target.value })}
              >
                <option></option>
                <option value='MESTIZO'>MESTIZO</option>
                <option value='MONTUBIO'>MONTUBIO</option>
                <option value='AFROECUATORIANO'>AFROECUATORIANO</option>
                <option value='BLANCO'>BLANCO</option>
                <option value='ASIATICO'>ASIATICO</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Parroquia.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form> */}
      </div>
    );
  }
}
