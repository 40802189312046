const isPermitEncuestaFlow = () => {
  const personalEncuesta = ["1311099434", "1309491932", "ogomez"];

  const configuration = localStorage.getItem("auth-jwt");
  const objectJwt = decodeJWT_RS256(configuration);
  const id_usuario =
    objectJwt.sub !== undefined
      ? objectJwt.sub.id_usuario
      : objectJwt.data.id_usuario;
  const surveyOn = personalEncuesta.filter((value) => value === id_usuario);
  return surveyOn.length > 0;
};

/**
 * Decodifica un JWT con codificación RS256 y HS256
 * @param  {String} jwt JSON Web Token
 * @return {Object}      Payload del JWT
 */
function decodeJWT_RS256(jwt) {
  try {
    var base64Url = jwt.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      Buffer.from(base64, "base64")
        .toString()
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const objectJwt = JSON.parse(jsonPayload);
    return objectJwt;
  } catch (error) {
    console.log(error);
    return {};
  }
}

/**
 * Retorna una lista de los años en secuencia posterior al año indicado
 * @param  {Number} year Año inicial
 * @return {Array}      Lista de años
 */
function getListDate(year: number) {
  const currentYear = new Date().getFullYear();
  if (year > currentYear) {
    throw new RangeError("El año inicial no puede ser mayor al año actual");
  }
  const startDate = year;
  let arrDate = [];
  for (let i = startDate; i <= currentYear; i++) {
    arrDate.push(i);
  }
  return arrDate;
}

/**
 * Retorna la fecha actual en formato ingresado.
 * Formato por defecto: DD-MM-YYYY__HH_MM_SS
 *
 * DD: Día
 *
 * MM: Mes
 *
 * YYYY: Año
 *
 * HH: Hora
 *
 * MM: Minutos
 *
 * SS: Segundos
 *
 * @param  {String} format Formato de fecha
 * @return {String}      Fecha y Hora actual
 */
function getDateNowString(format: String = "DD-MM-YYYY__HH_MM_SS") {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return format
    .replace("DD", day)
    .replace("MM", month)
    .replace("YYYY", year)
    .replace("HH", hour)
    .replace("MM", minute)
    .replace("SS", second);
}

/**
 * Aplica formato DD/MM/YYYY a una fecha
 * @param  {Date} date Fecha a formatear
 * @return {String}     Fecha formateada
 */
function formatDateToDDMMYYYY(date) {
  if (!(date instanceof Date)) {
    throw new Error("El argumento debe ser un objeto Date.");
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;

  return formattedDate;
}

export {
  isPermitEncuestaFlow,
  decodeJWT_RS256,
  getListDate,
  getDateNowString,
  formatDateToDDMMYYYY,
};
