import React, { useState, useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import instance from '../utils/Instance';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const columns = [
  {dataField: 'id_resp_cuest', text: 'ID', hidden: true},
  { dataField: 'fecharesolucionencuesta', text: 'Fecha Envio', sort: true },
  { dataField: 'provincia', text: 'Provincia' },
  { dataField: 'ciudad', text: 'Ciudad' },
  { dataField: 'areatrabajo', text: 'Area de Trabajo' },
  { dataField: 'nivelinstruccion', text: 'Nivel de instrucción' },
  { dataField: 'tiempoantiguedadempresa', text: 'Tiempo Antiguedad Empresa' },
  { dataField: 'rangoedad', text: 'Rango de Edad' },
  { dataField: 'autoidentificacionetnica', text: 'Auto-identificación étnica' },
  { dataField: 'genero', text: 'Género' },
  { dataField: 'tercil_grupo1', text: 'Carga y ritmo de trabajo' },
  { dataField: 'tercil_grupo2', text: 'Desarrollo de competencias' },
  { dataField: 'tercil_grupo3', text: 'Liderazgo' },
  { dataField: 'tercil_grupo4', text: 'Margen de acción y control' },
  { dataField: 'tercil_grupo5', text: 'Organización del trabajo' },
  { dataField: 'tercil_grupo6', text: 'Recuperación' },
  { dataField: 'tercil_grupo7', text: 'Soporte y apoyo' },
  { dataField: 'tercil_grupo8', text: 'Otros puntos importantes' },
  { dataField: 'dimension_total', text: 'Puntaje Total' },
];

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export default function ListaResultadoTercilEncuesta(props) {
  const [arrResultadoEncuestaTer, setArrResultadoEncuestTer] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    instance.get('cuestionarioencuesta/listar_tercilresultadoencuesta')
      .then(resp => {
        const { response } = resp.data;
        setArrResultadoEncuestTer(response);
        setLoader(false);
      })
      .catch(error => {
        alert('Hubo un error:\n\n', error);
        setLoader(false);
      });
  }, []);

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const ExportCSVButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div>
        <button style={{ marginTop: 5, backgroundColor: 'rgb(21, 122, 68)' }} className="btn btn-success" onClick={handleClick}>Exportar a CSV</button>
        <button style={{ marginTop: 5, backgroundColor: 'rgb(21, 122, 68)', marginLeft: 10 }} className="btn btn-success" onClick={() => exportToCSV(arrResultadoEncuestaTer, 'lista_empleados')}>Exportar a Excel</button>
      </div>
    );
  };
  
  const SpinnerLoader = (props) => {
    return (
      <Spinner animation="border" role="status" style={{
        position: props.position,
        top: "50%",
        left: "50%",
        marginTop: "-50px",
        marginLeft: "-50px",
        width: "100px",
        height: "100px",
        color: "#137b43"
      }}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  const SearchBar = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };

    return (
      <div>
        <input
          className="form-control"
          style={{ marginTop: 8 }}
          ref={n => input = n}
          type="text"
          onChange={handleClick}
          placeholder='Realice su busqueda ..'
        />
      </div>
    );
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="fecharesolucionencuesta"
        columns={columns}
        data={arrResultadoEncuestaTer}
        search
        exportCSV={{
          fileName: 'resultado-salud-ocupacional-gpm.csv',
          onlyExportFiltered: true,
          exportAll: false,
          noAutoBOM: false,
          blobType: "data:text/csv;charset=utf-8"
        }}
      >
        {
          toolkitprops => (
            <div>
              <ExportCSVButton {...toolkitprops.csvProps} />
              <SearchBar {...toolkitprops.searchProps} />
              <BootstrapTable
                bootstrap4
                classes='table-responsive'
                striped
                hover
                pagination={ paginationFactory() }
                {...toolkitprops.baseProps}
                {...paginationTableProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );

  return (
    <>
      <Container style={{ marginTop: 15 }}>
        {/* <PaginationProvider
        pagination={paginationFactory({
          sizePerPage: 10,
          hideSizePerPage: true,
          hidePageListOnlyOnePage: true,
          showTotal: true,
          paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Mostrando del {from} al {to}.<p>Total de Resultados <strong>{size}</strong>.</p>
            </span>
          )
        })}
        > */}
          {/* {contentTable} */}
      {!loader ? <ToolkitProvider
        keyField="id_resp_cuest"
        columns={columns}
        data={arrResultadoEncuestaTer}
        search
        pagination={ paginationFactory() }
        exportCSV={{
          fileName: 'resultado-salud-ocupacional-gpm.csv',
          onlyExportFiltered: true,
          exportAll: false,
          noAutoBOM: false,
          blobType: "data:text/csv;charset=utf-8"
        }}
      >
        {
          toolkitprops => (
            <div>
              <ExportCSVButton {...toolkitprops.csvProps} />
              <SearchBar {...toolkitprops.searchProps} />
              <BootstrapTable
                bootstrap4
                classes='table-responsive'
                striped
                hover
                pagination={ paginationFactory({
                    page: 1,
                    sizePerPage: 10,
                    hideSizePerPage: true,
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    paginationTotalRenderer: (from, to, size) => (
                      <span className="react-bootstrap-table-pagination-total">
                        Mostrando del { from } al { to }.<p>Total de Resultados <strong>{ size }</strong>.</p>
                      </span>
                    )
                  }) }
                {...toolkitprops.baseProps}
                // {...paginationTableProps}
              />
            </div>
          )
        }
      </ToolkitProvider>:<div style={{height: 200}}><SpinnerLoader position="absolute" /></div>}
        {/* </PaginationProvider> */}
      </Container>
    </>
  );
}

