import React, { Component } from 'react';
import { Container, Button, Form, Col, Alert, Spinner, Image, Toast, Row, Modal, Badge, Table } from 'react-bootstrap';
import Recaptcha from 'react-recaptcha';
import instance from './utils/Instance';
import LogoGPM from './assets/imgs/logo.png';
import DatePicker from 'react-datepicker2';

export default class DonacionPatio extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);

    this.state = {
      identificacion: '',
      nombrecompleto: '',
      fechanacimiento: '',
      edad: '',
      etnia: '',
      estadocivil: '',
      tiposangre: '',
      telefono: '',
      telefonoadicional: '',
      correoelectronico: '',
      sexo: '',
      direcciondomicilio: '',
      instruccion: '',
      domicilio: '',
      cargoactual: '',
      numerohijos: 0,
      identificacionconyugue: '',
      nombrecompletoconyugue: '',
      validated: false,
      isSubmitted: false,
      show: false,
      messageUnprocessableEntity: ``,
      isverifiedCaptcha: false,
      isMounted: false,
      messageValidIndentification: '',
      showModalRecaudacion: true,
      especializacion: '',
      rolempleado: '',
      codigoopt: '',
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadInfDinardapAndTalentoHumano = cedula => {
    if (cedula.length === 10) {
      const url = `https://apiservices.manabi.gob.ec/persona/registrocivil/datosdemograficos/${cedula}`;
      fetch(url)
        .then((resp) => resp.json())
        .then(data => {
          this.setState({
            sexo: data.sexo, domicilio: data.domicilio,
            identificacionconyugue: data.cedulaConyuge,
            direcciondomicilio: `${data.domicilio} - ${data.callesDomicilio}`
          })
        })
        .catch(error => { });

      instance.get(`talentohumano/obtenerpersona/${cedula}`)
        .then(resp => {
          if (resp.data.message.result === true && resp.data.message.status === 'success') {
            this.setState({
              nombrecompleto: `${resp.data.response.NOMBRES} ${resp.data.response.APELLIDOS}`,
              rolempleado: resp.data.response.rolempleado
            })
          }
        })
        .catch(error => { });
    }
  }

  calcularEdad(fechanacimiento) {
    if (fechanacimiento) {
      const currentDate = new Date();
      const cumpleanos = fechanacimiento._d;
      let currentAge = currentDate.getFullYear() - cumpleanos.getFullYear();
      let m = currentDate.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && currentDate.getDate() < cumpleanos.getDate())) {
        currentAge--;
      }

      this.setState({ edad: currentAge });
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    const {
      identificacion,
      nombrecompleto,
      fechanacimiento,
      edad,
      etnia,
      estadocivil,
      tiposangre,
      telefono,
      telefonoadicional,
      correoelectronico,
      sexo,
      direcciondomicilio,
      instruccion,
      domicilio,
      cargoactual,
      numerohijos,
      identificacionconyugue,
      nombrecompletoconyugue, isverifiedCaptcha, especializacion, rolempleado, codigoopt
    } = this.state;

    if (isverifiedCaptcha && identificacion !== '') {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ validated: true });
      } else {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
          isSubmitted: true
        });

        const objectDonation = {
          identificacion,
          nombrecompleto,
          fechanacimiento,
          edad,
          etnia,
          sexo,
          direcciondomicilio,
          correoelectronico,
          estadocivil,
          tiposangre,
          telefono,
          telefonoadicional,
          instruccion,
          cargoactual,
          numerohijos,
          identificacionconyugue,
          nombrecompletoconyugue,
          especializacion,
          rolempleado,
          codigoopt,
        };

        instance.post('/talentohumano/actualizarpersona', objectDonation)
          .then(resp => {
            const { response, message } = resp.data;
            if (response && message.status === 'success') {
              this.setState({
                isSubmitted: false,
                validated: false,
                show: true,
                messageUnprocessableEntity: 'Sus datos se registraron de manera sastifactoria'
              });

              alert('Sus datos fueron registrados de manera sastifactoria !');

              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              alert('Estimado usuario, ha finalizado el tiempo para enviar su actualización de datos !');
              this.setState({
                isSubmitted: false,
                validated: false,
              });
            }
          })
          .catch(error => {
            const { status, data } = error.response;
            this.setState({ isSubmitted: false, validated: false });
          });
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
      alert('Tenemos que verificar que no eres un robot. Por favor, completa el siguiente control de seguridad !');
    }
  };

  cleanElements = () => {
    this.setState({
      identificacion: '',
      nombrecompleto: '',
      ciudad: '',
      telefono: '',
      telefonoadicional: '',
      correoelectronico: '',
    })
  }

  recaptchaLoaded() {
    console.log('capcha successfully loaded');
  }

  verifyCallback(response) {
    if (response) {
      if (this._isMounted) {
        this.setState({
          isverifiedCaptcha: true
        });
      }
    }
  }

  handleRecaudacionClose = () => {
    this.setState({
      showModalRecaudacion: false
    });
  }

  renderModalRecaudacion = (textButton = 'Cerrar ventana', title = 'Actualización de Datos') => {
    const { showModalRecaudacion } = this.state;
    return (
      <Modal centered show={showModalRecaudacion} onHide={this.handleRecaudacionClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: "center" }}><Badge style={{ background: "#155724", color: "white", marginBottom: 0, textAlign: "center" }}>GOBIERNO PROVINCIAL DE MANABÍ</Badge></p>
          <p style={{ textAlign: "justify" }}> Es de caracter obligatorio para el personal institucional actualizar su información </p>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ background: "#155724" }} onClick={this.handleRecaudacionClose}>
            {textButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  render() {
    const {
      validated, identificacion, nombrecompleto, correoelectronico, telefono, telefonoadicional, isSubmitted, show, messageUnprocessableEntity,
      messageValidIndentification, sexo, direcciondomicilio,
      instruccion, domicilio, fechanacimiento, edad, estadocivil, etnia, tiposangre, cargoactual, numerohijos,
      identificacionconyugue, nombrecompletoconyugue, especializacion, rolempleado
    } = this.state;
    return (
      <Container style={{ marginTop: 15 }}>
        <Alert variant="success" style={{ backgroundColor: '#157a44' }}>
          <Alert.Heading style={{ justifyContent: 'center', textAlign: 'center' }}>
            <p style={{ color: 'white', fontSize: 'larger', fontWeight: '700' }}>Formulario de Actualización Personal</p>
          </Alert.Heading>
          <div style={{ justifyContent: 'center', textAlign: 'center' }}>
            <Image style={{ textAlign: "center", filter:"brightness(500%)" }} src={LogoGPM} fluid />
          </div>
          <hr />
        </Alert>
        {this.renderModalRecaudacion()}
        <>
          {show &&
            <Col xs={12}>
              <Toast onClose={() => this.setState({ show: false })} show={show} delay={14000} autohide>
                <Toast.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
                  <img
                    src={LogoGPM}
                    className="rounded mr-2"
                    alt="Gobierno Provincial de Manabi"
                  />
                </Toast.Header>
                <Toast.Body>
                  {messageUnprocessableEntity}
                </Toast.Body>
              </Toast>
            </Col>
          }
        </>
        <Form noValidate validated={validated} onSubmit={this.handleSubmit} style={{ marginTop: 15 }}>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Número de cédula:</Form.Label>
              <Form.Control
                required
                //disabled
                type="text"
                placeholder="1313131313"
                defaultValue={identificacion}
                pattern="[0-9]{10,13}"
                //onKeyDown={event => this.loadInfDinardapAndTalentoHumano(event)}
                onChange={event => {
                  this.setState({ identificacion: event.target.value });
                  this.loadInfDinardapAndTalentoHumano(event.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Ingrese número de cédula.
              </Form.Control.Feedback>
              <>
                {
                  messageValidIndentification &&
                  <Form.Control.Feedback type="invalid">
                    {messageValidIndentification}
                  </Form.Control.Feedback>
                }
              </>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Nombres Completos:</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Luis Farfan"
                defaultValue={nombrecompleto}
                onChange={event => this.setState({ nombrecompleto: event.target.value })}
                onInput={this.toInputUppercase}
                maxLength={150}
                minLength={10}
              //pattern="[A-Za-z ]{4,50}"
              />
              <Form.Control.Feedback type="invalid">
                Ingrese Nombres Completos.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Fecha de Nacimiento (dd/mm/aaaa):</Form.Label>
              <DatePicker
                isGregorian={true}
                inputFormat='DD/MM/YYYY'
                className='form-control'
                timePicker={false}
                max={new Date()}
                onChange={value => {
                  if (value) {
                    const dd = String(value._d.getDate()).padStart(2, '0');
                    const mm = String(value._d.getMonth() + 1).padStart(2, '0');
                    const yyyy = value._d.getFullYear();
                    let today = mm + '/' + dd + '/' + yyyy;
                    this.setState({ fechanacimiento: today });
                    this.calcularEdad(value);
                  }
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Edad:</Form.Label>
              <Form.Control
                disabled
                required
                type="number"
                placeholder="0"
                defaultValue={edad}
                onChange={event => this.setState({ edad: event.target.value })}
                maxLength={150}
                minLength={10}
              />
              <Form.Control.Feedback type="invalid">
                Ingrese Edad.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Rol:</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="CONTRATO OCACIONAL"
                defaultValue={rolempleado}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom04">
              <Form.Label>Etnia:</Form.Label>
              <Form.Control
                as="select"
                required
                value={etnia}
                onChange={event => this.setState({ etnia: event.target.value })}
              >
                <option></option>
                <option value='MESTIZO'>MESTIZO</option>
                <option value='MONTUBIO'>MONTUBIO</option>
                <option value='AFROECUATORIANO'>AFROECUATORIANO</option>
                <option value='BLANCO'>BLANCO</option>
                <option value='ASIATICO'>ASIATICO</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Etnia.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Sexo:</Form.Label>
              <Form.Control
                as="select"
                required
                value={sexo}
                onChange={event => this.setState({ sexo: event.target.value })}
              >
                <option></option>
                <option value='HOMBRE'>HOMBRE</option>
                <option value='MUJER'>MUJER</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Seleccione el Sexo.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Dirección Domiciliaria Croquis y Referencia:</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                required
                type="text"
                placeholder="INGRESE INFORMACION ..."
                defaultValue={direcciondomicilio}
                onChange={event => this.setState({ direcciondomicilio: event.target.value })}
                onInput={this.toInputUppercase}
                maxLength={300}
                minLength={15}
              />
              <Form.Control.Feedback type="invalid">
                Ingrese descripcion de su domicilio.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom05">
              <Form.Label>Correo Personal:</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="(gmail, hotmail, yahoo)"
                defaultValue={correoelectronico}
                onChange={event => this.setState({ correoelectronico: event.target.value })}
                maxLength={50}
                minLength={8}
              />
              <Form.Control.Feedback type="invalid">
                Ingrese Correo Personal.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom04">
              <Form.Label>Estado Civil:</Form.Label>
              <Form.Control
                as="select"
                required
                value={estadocivil}
                onChange={event => this.setState({ estadocivil: event.target.value })}
              >
                <option></option>
                <option value='SOLTERO/A'>SOLTERO/A</option>
                <option value='CASADO/A'>CASADO/A</option>
                <option value='DIVORCIADO/A'>DIVORCIADO/A</option>
                <option value='VIUDO/A'>VIUDO/A</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Estado Civil.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom04">
              <Form.Label>Tipo de Sangre:</Form.Label>
              <Form.Control
                as="select"
                required
                value={tiposangre}
                onChange={event => this.setState({ tiposangre: event.target.value })}
              >
                <option></option>
                <option value='A+'>A+</option>
                <option value='B+'>B+</option>
                <option value='O+'>O+</option>
                <option value='AB+'>AB+</option>
                <option value='A-'>A-</option>
                <option value='B-'>B-</option>
                <option value='O-'>O-</option>
                <option value='AB-'>AB-</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Ingrese Tipo de Sangre.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Teléfono Convencional:</Form.Label>
              <Form.Control
                type="text"
                placeholder="INGRESE INFORMACION ..."
                defaultValue={telefono}
                onChange={event => this.setState({ telefono: event.target.value })}
                maxLength={10}
                minLength={6}
                pattern="[0-9-]{6,10}"
              />
              <Form.Control.Feedback type="invalid">
                Ingrese número de Teléfono Convencional.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Teléfono Movil:</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="INGRESE INFORMACION ..."
                defaultValue={telefonoadicional}
                onChange={event => this.setState({ telefonoadicional: event.target.value })}
                maxLength={10}
                minLength={6}
                pattern="[0-9-]{6,10}"
              />
              <Form.Control.Feedback type="invalid">
                Ingrese número de Teléfono Movil.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom04">
              <Form.Label>Formación Académica:</Form.Label>
              <Form.Control
                as="select"
                required
                value={instruccion}
                onChange={event => this.setState({ instruccion: event.target.value })}
              >
                <option></option>
                <option value='PRIMARIA'>PRIMARIA</option>
                <option value='SECUNDARIA'>SECUNDARIA</option>
                <option value='TERCER NIVEL'>TERCER NIVEL</option>
                <option value='CUARTO NIVEL'>CUARTO NIVEL</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Escoja Formacion Academica.
              </Form.Control.Feedback>
            </Form.Group>
            {(instruccion == 'TERCER NIVEL' || instruccion == 'CUARTO NIVEL') &&
              (
                <Form.Group as={Col} md="6">
                  <Form.Label>Especialización:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="INGRESE INFORMACION ..."
                    defaultValue={especializacion}
                    onChange={event => this.setState({ especializacion: event.target.value })}
                    onInput={this.toInputUppercase}
                    maxLength={50}
                    minLength={5}
                  />
                </Form.Group>
              )
            }
            {/* <Form.Group as={Col} md="12" controlId="validationCustom05">
              <Form.Label>Capacitaciones:</Form.Label>
              <Table responsive="lg">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fecha Inicio</th>
                    <th>Fecha Fin</th>
                    <th>Descripcion</th>
                    <th>Institucion </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>2017-02-05</td>
                    <td>2017-03-05</td>
                    <td>Desarrollo en Angular y TDD</td>
                    <td>Udemy</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>2017-02-05</td>
                    <td>2017-03-05</td>
                    <td>Desarrollo en Angular y TDD</td>
                    <td>Udemy</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>2017-02-05</td>
                    <td>2017-03-05</td>
                    <td>Desarrollo en Angular y TDD</td>
                    <td>Udemy</td>
                  </tr>
                </tbody>
              </Table>
            </Form.Group> */}
            <Form.Group as={Col} md="6" controlId="validationCustom05">
              <Form.Label>Cargo Actual:</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="INGRESE INFORMACION ..."
                defaultValue={cargoactual}
                onChange={event => this.setState({ cargoactual: event.target.value })}
                onInput={this.toInputUppercase}
                maxLength={50}
                minLength={5}
              />
              <Form.Control.Feedback type="invalid">
                Ingrese el Cargo Actual.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Número de cédula Conyugue:</Form.Label>
              <Form.Control
                type="text"
                placeholder="INGRESE INFORMACION ..."
                defaultValue={identificacionconyugue}
                onChange={event => this.setState({ identificacionconyugue: event.target.value })}
              />
              <Form.Control.Feedback type="invalid">
                Ingrese el número cédula de la Conyugue.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom05">
              <Form.Label>Nombres y Apellidos Conyugue:</Form.Label>
              <Form.Control
                required={identificacionconyugue == '' ? false : true}
                type="text"
                placeholder="INGRESE INFORMACION ..."
                defaultValue={nombrecompletoconyugue}
                onChange={event => this.setState({ nombrecompletoconyugue: event.target.value })}
                onInput={this.toInputUppercase}
              />
              <Form.Control.Feedback type="invalid">
                Ingrese los Nombres y Apellidos Conyugue.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Número de hijos:</Form.Label>
              <Form.Control
                required={identificacionconyugue == '' ? false : true}
                type="number"
                placeholder="5"
                defaultValue={numerohijos}
                onChange={event => this.setState({ numerohijos: event.target.value })}
              />
              <Form.Control.Feedback type="invalid">
                Ingrese el número de hijos .
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Recaptcha
            sitekey="6LceWOYUAAAAALeyFRn6QhxxxSdGu9T87YF0qUh2"
            render="explicit"
            onloadCallback={this.recaptchaLoaded}
            verifyCallback={this.verifyCallback}
            hl="es"
            size="normal"
          />

          <Row style={{ justifyContent: 'center' }}>
            <Button
              variant="primary"
              type="submit"
              style={{ marginBottom: 15 }}
            >
              {!isSubmitted && "Enviar Datos"}
              {isSubmitted && <Spinner animation="border" variant="info" />}
            </Button>
          </Row>
        </Form>
      </Container>
    );
  }

}
