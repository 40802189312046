import React, { useState, useEffect, useRef } from "react";

import { HiDownload } from "react-icons/hi";

import NavBar from "../../NavBar";

import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import Form from "react-bootstrap/Form";

import { getTransactions } from "../../services/printRollTransactions/getTransactions";

import Table from "react-bootstrap/Table";

import Pagination from "react-bootstrap/Pagination";

import Spinner from "react-bootstrap/Spinner";

import Badge from "react-bootstrap/Badge";

import ConfirmationDialog from "../../components/Cemvial/ConfirmationDialog";

import { updateTransaction } from "../../services/printRollTransactions/updateTransaction";
import { instance_py } from "../../utils/InstancePy";
import ValidateLoader from "../../ValidateLoader";

const CardHeader = () => (
  <Card.Header>
    <Card.Title>
      <h4 style={{ textAlign: "center" }}>Consulta de Transacciones Cemvial</h4>
    </Card.Title>
  </Card.Header>
);

const MyPagination = ({ pagination, onPageChange }) => {
  const pages = [];
  const totalPages = pagination?.total_pages;
  const currentPage = pagination?.current_page;

  const startIndex = Math.max(1, currentPage - 2);
  const endIndex = Math.min(totalPages, startIndex + 4);

  // Añadir el botón "Anterior" si hay una página anterior
  if (pagination?.has_previous) {
    pages.push(
      <Pagination.Prev
        key="prev"
        onClick={() => onPageChange(currentPage - 1)}
      />
    );
  }

  // Añadir botones de páginas dentro del rango
  for (let i = startIndex; i <= endIndex; i++) {
    pages.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  // Añadir el botón "Siguiente" si hay una página siguiente
  if (pagination?.has_next) {
    pages.push(
      <Pagination.Next
        key="next"
        onClick={() => onPageChange(currentPage + 1)}
      />
    );
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <Pagination>{pages}</Pagination>
    </div>
  );
};

const CemvialTransactionQueries = (props) => {
  const [values, setValues] = useState({
    page: 1,
    page_size: 15,
    year: null,
    filter_value: "",
    view_validated: false,
  });

  const [transactions, setTransactions] = useState([]);

  const [pagination, setPagination] = useState({});

  const [loading, setLoading] = useState(false);

  const [loadingResults, setLoadingResults] = useState(false);

  const [loadingPopupConfirmation, setLoadingPopupConfirmation] =
    useState(false);

  const [show, setShow] = useState(false);

  const [target, setTarget] = useState(null);

  const ref = useRef(null);

  const handleYearChange = (e) => {
    setValues({ ...values, year: e.target.value });
  };

  useEffect(() => {
    if (!values.year) return;
    const new_values = { ...values };
    const fetchData = async () => {
      setLoading(true);
      const res = await getTransactions(new_values);
      setLoading(false);
      setTransactions(res.transactions);
      setPagination(res.pagination);
    };
    fetchData();
  }, [values]);

  const handleBadgeClick = (event, id) => {
    setShow(true);
    setTarget({
      id,
      target: event.target,
    });
  };

  const handleConfirmation = () => async () => {
    setLoadingPopupConfirmation(true);
    const res = await updateTransaction(target.id);
    setLoadingPopupConfirmation(false);
    if (res) {
      const newTransactions = transactions.filter(
        (transaction) => transaction.id !== target.id
      );
      setTransactions(newTransactions);
      handleCloseConfirmation();
    }
  };

  const handleCloseConfirmation = () => {
    setShow(false);
    setTarget(null);
  };

  const handleDownload = async (id) => {
    const res = await instance_py.get(
      `/v1/print_roll/transactions/validate/generate_proof_voucher/${id}`,
      {
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([res.data], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);

    window.open(url);
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: { width: "10%" },
      style: { width: "10%" },
    },
    {
      dataField: "transacction_number",
      text: "N° de Transacción",
      sort: true,
    },
    {
      dataField: "vehicle_identification",
      text: "Placa",
      sort: true,
    },
    {
      dataField: "reference",
      text: "Referencia",
      sort: true,
    },
    {
      dataField: "bank",
      text: "Banco",
      sort: true,
    },
    {
      dataField: "value",
      text: "Valor Cancelado",
      sort: true,
    },
    {
      dataField: "process_date",
      text: "Fecha de Transacción",
      sort: true,
    },
    {
      dataField: "is_validated",
      text: "Estado",
      sort: true,
    },
  ];

  const rows =
    transactions &&
    transactions.map((transaction) => {
      return {
        id: transaction.id,
        transacction_number: transaction.transaction_number,
        vehicle_identification: transaction.vehicle_identification,
        reference: transaction.reference,
        bank: transaction.bank,
        value: Number(transaction.value).toFixed(2),
        process_date: new Date(transaction.process_date).toLocaleDateString(
          "es-Es",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ),
        is_validated: (
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip`}>
                  {transaction.is_validated
                    ? `Validado por: ${
                        transaction.validator_user
                      } el ${new Date(
                        transaction.validation_date
                      ).toLocaleDateString()}`
                    : "La transacción aún no ha sido validada"}
                </Tooltip>
              }
            >
              <Badge
                style={{
                  cursor: "pointer",
                  padding: "0.4rem",
                  borderRadius: "0.3rem",
                }}
                variant={transaction.is_validated ? "success" : "danger"}
                onClick={(e) => {
                  if (!transaction.is_validated) {
                    handleBadgeClick(e, transaction.id);
                  }
                }}
              >
                {transaction.is_validated ? "Validado" : "Pendiente"}
              </Badge>
            </OverlayTrigger>

            {transaction.is_validated ? (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`tooltip`}>Descargar certificado</Tooltip>
                }
              >
                <button
                  onClick={() => handleDownload(transaction.id)}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    width: "2.2rem",
                  }}
                >
                  <HiDownload
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </button>
              </OverlayTrigger>
            ) : null}
          </div>
        ),
      };
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const new_data = { ...values, page: 1 };
    setLoadingResults(true);
    setLoading(true);
    const res = await getTransactions(new_data);
    setLoading(false);
    setLoadingResults(false);
    setTransactions(res.transactions);
    setPagination(res.pagination);
  };

  return (
    <>
      <ValidateLoader {...props} />
      <NavBar {...props} />

      <Card>
        <CardHeader />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
              controlId="control"
            >
              <Form.Group>
                <Form.Label>Seleccione el año de la transacción</Form.Label>
                <Form.Control
                  onChange={handleYearChange}
                  as="select"
                  size="sm"
                  custom
                >
                  <option disabled selected>
                    Seleccione un año
                  </option>
                  <option value={2019}>2019</option>
                  <option value={2020}>2020</option>
                  <option value={2021}>2021</option>
                  <option value={2022}>2022</option>
                  <option value={2023}>2023</option>
                  <option value={2024}>2024</option>
                </Form.Control>
              </Form.Group>

              {values.year && (
                <Form.Group className="w-100">
                  <Form.Label>Ingrese el valor a buscar</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre y apellidos, placa o transacción"
                    onChange={(e) =>
                      setValues({ ...values, filter_value: e.target.value })
                    }
                  />
                </Form.Group>
              )}
            </Form.Group>

            {values.year && (
              <div>
                <button
                  style={{
                    display: "block",
                    width: "20%",
                  }}
                  type="submit"
                  className={`btn btn-primary d-flex gap-5 justify-content-center align-items-center ${
                    loadingResults ? "disabled" : ""
                  }`}
                >
                  Buscar
                  {loadingResults && (
                    <Spinner
                      style={{
                        width: "1.2rem",
                        height: "1.2rem",
                        marginLeft: "0.5rem",
                      }}
                      animation="border"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </button>
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Card.Title>
            <h5>{`Resultados de la Búsqueda (${
              pagination?.total_records ?? 0
            } registros)`}</h5>
          </Card.Title>
          <Card.Body
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>¿Mostrar transacciones validadas? </span>
            <Form.Check
              type="switch"
              id="custom-switch"
              label=""
              onChange={(e) =>
                setValues({
                  ...values,
                  view_validated: e.target.checked,
                })
              }
            />
          </Card.Body>
        </Card.Body>
        {loading ? (
          <div
            style={{
              height: "50vh",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : transactions?.length > 0 ? (
          <Card.Body>
            <Table striped bordered responsive hover size="md">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.dataField}>{column.text}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={index}>
                    {columns.map((column) => (
                      <td key={column.dataField}>{row[column.dataField]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            <ConfirmationDialog
              show={show}
              target={target?.target}
              setTarget={setTarget}
              onclick={handleConfirmation()}
              loading={loadingPopupConfirmation}
            />

            <span
              style={{
                display: "block",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              Página {pagination?.current_page} de {pagination?.total_pages}
            </span>

            <MyPagination
              pagination={pagination}
              onPageChange={(page) => setValues({ ...values, page })}
            />
            <span
              style={{
                display: "block",
                textAlign: "center",
                marginTop: "1rem",
              }}
            ></span>
          </Card.Body>
        ) : (
          <Card.Body>
            <div
              style={{
                height: "50vh",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <h3>No hay resultados</h3>
            </div>
          </Card.Body>
        )}
      </Card>
    </>
  );
};
export default CemvialTransactionQueries;
