import React, { useState, useEffect } from 'react';
import { Container, Spinner, Form } from 'react-bootstrap';
import instance from '../utils/Instance';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { getListDate } from '../utils/Utilities';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export default function ListaConteoTotalizadoPorGrupo(props) {  
  const [arrResultadoEncuestaGrupo, setArrResultadoEncuestaGrupo] = useState([]);
  const [loader, setLoader] = useState(true);
  let aniosList = {};
  getListDate(2020).forEach((item) => {
    aniosList[item] = item;
  });  

  const columns = [
    { dataField: 'descripcion', text: 'Dimensiones', sort: true },
    { dataField: 'riesgo_bajo', text: 'Riesgo Bajo' },
    { dataField: 'riesgo_medio', text: 'Riesgo Medio' },
    { dataField: 'riesgo_alto', text: 'Riesgo Alto' },
    { dataField: 'anio', text: 'Año',filter: selectFilter({
        options: aniosList
      }),
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <>
            { column.text }
            <br />
            {filterElement}
            {sortElement}
          </>
        );
      }
    },
  ];

  useEffect(() => {
    console.log(aniosList);
    listDonacionesGp()
  }, []);

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const listDonacionesGp = () => {
    // setLoader(true);
    instance.get('cuestionarioencuesta/listar_conteototalizado_porgrupo')
      .then(resp => {
        const { response } = resp.data;
        setArrResultadoEncuestaGrupo(response.map((item, index) => {
          item.id = index + 1;
          return item;
        }));
        setLoader(false);
      })
      .catch(error => {
        alert('Hubo un error:\n\n', error);
        setLoader(false);
      });
  }   

  const SpinnerLoader = (props) => {
    return (
      <Spinner animation="border" role="status" style={{
        position: props.position,
        top: "50%",
        left: "50%",
        marginTop: "-50px",
        marginLeft: "-50px",
        width: "100px",
        height: "100px",
        color: "#137b43"
      }}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

    const ExportCSVButton = (props) => {
      const handleClick = () => {
        props.onExport();
      };

      return (
        <div>
          <button style={{ marginTop: 5, backgroundColor: 'rgb(21, 122, 68)' }} className="btn btn-success" onClick={handleClick}>Exportar a CSV</button>
          <button style={{ marginTop: 5, backgroundColor: 'rgb(21, 122, 68)', marginLeft: 10 }} className="btn btn-success" onClick={() => exportToCSV(arrResultadoEncuestaGrupo, 'Conteo totalizado por grupo')}>Exportar a Excel</button>
        </div>
      );
    };

    

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <ToolkitProvider
          keyField="id"
          columns={columns}
          data={arrResultadoEncuestaGrupo}
          exportCSV={{
            fileName: 'conteo-totalizado-porgrupo.csv',
            onlyExportFiltered: true,
            exportAll: false,
            noAutoBOM: false,
            blobType: "data:text/csv;charset=utf-8"
          }}
        >
          {
            toolkitprops => (
              <div>
                <ExportCSVButton {...toolkitprops.csvProps} />
                <BootstrapTable
                  bootstrap4
                  striped
                  hover
                  pagination={ paginationFactory() }
                  filter={ filterFactory() }
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );

    return (
      <>
      <Container style={{ marginTop: 15 }}>
        {!loader ? <PaginationProvider
          pagination={
            paginationFactory(
              {
                sizePerPage: 10,
                hideSizePerPage: true,
                hidePageListOnlyOnePage: true,
                showTotal: true,
                paginationTotalRenderer: (from, to, size) => (
                  <span className="react-bootstrap-table-pagination-total">
                    Mostrando del { from } al { to }.<p>Total de Resultados <strong>{ size }</strong>.</p>
                  </span>
                )
              })
          }
        >{contentTable}
        </PaginationProvider> : <div style={{height: 200}}><SpinnerLoader position="absolute" /></div>}
      </Container>
      </>
    );  
}
