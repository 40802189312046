import React, { useState } from "react";

import NavBar from "../../NavBar";

import { Button, Card, Form, ListGroup } from "react-bootstrap";

import CustomTable, { createData } from "../../components/Cemvial/Table";

import { getDebts } from "../../services/cemvialProviders/getDebts";

import Spinner from "react-bootstrap/Spinner";
import ValidateLoader from "../../ValidateLoader";

const ConsultationCemvalProvider = (props) => {
  const [identification, setIdentification] = useState("");

  const [dataDebts, setDataDebts] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");

  const validPlate = dataDebts && dataDebts?.debts?.length > 0;

  const [loading, setLoading] = useState(false);

  const column = [
    { name: "Placa", align: "text-left" },
    { name: "Año", align: "text-left" },
    { name: "Valor Base", align: "text-left" },
    { name: "Interés", align: "text-left" },
    { name: "Exoneración", align: "text-left" },
    { name: "Valor a pagar", align: "text-left" },
  ];

  const rows = [];
  validPlate &&
    dataDebts.debts.forEach((provider) => {
      let interest = 0;
      let exoneracion = 0;
      let valueBase = 0;
      const { bills_details, plate } = provider;
      bills_details.forEach((bill) => {
        const { anio_deuda, facturaDetalles, totalTarifa, concepto } = bill;

        facturaDetalles.forEach((factura) => {
          const { rubroDescripcion, valorUnitario } = factura;

          if (rubroDescripcion === "Exoneracion") {
            exoneracion += valorUnitario;
          } else if (rubroDescripcion === "CEM VEHICULAR") {
            valueBase += valorUnitario;
          } else if (rubroDescripcion === "interes") {
            interest += valorUnitario;
          }
        });

        const rowData = createData(
          plate,
          anio_deuda,
          valueBase,
          interest,
          exoneracion,
          totalTarifa,
          concepto
        );
        rows.push(rowData);
      });
    });

  const plates_list = validPlate && dataDebts.plates_list;

  // const filterTotalbyYear = (data) => {
  //   const years = data.reduce((acc, provider) => {
  //     const { bills_details } = provider;
  //     bills_details.forEach((bill) => {
  //       const { anio_deuda, totalTarifa } = bill;
  //       if (!acc[anio_deuda]) {
  //         acc[anio_deuda] = 0;
  //       }
  //       acc[anio_deuda] += totalTarifa;
  //     });
  //     return acc;
  //   }, {});
  // };

  const totalDebtstoPay = (data) => {
    let total = 0;
    data.forEach((provider) => {
      const { bills_details } = provider;
      bills_details.forEach((bill) => {
        const { totalTarifa } = bill;
        total += totalTarifa;
      });
    });
    return total;
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (identification === "") {
      setLoading(false);
      return;
    }

    const data = await getDebts(identification);
    if (data.error) {
      setLoading(false);
      setErrorMessage(data.error.response.data.message);
      setDataDebts(null);
      return;
    }
    setDataDebts(data);
    setErrorMessage("");
    setLoading(false);
  };

  return (
    <>
      <ValidateLoader {...props} />
      <NavBar {...props} />
      <Card className="mt-3">
        <Card.Header className="text-uppercase">
          Consulta de Deudores CEM VIAL
        </Card.Header>
        <Card.Body>
          {/* <Card.Title>Consulta de Deudores</Card.Title> */}
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Ingrese cédula o ruc</Form.Label>
                <Form.Control
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onChange={(e) => setIdentification(e.target.value)}
                  value={identification}
                  placeholder="Cédula o RUC"
                  maxLength={13}
                />
                <Button
                  className="mt-3 text-uppercase d-flex align-items-center"
                  variant="primary"
                  type="submit"
                  disabled={!identification || loading}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="ml-2">Buscando...</span>
                    </>
                  ) : (
                    "Consultar"
                  )}
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card.Body>
      </Card>

      {loading ? (
        <div
          style={{
            height: "50vh",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {validPlate && (
            <Card className="mt-3">
              <Card.Header className="text-uppercase">
                Datos del usuario
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item>
                    <strong>Nombre:</strong> {dataDebts.client}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Cédula:</strong> {dataDebts.identification}
                  </ListGroup.Item>
                  {dataDebts.address && (
                    <ListGroup.Item>
                      <strong>Dirección:</strong> {dataDebts.address}
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>
          )}

          {errorMessage && (
            <Card className="mt-3">
              <Card.Header className="text-uppercase">Error</Card.Header>
              <Card.Body>
                <Card.Text>{errorMessage}</Card.Text>
              </Card.Body>
            </Card>
          )}

          {dataDebts && dataDebts.debts.length === 0 && (
            <Card className="mt-3">
              <Card.Header className="text-uppercase">
                Resultados de la Búsqueda
              </Card.Header>
              <Card.Body>
                <Card.Text>No se encontraron deudas</Card.Text>
              </Card.Body>
            </Card>
          )}

          {validPlate && (
            <Card className="mt-3">
              <Card.Header className="text-uppercase">
                Resultados de la consulta
              </Card.Header>
              <Card.Body>
                <>
                  <div
                    className="mb-3 ml-3"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    <strong>Fecha y hora de la consulta: </strong>
                    {new Date().toLocaleString()}
                  </div>
                  <CustomTable
                    rows={rows}
                    column={column}
                    plates_list={plates_list}
                  />
                  <ListGroup className="mt-3">
                    <ListGroup.Item className="d-flex justify-content-end text-uppercase font-weight-bold">
                      <strong>Total a pagar:</strong>{" "}
                      <span className="ml-3 text-5xl">
                        {totalDebtstoPay(dataDebts.debts)}
                      </span>
                    </ListGroup.Item>
                  </ListGroup>
                </>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </>
  );
};
export default ConsultationCemvalProvider;
