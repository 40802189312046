const isPlateValid = (value) => {
  let length = value.length;
  if (length === 7) {
    // Expresión Regular para validar Placas Vehiculares
    return /^(?![fF])([A-Z]{3}[0-9]{4})/.test(value);
  } else if (length === 6) {
    // Expresión Regular para validar Placas de Motos
    return /^([A-Z]{2}[0-9]{3}[A-Z]{1})/.test(value);
  }
};

const isIdentificationValid = (value, element) => {
  let sum = 0;
  let sum_total = 0;
  let residuo = 0;
  let digito = 0;
  if (value.length > 0 && !isNaN(parseInt(value))) {
    const nRegion = parseInt(value.substring(0, 2));

    if (
      value.length === 10 &&
      ((nRegion >= 1 && nRegion <= 24) || nRegion === 30)
    ) {
      for (let n = 0; n < value.length - 1; n++) {
        if ((n + 1) % 2 !== 0) sum = value.charAt(n) * 2;
        else sum = value.charAt(n) * 1;
        if (sum > 9) sum = sum - 9;
        sum_total = sum + sum_total;
      }
      residuo = sum_total % 10;
      if (residuo !== 0) digito = 10 - residuo;

      if (value.charAt(9) === digito) return true;
    }
    return false;
  }

  if (value.length > 0 && isNaN(parseInt(value))) {
    return false;
  }

  return true;
};

// const isIdentificationRUCValid = (value, element) => {
//   if (value.length > 0 && !isNaN(parseInt(value.substr(0, 10)))) {
//     let sum = 0;
//     let sum_total = 0;
//     let residuo = 0;
//     let digito = 0;
//     let coefp = [3, 2, 7, 6, 5, 4, 3, 2];
//     let coefj = [4, 3, 2, 7, 6, 5, 4, 3, 2];
//     let n = 0;
//     if (value.length == 13) {
//       const dig = value.substr(2, 1);
//       if (dig < 6) {
//         if (isIdentificationValid(value.substr(0, 10), element)) {
//           for (var i = 10; i < 13; i++) {
//             if (isNaN(parseInt(value.charAt(i))))
//               return false;
//           }
//           return true;
//         }
//       }
//       else if (dig == 6) {

//         for (n = 0; n < 8; n++) {
//           sum = value.charAt(n) * coefp[n];
//           sum_total = sum + sum_total;
//         }
//         residuo = sum_total % 11;
//         if (residuo != 0)
//           digito = 11 - residuo;

//         if (value.charAt(8) == digito)
//           return true;

//       }
//       else if (dig == 9) {

//         for (n = 0; n < 9; n++) {
//           sum = value.charAt(n) * coefj[n];
//           sum_total = sum + sum_total;
//         }
//         residuo = sum_total % 11;
//         if (residuo != 0)
//           digito = 11 - residuo;

//         if (value.charAt(9) == digito)
//           return true;
//       }

//     }
//     return false;
//   } else if (value.length > 0 && isNaN(parseInt(value))) {
//     return false;
//   }
//   return true;
// }

const isIdentificationRUCValid = (numero) => {
  var suma = 0;
  var residuo = 0;
  var pri = false;
  var pub = false;
  var nat = false;
  var numeroProvincias = 22;
  var modulo = 11;

  /* Verifico que el campo no contenga letras */
  var ok = 1;
  for (var i = 0; i < numero.length && ok === 1; i++) {
    var n = parseInt(numero.charAt(i));
    if (isNaN(n)) ok = 0;
  }
  if (ok === 0 || numero.length < 10) {
    return false;
  }

  /* Los primeros dos digitos corresponden al codigo de la provincia */
  var provincia = numero.substr(0, 2);
  if (provincia < 1 || provincia > numeroProvincias) {
    return false;
  }

  /* Aqui almacenamos los digitos de la cedula en variables. */
  var d1 = numero.substr(0, 1);
  var d2 = numero.substr(1, 1);
  var d3 = numero.substr(2, 1);
  var d4 = numero.substr(3, 1);
  var d5 = numero.substr(4, 1);
  var d6 = numero.substr(5, 1);
  var d7 = numero.substr(6, 1);
  var d8 = numero.substr(7, 1);
  var d9 = numero.substr(8, 1);
  var d10 = numero.substr(9, 1);

  /* El tercer digito es: */
  /* 9 para sociedades privadas y extranjeros   */
  /* 6 para sociedades publicas */
  /* menor que 6 (0,1,2,3,4,5) para personas naturales */

  if (d3 === 7 || d3 === 8) {
    return false;
  }

  /* Solo para personas naturales (modulo 10) */
  if (d3 < 6) {
    nat = true;
    var p1 = d1 * 2;
    if (p1 >= 10) p1 -= 9;
    var p2 = d2 * 1;
    if (p2 >= 10) p2 -= 9;
    var p3 = d3 * 2;
    if (p3 >= 10) p3 -= 9;
    var p4 = d4 * 1;
    if (p4 >= 10) p4 -= 9;
    var p5 = d5 * 2;
    if (p5 >= 10) p5 -= 9;
    var p6 = d6 * 1;
    if (p6 >= 10) p6 -= 9;
    var p7 = d7 * 2;
    if (p7 >= 10) p7 -= 9;
    var p8 = d8 * 1;
    if (p8 >= 10) p8 -= 9;
    var p9 = d9 * 2;
    if (p9 >= 10) p9 -= 9;
    modulo = 10;
  } else if (d3 === 6) {

  /* Solo para sociedades publicas (modulo 11) */
  /* Aqui el digito verficador esta en la posicion 9, en las otras 2 en la pos. 10 */
    pub = true;
    p1 = d1 * 3;
    p2 = d2 * 2;
    p3 = d3 * 7;
    p4 = d4 * 6;
    p5 = d5 * 5;
    p6 = d6 * 4;
    p7 = d7 * 3;
    p8 = d8 * 2;
    p9 = 0;
  } else if (d3 === 9) {

  /* Solo para entidades privadas (modulo 11) */
    pri = true;
    p1 = d1 * 4;
    p2 = d2 * 3;
    p3 = d3 * 2;
    p4 = d4 * 7;
    p5 = d5 * 6;
    p6 = d6 * 5;
    p7 = d7 * 4;
    p8 = d8 * 3;
    p9 = d9 * 2;
  }

  suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
  residuo = suma % modulo;

  /* Si residuo=0, dig.ver.=0, caso contrario 10 - residuo*/
  var digitoVerificador = residuo === 0 ? 0 : modulo - residuo;

  /* ahora comparamos el elemento de la posicion 10 con el dig. ver.*/
  if (pub === true) {
    if (digitoVerificador !== d9 || numero.substr(9, 4) !== "0001") {
      return false;
    }
  } else if (pri === true) {
    if (digitoVerificador !== d10 || numero.substr(10, 3) !== "001") {
      return false;
    }
  } else if (
    nat === true ||
    (numero.length > 10 && numero.substr(10, 3) !== "001")
  ) {
    if (digitoVerificador !== d10) {
      return false;
    }
  }
  return true;
};

const currentDate = () => {
  const today = new Date();
  let date = today.getDate();
  let month = today.getMonth() + 1;
  const year = today.getFullYear();

  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + date;
};

export {
  isIdentificationValid,
  isIdentificationRUCValid,
  currentDate,
  isPlateValid,
};
