import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Spinner,
  Table,
  Alert,
  ListGroup,
} from "react-bootstrap";
import axios from "axios";
import LogoGPM from "./assets/imgs/logo.png";
import LoadingAnimated from "./assets/imgs/timer.gif";
import {
  isIdentificationValid,
  isIdentificationRUCValid,
} from "./utils/Validator";
import { decodeJWT_RS256 } from "./utils/Utilities";
import NavBar from "./NavBar";
import ValidateLoader from "./ValidateLoader";
import { instance_py } from "./utils/InstancePy";

const optionsEntradaConsulta = [
  { value: "cedula", descripcion: "CÉDULA" },
  { value: "ruc", descripcion: "RUC" },
  { value: "placa", descripcion: "PLACA" },
];

export default function ContactInformation(props) {
  /*
      const initialResponseService = {ant_datoslicencia: {data: {}, state: "", loaded: false}, rc_datosdemograficos: {data: {}, state: "", loaded: false}};
  */
  const InitialResponseServiceCedula = {
    ant_datoslicencia: { data: {}, status: 0, loaded: false },
    rc_datosdemograficos: { data: {}, status: 0, loaded: false },
  };
  const InitialResponseServiceRuc = {
    sri_ruccontactos: { data: {}, status: 0, loaded: false },
  };
  const InitialResponseServicePlaca = {
    ant_datosvehiculares: { data: {}, status: 0, loaded: false },
  };

  const [responseServiceCedula, setResponseServiceCedula] = useState(
    InitialResponseServiceCedula
  );
  const [responseServiceRuc, setResponseServiceRuc] = useState(
    InitialResponseServiceRuc
  );
  const [responseServicePlaca, setResponseServicePlaca] = useState(
    InitialResponseServicePlaca
  );

  const [informationUser, setInformationUser] = useState([]);
  // const [informationUser, setInformationUser] = useState(
  //   {
  //     "nombrecompleto": "Alava Giler Nico Baldemar",
  //     "cedula": "1310595226",
  //     "direccion": "Dirección de Tecnología",
  //     "correo": "nalava@manabi.gob.ec",
  //     "cargo": "Asistente de Procesos"
  //   }
  // );
  const [valid, isValid] = useState(false);
  const [submit, isSubmit] = useState(false);
  const [justificacion, setJustificacion] = useState("");
  const [identificacionvalid, isIdentificacionValid] = useState(true);
  const { history } = props;

  const [identificacion, setIdentificacion] = useState("");
  const [entradaConsulta, setEntradaConsulta] = useState("");

  /* +++++++++++++++++++++++++++++++++++++++ */

  useEffect(() => {
    const configuration = localStorage.getItem("auth-jwt");
    if (configuration) {
      const objectJwt = decodeJWT_RS256(configuration);
      if (objectJwt.exp < Date.now() / 1000) {
        localStorage.clear("auth-jwt");
        history.push("/login");
      } else {
        setInformationUser(
          objectJwt.sub !== undefined ? objectJwt.sub : objectJwt.data
        );
      }
    }
  }, []);

  /* +++++++++++++++++++++++++++++++++++++++ */

  function toPDF() {
    let data_ = {};
    if (identificacion !== "" && entradaConsulta !== "") {
      switch (entradaConsulta) {
        case "cedula":
          if (
            Object.keys(responseServiceCedula.ant_datoslicencia.data).length ===
              0 &&
            Object.keys(responseServiceCedula.rc_datosdemograficos.data)
              .length === 0
          ) {
            break;
          }
          data_ = {
            antDl: {
              correo: responseServiceCedula.ant_datoslicencia.data.correo,
              direccion: responseServiceCedula.ant_datoslicencia.data.direccion,
              telefono: responseServiceCedula.ant_datoslicencia.data.telefono,
            },
            rcDd: {
              domicilio:
                responseServiceCedula.rc_datosdemograficos.data.domicilio,
              direccion:
                responseServiceCedula.rc_datosdemograficos.data.callesDomicilio,
            },
          };
          break;
        case "ruc":
          if (
            Object.keys(responseServiceRuc.sri_ruccontactos.data).length === 0
          ) {
            break;
          }
          data_ = {
            sriDr: responseServiceRuc.sri_ruccontactos.data[0].parameters.map(
              (contacto) => {
                return {
                  contacto: contacto[0].valor,
                  valor: contacto[3].valor,
                };
              }
            ),
          };
          break;
        case "placa":
          if (
            Object.keys(responseServicePlaca.ant_datosvehiculares.data)
              .length === 0
          ) {
            break;
          }
          data_ = {
            antDv: {
              correo: responseServicePlaca.ant_datosvehiculares.data.correo,
              direccion:
                responseServicePlaca.ant_datosvehiculares.data.direccion,
              telefono: responseServicePlaca.ant_datosvehiculares.data.telefono,
            },
          };
          break;
        default:
          break;
      }

      let data_body = {
        parameter: entradaConsulta,
        value: identificacion,
        data: data_,
      };

      //axios
      instance_py
        .post(`/v1/contactinformation/report-pdf`, data_body, {
          responseType: "arraybuffer",
          // headers: {
          //   'Authorization': 'Bearer ' + localStorage.getItem('auth-jwt').replaceAll(`"`, "")
          // }
        })
        .then((response) => {
          const filename = response.headers["content-disposition"]
            .split(";")[1]
            .split("=")[1];
          const file = new File([response.data], filename, {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = filename;
          link.click();
        })
        .catch((error) => {
          console.log(error);
          alert("Error al generar el Documento !!");
        });
    }
  }

  function toInputUppercase(event) {
    event.target.value = ("" + event.target.value).toUpperCase();
  }
  function labelEntradaConsulta() {
    switch (entradaConsulta) {
      case "cedula":
        return "INGRESE UN NÚMERO DE CÉDULA";
      case "ruc":
        return "INGRESE EL RUC";
      case "placa":
        return "INGRESE LA PLACA DEL VEHÍCULO";
      default:
        return "";
    }
  }
  function validateInputLength() {
    switch (entradaConsulta) {
      case "cedula":
        if (identificacion.length === 10) {
          return true;
        } else {
          return false;
        }
      case "ruc":
        if (identificacion.length === 13) {
          return true;
        } else {
          return false;
        }
      case "placa":
        if (identificacion.length >= 6 && identificacion.length <= 7) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }
  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (entradaConsulta === "" || identificacion === "") {
      if (form.checkValidity() === false) {
        isValid(true);
      }
    } else {
      isSubmit(true);

      if (entradaConsulta === "cedula") {
        axios
          .get(
            `https://apiservices.manabi.gob.ec/persona/ant/datoslicencia/${identificacion}`,
            {
              timeout: 5000,
            }
          )
          .then((resp) => {
            setResponseServiceCedula((value) => ({
              ...value,
              ant_datoslicencia: {
                data: resp.data,
                status: resp.status,
                loaded: true,
              },
            }));
            isSubmit(false);
          })
          .catch((error) => {
            setResponseServiceCedula(
              (value) => ({
                ...value,
                ant_datoslicencia: {
                  ...value.ant_datoslicencia,
                  status: 500,
                  loaded: true,
                },
              })
              //({...value, ant_datoslicencia: {...value.ant_datoslicencia, status: 0, loaded: false} })
            );
            console.error(error);
            isSubmit(false);
          });

        axios
          .get(
            `https://apiservices.manabi.gob.ec/persona/registrocivil/datosdemograficos/${identificacion}`,
            {
              timeout: 5000,
            }
          )
          .then((resp) => {
            setResponseServiceCedula((value) => ({
              ...value,
              rc_datosdemograficos: {
                data: resp.data,
                status: resp.status,
                loaded: true,
              },
            }));
            isSubmit(false);
          })
          .catch((error) => {
            setResponseServiceCedula((value) => ({
              ...value,
              rc_datosdemograficos: {
                ...value.rc_datosdemograficos,
                status: 500,
                loaded: true,
              },
            }));
            console.error(error);
            isSubmit(false);
          });
      } else if (entradaConsulta === "ruc") {
        axios
          .get(
            `https://apiservices.manabi.gob.ec/persona/sri/contactos/${identificacion}`,
            {
              timeout: 5000,
            }
          )
          .then((resp) => {
            setResponseServiceRuc((value) => ({
              ...value,
              sri_ruccontactos: {
                data: resp.data,
                status: resp.status,
                loaded: true,
              },
            }));
            isSubmit(false);
          })
          .catch((error) => {
            setResponseServiceRuc((value) => ({
              ...value,
              sri_ruccontactos: {
                ...value.sri_ruccontactos,
                status: 500,
                loaded: true,
              },
            }));
            console.error(error);
            isSubmit(false);
          });
      } else if (entradaConsulta === "placa") {
        axios
          .get(
            `https://apiservices.manabi.gob.ec/consultavehicular/ant/matriculacion/${identificacion}`,
            {
              timeout: 5000,
            }
          )
          .then((resp) => {
            setResponseServicePlaca((value) => ({
              ...value,
              ant_datosvehiculares: {
                data: resp.data,
                status: resp.status,
                loaded: true,
              },
            }));
            isSubmit(false);
          })
          .catch((error) => {
            setResponseServicePlaca((value) => ({
              ...value,
              ant_datosvehiculares: {
                ...value.ant_datosvehiculares,
                status: 500,
                loaded: true,
              },
            }));
            console.error(error);
            isSubmit(false);
          });
      }
    }
  }

  /* +++++++++++++++++++++++++++++++++++++++ */

  function renderContactInformationByCedula() {
    return (
      (responseServiceCedula.ant_datoslicencia.loaded ||
        responseServiceCedula.rc_datosdemograficos.loaded) &&
      entradaConsulta === "cedula" && (
        <Row>
          <Col sm={6}>
            <Card style={{ marginTop: 10 }}>
              <Card.Header className="text-center">
                ANT - Datos de la Licencia Vehicular
              </Card.Header>
              {(submit || !responseServiceCedula.ant_datoslicencia.loaded) && (
                <div className="text-center p-5">
                  <Spinner animation="border" variant="success" />
                </div>
              )}
              {Object.keys(responseServiceCedula.ant_datoslicencia.data)
                .length > 0 &&
              responseServiceCedula.ant_datoslicencia.data.fechaPrimeraVez !==
                "" &&
              responseServiceCedula.ant_datoslicencia.loaded ? (
                <>
                  <Card.Body>
                    <ListGroup>
                      <ListGroup.Item>
                        <strong>Correo:</strong>{" "}
                        {responseServiceCedula.ant_datoslicencia.data.correo}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Dirección:</strong>{" "}
                        {responseServiceCedula.ant_datoslicencia.data.direccion}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Teléfono(s):</strong>{" "}
                        {responseServiceCedula.ant_datoslicencia.data.telefono
                          .split(";")
                          .map((num, index) => {
                            if (num.length >= 7) {
                              return (
                                <>
                                  <a key={index}>{num} &nbsp;&nbsp;</a>
                                  <br></br>
                                </>
                              );
                            }
                          })}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </>
              ) : (
                <>
                  {Object.keys(responseServiceCedula.ant_datoslicencia.data)
                    .length === 0 &&
                  responseServiceCedula.ant_datoslicencia.status === 200 ? (
                    <Alert variant="warning">
                      EL PROPIETARIO DE LA CEDULA NO CUENTA CON UNA LICENCIA
                      VEHICULAR DE LA ANT.
                    </Alert>
                  ) : responseServiceCedula.ant_datoslicencia.status === 500 ? (
                    <Alert variant="danger">
                      LOS SERVICIOS DE LA ANT PRESENTAN INTERMITENCIA. INTENTE
                      DE NUEVO MÁS TARDE.
                    </Alert>
                  ) : null}
                </>
              )}
            </Card>
          </Col>
          <Col sm={6}>
            <Card style={{ marginTop: 10 }}>
              <Card.Header className="text-center">
                Registro Civil - Datos demográficos
              </Card.Header>
              {(submit ||
                !responseServiceCedula.rc_datosdemograficos.loaded) && (
                <div className="text-center p-5">
                  <Spinner animation="border" variant="success" />
                </div>
              )}
              {Object.keys(responseServiceCedula.rc_datosdemograficos.data)
                .length > 0 &&
              responseServiceCedula.rc_datosdemograficos.loaded ? (
                <>
                  <Card.Body>
                    <ListGroup>
                      <ListGroup.Item>
                        <strong>Domicilio:</strong>{" "}
                        {
                          responseServiceCedula.rc_datosdemograficos.data
                            .domicilio
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Dirección:</strong>{" "}
                        {
                          responseServiceCedula.rc_datosdemograficos.data
                            .callesDomicilio
                        }
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </>
              ) : (
                <>
                  {Object.keys(responseServiceCedula.rc_datosdemograficos.data)
                    .length === 0 &&
                  responseServiceCedula.rc_datosdemograficos.status === 200 ? (
                    <Alert variant="warning">
                      LA CEDULA INGRESADA NO EXISTE.
                    </Alert>
                  ) : responseServiceCedula.rc_datosdemograficos.status ===
                    500 ? (
                    <Alert variant="danger">
                      LOS SERVICIOS DEL REGISTRO CIVIL PRESENTAN INTERMITENCIA.
                      INTENTE DE NUEVO MÁS TARDE.
                    </Alert>
                  ) : null}
                </>
              )}
            </Card>
          </Col>
        </Row>
      )
    );
  }

  function renderContactInformationByRUC() {
    return (
      responseServiceRuc.sri_ruccontactos.loaded &&
      entradaConsulta === "ruc" && (
        <Row>
          <Col sm={12}>
            <Card style={{ marginTop: 10 }}>
              <Card.Header className="text-center">
                SRI - Datos de Contacto
              </Card.Header>
              {(submit || !responseServiceRuc.sri_ruccontactos.loaded) && (
                <div className="text-center p-5">
                  <Spinner animation="border" variant="success" />
                </div>
              )}
              {Object.keys(responseServiceRuc.sri_ruccontactos.data).length >
                0 && responseServiceRuc.sri_ruccontactos.loaded ? (
                <>
                  <Card.Body>
                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th>Tipo de Medio de Contacto</th>
                          <th>Medio de Contacto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {responseServiceRuc.sri_ruccontactos.data[0].parameters.map(
                          (contacto, index) => (
                            <tr key={index}>
                              <td>{contacto[0].valor}</td>
                              <td>{contacto[3].valor}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </>
              ) : (
                <>
                  {Object.keys(responseServiceRuc.sri_ruccontactos.data)
                    .length === 0 &&
                  responseServiceRuc.sri_ruccontactos.status === 200 ? (
                    <Alert variant="warning">EL RUC INGRESADO NO EXISTE.</Alert>
                  ) : responseServiceRuc.sri_ruccontactos.status === 500 ? (
                    <Alert variant="danger">
                      LOS SERVICIOS DEL SRI PRESENTAN INTERMITENCIA. INTENTE DE
                      NUEVO MÁS TARDE.
                    </Alert>
                  ) : null}
                </>
              )}
            </Card>
          </Col>
        </Row>
      )
    );
  }

  function renderContactInformationByPlaca() {
    return (
      responseServicePlaca.ant_datosvehiculares.loaded &&
      entradaConsulta === "placa" && (
        <Row>
          <Col sm={12}>
            <Card style={{ marginTop: 10 }}>
              <Card.Header className="text-center">
                ANT - Datos Vehiculares
              </Card.Header>
              {(submit ||
                !responseServicePlaca.ant_datosvehiculares.loaded) && (
                <div className="text-center p-5">
                  <Spinner animation="border" variant="success" />
                </div>
              )}
              {Object.keys(responseServicePlaca.ant_datosvehiculares.data)
                .length > 0 &&
              responseServicePlaca.ant_datosvehiculares.data.propietario !==
                "" &&
              responseServicePlaca.ant_datosvehiculares.loaded ? (
                <>
                  <Card.Body>
                    <ListGroup>
                      <ListGroup.Item>
                        <strong>Correo:</strong>{" "}
                        {responseServicePlaca.ant_datosvehiculares.data.correo}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Dirección:</strong>{" "}
                        {
                          responseServicePlaca.ant_datosvehiculares.data
                            .direccion
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Teléfono(s):</strong>{" "}
                        {responseServicePlaca.ant_datosvehiculares.data.telefono
                          .split(";")
                          .map((num, index) => {
                            if (num.length >= 7) {
                              return (
                                <>
                                  <a key={index}>{num} &nbsp;&nbsp;</a>
                                </>
                              );
                            }
                          })}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </>
              ) : (
                <>
                  {Object.keys(responseServicePlaca.ant_datosvehiculares.data)
                    .length === 0 &&
                  responseServicePlaca.ant_datosvehiculares.status === 200 ? (
                    <Alert variant="warning">
                      LA PLACA INGRESADA NO EXISTE.
                    </Alert>
                  ) : responseServicePlaca.ant_datosvehiculares.status ===
                    500 ? (
                    <Alert variant="danger">
                      LOS SERVICIOS DE LA ANT PRESENTAN INTERMITENCIA. INTENTE
                      DE NUEVO MÁS TARDE.
                    </Alert>
                  ) : null}
                </>
              )}
            </Card>
          </Col>
        </Row>
      )
    );
  }

  return (
    <Container fluid>
      <ValidateLoader {...props} />
      <NavBar {...props} />

      <Card style={{ marginTop: 15 }}>
        <Card.Header>DATOS DEL FUNCIONARIO</Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>NOMBRE COMPLETO:</span>{" "}
                {informationUser.nombrecompleto}
              </Card.Text>
            </Col>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>CÉDULA:</span>{" "}
                {informationUser.cedula}
              </Card.Text>
            </Col>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>DIRECCIÓN:</span>{" "}
                {informationUser.direccion}
              </Card.Text>
            </Col>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>CORREO:</span>{" "}
                {informationUser.correo}
              </Card.Text>
            </Col>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>CARGO:</span>{" "}
                {informationUser.cargo}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card style={{ marginTop: 15 }}>
        <Card.Header>PRESUNTOS DATOS DE CONTACTO</Card.Header>
        <Form
          noValidate
          validated={valid}
          onSubmit={handleSubmit}
          style={{ marginTop: 15 }}
        >
          <Card.Body>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  PARÁMETRO DE CONSULTA:
                </Form.Label>
                <Form.Control
                  as="select"
                  required
                  value={entradaConsulta}
                  onChange={(event) => {
                    setEntradaConsulta(event.target.value);
                    setIdentificacion("");
                    setResponseServiceCedula(InitialResponseServiceCedula);
                    setResponseServiceRuc(InitialResponseServiceRuc);
                    setResponseServicePlaca(InitialResponseServicePlaca);
                  }}
                >
                  <option></option>
                  {optionsEntradaConsulta.map((option, idx) => {
                    return (
                      <option key={idx} value={option.value}>
                        {option.descripcion}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Elija un parámetro de entrada.
                </Form.Control.Feedback>
              </Form.Group>
              {entradaConsulta && (
                <>
                  <Form.Group as={Col} md="6">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      {labelEntradaConsulta()}:
                    </Form.Label>
                    <Form.Control
                      as="input"
                      required
                      isInvalid={!identificacionvalid}
                      isValid={identificacionvalid && validateInputLength()}
                      type="text"
                      placeholder={labelEntradaConsulta()}
                      value={identificacion}
                      onChange={(event) => {
                        setIdentificacion(event.target.value.toUpperCase());
                        if (event.target.value.length === 10) {
                          isIdentificacionValid(
                            isIdentificationValid(event.target.value.trim())
                          );
                          //setPersonInformation(event.target.value.trim());
                          //settingInformationCustomer(event.target.value.trim());
                        } else if (event.target.value.length === 13) {
                          isIdentificacionValid(
                            isIdentificationRUCValid(event.target.value.trim())
                          );
                        }
                      }}
                      maxLength={13}
                      minLength={7}
                    />
                    <Form.Control.Feedback type="invalid">
                      {entradaConsulta === "cedula"
                        ? "Ingrese una Cédula valida!!!"
                        : entradaConsulta === "ruc"
                        ? "Ingrese un RUC valido!!!"
                        : "Ingrese una Placa valida!!!"}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      DETALLE DE JUSTIFICACIÓN:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      required
                      type="text"
                      placeholder="INGRESE DETALLE DE LA JUSTIFICACIÓN ..."
                      defaultValue={justificacion}
                      onChange={(event) => setJustificacion(event.target.value)}
                      onInput={toInputUppercase}
                      maxLength={300}
                      minLength={15}
                    />
                    <Form.Control.Feedback type="invalid">
                      Ingrese detalle de la justificación.
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </Form.Row>
          </Card.Body>
          <Card.Footer className="text-muted">
            <Row
              style={{ justifyContent: "space-evenly" }}
              className="btn-toolbar"
            >
              <Button
                variant="primary"
                type="submit"
                style={{ marginBottom: 15 }}
                disabled={submit}
              >
                {!submit && "ENVIAR DATOS"}
                {submit && <Spinner animation="border" variant="info" />}
              </Button>
              <Button
                variant="danger"
                type="button"
                style={{ marginBottom: 15 }}
                onClick={() => toPDF()}
              >
                GENERAR PDF
              </Button>
            </Row>
          </Card.Footer>
        </Form>
      </Card>
      <Card style={{ marginTop: 15 }}>
        <Card.Header>RESULTADO DE LA BÚSQUEDA</Card.Header>
        <Card.Body>
          {submit && (
            <img
              className="mx-auto d-block"
              src={LoadingAnimated}
              alt="Cargando..."
            />
          )}
          {/* {(Object.keys(responseServiceCedula).length === 0 && !submit) &&
          <img className="mx-auto d-block" src={EmptyAnimated} alt="No se ha encontrado resultados..." />} */}

          {renderContactInformationByCedula()}
          {renderContactInformationByRUC()}
          {renderContactInformationByPlaca()}
        </Card.Body>
      </Card>
      <br></br>
    </Container>
  );
}
