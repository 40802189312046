import React from "react";
import { Container, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getDateNowString } from "../utils/Utilities";
import { defaultProps } from "react-recaptcha";
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from "react-icons/hi";

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export default function TableReactBootstrapNext(props) {
  const { 
    data,     
    columns,
    fileName,
    responsive,
    keyField,
    showPagination,
    showSearchBar,
   } = props;

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const ExportCSVButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div style={{paddingBottom: "12px"}}>
        <button style={{marginTop: 5, backgroundColor: 'rgb(21, 122, 68)'}} className="btn btn-success"
                onClick={handleClick}>Exportar a CSV
        </button>
        <button style={{marginTop: 5, backgroundColor: 'rgb(21, 122, 68)', marginLeft: 10}}
                className="btn btn-success"
                onClick={() => exportToCSV(data, fileName)}>Exportar a Excel
        </button>
      </div>
    );
  };

  const SearchBar = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };

    return (
      <div>
        <input
          className="form-control"
          style={{marginTop: 8}}
          ref={n => input = n}
          type="text"
          onChange={handleClick}
          placeholder='Realice su búsqueda...'
        />
      </div>
    );
  };

  const contentTable = ({paginationProps, paginationTableProps}) => (
    <div>
      <ToolkitProvider
        keyField={keyField}
        columns={columns}
        data={data}
        search
        exportCSV={{
          fileName: `${fileName+`--${getDateNowString('DD-MM-YYYY')}`}.csv`,
          onlyExportFiltered: true,
          exportAll: false,
          noAutoBOM: false,
          blobType: "data:text/csv;charset=utf-8"
        }}
      >
        {
          toolkitprops => (
            <div>
              <ExportCSVButton {...toolkitprops.csvProps} />
              {showSearchBar && <SearchBar {...toolkitprops.searchProps} />}
              <BootstrapTable
                bootstrap4={responsive}
                classes={responsive ? 'table-responsive' : ''}
                striped
                hover
                filter={filterFactory()}
                {...toolkitprops.baseProps}
                {...paginationTableProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );

  const contentTableWhitOutPagination = ({paginationTableProps}) => (
      <ToolkitProvider
        keyField={keyField}
        columns={columns}
        data={data}
        search
        exportCSV={{
          fileName: `${fileName+`--${getDateNowString('DD-MM-YYYY')}`}.csv`,
          onlyExportFiltered: true,
          exportAll: false,
          noAutoBOM: false,
          blobType: "data:text/csv;charset=utf-8"
        }}
      >
        {
          toolkitprops => (
            <div>
              <ExportCSVButton {...toolkitprops.csvProps} />
              {showSearchBar && <SearchBar {...toolkitprops.searchProps} />}
              <BootstrapTable
                bootstrap4={responsive}
                classes={responsive ? 'table-responsive' : ''}
                striped
                hover
                filter={filterFactory()}
                {...toolkitprops.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
  );

  return (
    <>
    <Container style={{marginTop: 15}}>
      {showPagination ? <PaginationProvider
        pagination={
          paginationFactory({
            page: 1,
            sizePerPage: 10,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            showTotal: true,
            paginationTotalRenderer: (from, to, size) => (
              <span className="react-bootstrap-table-pagination-total">
                Mostrando del { from } al { to }.<p>Total de Resultados <strong>{ size }</strong>.</p>
              </span>
            ),
            pageButtonRenderer: ({
              page,
              active,
              disable,
              title,
              onPageChange
            }) => {
              const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
              };

              const activeStyle = {
                borderColor: '#28a745',
                margin: ".05rem",
                zIndex: "10000",
              };
              if (active) {
                activeStyle.backgroundColor = 'rgb(21, 122, 68)';
                activeStyle.borderColor = 'rgb(21, 122, 68)';
                activeStyle.color = 'white';
              } else {
                activeStyle.backgroundColor = '#28a745';
                activeStyle.color = 'white';
              }
              if (typeof page === 'string') {
                activeStyle.backgroundColor = 'white';
                activeStyle.color = 'black';
              }

              const handleIcon = (page) => {
                if (page === '<<') return <HiChevronDoubleLeft color='rgb(21, 122, 68)' size='1.3rem' />
                if (page === '>>') return <HiChevronDoubleRight color='rgb(21, 122, 68)' size='1.3rem' />
                if (page === '<') return <HiChevronLeft color='rgb(21, 122, 68)' size='1.3rem' />
                if (page === '>') return <HiChevronRight color='rgb(21, 122, 68)' size='1.3rem' />
                return page

              }
              const hoverButton = (event) => {
                if (event.target.tagName === 'BUTTON'){
                  event.target.style.boxShadow = '0 0 0 0.2rem rgb(21 122 68 / 50%)';
                }
              }

              const leaveButton = (event) => {
                if (event.target.tagName === 'BUTTON') {
                  event.target.style.boxShadow = '';
                }
              }

              return (
                <li className="page-item">
                  <Button 
                    onFocus={hoverButton}
                    onBlur={leaveButton}
                    onMouseOver={hoverButton}
                    onMouseOut={leaveButton}

                    onClick={handleClick}
                    style={activeStyle}
                  >{handleIcon(page)}
                  </Button>
                </li>
              );
            },
          })
        }
      >{contentTable}
      </PaginationProvider>: 
      <>{contentTableWhitOutPagination(props)}</>
      }

    </Container>
    </>
  );
}

TableReactBootstrapNext.defaultProps = {
  data: [],
  columns: [],
  fileName: 'file',
  responsive: false,
  showPagination: true,
  showSearchBar: true,
}