import React, { useState } from "react";

import Accordion from "react-bootstrap/Accordion";

import Card from "react-bootstrap/Card";

import Button from "react-bootstrap/Button";

import Table from "react-bootstrap/Table";

import Pagination from "react-bootstrap/Pagination";

import { Dropdown } from "react-bootstrap";

import usePagination from "../../../hooks/usePagination";

export function createData(
  plate,
  year,
  valueBase,
  interest,
  exoneracion,
  value_to_pay,
  details
) {
  return {
    plate,
    year,
    valueBase,
    interest,
    exoneracion,
    value_to_pay,
    details,
  };
}

const CustomTable = ({ column, rows, plates_list }) => {
  const [expandedRow, setExpandedRow] = useState(null);

  const [itemsPerPage, setItemsPerPage] = useState(5);

  const {
    next,
    prev,
    currentData,
    currentPage,
    jump,
    maxPage,
    filterPerPlate,
    plateSelected,
  } = usePagination(rows, itemsPerPage);

  const handleRowClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  return (
    <>
      <Dropdown className="mb-3">
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          {plateSelected || "Todas las placas"}
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <Dropdown.Item onClick={() => filterPerPlate("")}>
            Todos
          </Dropdown.Item>
          {plates_list.map((plate, index) => (
            <Dropdown.Item onClick={() => filterPerPlate(plate)} key={index}>
              {plate}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Table responsive bordered hover>
        <thead>
          <tr>
            <th />
            {column.map((col, index) => (
              <th key={index} className={col.align}>
                {col.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, index) => (
            <React.Fragment key={index}>
              <tr onClick={() => handleRowClick(index)}>
                <td>
                  <Button
                    variant="link"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(index);
                    }}
                  >
                    {expandedRow === index ? "-" : "+"}
                  </Button>
                </td>
                <td className="text-left">{row.plate}</td>
                <td className="text-left">{row.year}</td>
                <td className="text-left">{row.valueBase}</td>
                <td className="text-left">{row.interest}</td>
                <td className="text-left">{row.exoneracion}</td>
                <td className="text-left">{row.value_to_pay}</td>
              </tr>
              {expandedRow === index && (
                <tr>
                  <td colSpan="7">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="cursor-pointer"
                        >
                          Detalles
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Card.Text>{row.details}</Card.Text>
                          </Card.Body>
                        </Accordion.Collapse>
                        {/* <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Customer</th>
                                  <th className="text-right">Amount</th>
                                  <th className="text-right">
                                    Total price ($)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {row.details.map((historyRow) => (
                                  <tr key={historyRow.date}>
                                    <td>{historyRow.date}</td>
                                    <td>{historyRow.customerId}</td>
                                    <td className="text-right">
                                      {historyRow.amount}
                                    </td>
                                    <td className="text-right">
                                      {Math.round(
                                        historyRow.amount * row.price * 100
                                      ) / 100}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Accordion.Collapse> */}
                      </Card>
                    </Accordion>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <Pagination>
          <Pagination.First onClick={() => jump(1)} />
          <Pagination.Prev onClick={prev} />
          <Pagination.Item>{currentPage}</Pagination.Item>
          <Pagination.Next onClick={next} />
          <Pagination.Last onClick={() => jump(maxPage)} />
        </Pagination>
        <div className="text-right d-flex align-items-center justify-content-end gap-8">
          <span>Filas por página</span>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              {itemsPerPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setItemsPerPage(10);
                }}
              >
                10
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setItemsPerPage(20);
                }}
              >
                20
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setItemsPerPage(50);
                }}
              >
                50
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setItemsPerPage(100);
                }}
              >
                100
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default CustomTable;
