import { useState, useEffect } from "react";

const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [filteredData, setFilteredData] = useState(data);

  const [maxPage, setMaxPage] = useState(1);

  const [plateSelected, setPlateSelected] = useState("");

  useEffect(() => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    setMaxPage(totalPages);
  }, [filteredData, itemsPerPage]);

  // Función para actualizar los datos filtrados al cambiar la placa
  const filterPerPlate = (plate) => {
    if (!plate) {
      setFilteredData(data);
      setPlateSelected("");
    } else {
      setPlateSelected(plate);
      const filtered = data.filter((row) => row.plate === plate);
      setFilteredData(filtered);
    }
    setCurrentPage(1);
  };

  const currentData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const next = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  };

  const prev = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };

  const jump = (page) => {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  };

  return {
    next,
    prev,
    currentData,
    currentPage,
    jump,
    maxPage,
    filterPerPlate,
    plateSelected,
  };
};

export default usePagination;
