import React, { useState, useEffect } from 'react';
import { Container, Form, Spinner } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getListDate } from '../utils/Utilities';
import { instance_py } from '../utils/InstancePy';

const columns = [
  { dataField: 'cedula', text: 'Cédula'},
  { dataField: 'funcionario', text: 'Nombres Completos'},
  { dataField: 'departamento', text: 'Departamento'},
  { dataField: 'fecha', text: 'Fecha de Encuesta' },
];

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export default function ListaFuncionariosEncuestados(props){
  
  const [listFuncEnc, setListFuncEnc] = useState([]);
  const [anio, setAnio] = useState('');
  const aniosList = getListDate(2020);  
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    instance_py.get(`/v1/cuestionarioOcupacional/funcionarios${anio !== '' ? ("?anio=" + String(anio)) : ''}`
    ).then((resp) => {
      setListFuncEnc(resp.data.data.funcionarios);
      setLoader(false);
    }).catch((error) => {
      console.log(error);
      setLoader(false);
    });
  }, [anio]);

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const SpinnerLoader = (props) => {
    return (
      <Spinner animation="border" role="status" style={{
        position: props.position,
        top: "50%",
        left: "50%",
        marginTop: "-50px",
        marginLeft: "-50px",
        width: "100px",
        height: "100px",
        color: "#137b43"
      }}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

    const ExportCSVButton = (props) => {
      const handleClick = () => {
        props.onExport();
      };

      return (
        <div>
          <button style={{ marginTop: 5, backgroundColor: 'rgb(21, 122, 68)' }} className="btn btn-success" onClick={handleClick}>Exportar a CSV</button>
          <button style={{ marginTop: 5, backgroundColor: 'rgb(21, 122, 68)', marginLeft: 10 }} className="btn btn-success" onClick={() => exportToCSV(listFuncEnc, `FuncionariosEncuestados_${anio}`)}>Exportar a Excel</button>
        </div>
      );
    };

    const SearchBar = (props) => {
      let input;
      const handleClick = () => {
        props.onSearch(input.value);
      };

      return (
        <div>
          <input
            className="form-control"
            style={{ marginTop: 8 }}
            ref={n => input = n}
            type="text"
            onChange={handleClick}
            placeholder='Realice su busqueda ..'
          />
        </div>
      );
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <Form.Group>
          <Form.Label>Filtrar por año: </Form.Label>
          <Form.Control 
            as="select"
            value={anio}
            onChange={(e) => {
              setAnio(e.target.value);
            }}
          >
            <option value={''}>TODOS</option>
            { aniosList.length > 0 && aniosList.map((anio, index) => {
              return <option key={index} value={anio}>{anio}</option>
            }) }
          </Form.Control>
        </Form.Group>
        <ToolkitProvider
          keyField="descripcion"
          columns={columns}
          data={listFuncEnc}
          search
          exportCSV={{
            fileName: `funcionarios-encuestados-${anio}.csv`,
            onlyExportFiltered: true,
            exportAll: false,
            noAutoBOM: false,
            blobType: "data:text/csv;charset=utf-8"
          }}
        >
          {
            toolkitprops => (
              <div>
                <ExportCSVButton {...toolkitprops.csvProps} />
                <SearchBar {...toolkitprops.searchProps} />
                <BootstrapTable
                  bootstrap4
                  striped
                  hover
                  pagination={ paginationFactory() }
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />
              </div>
            )
          }
        </ToolkitProvider>        
      </div>
    );

    return (
      <>
        <Container style={{ marginTop: 15 }}>
          {!loader ? <PaginationProvider
            pagination={
              paginationFactory(
                {
                  page: 1,
                  sizePerPage: 10,
                  hideSizePerPage: true,
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  paginationTotalRenderer: (from, to, size) => (
                    <span className="react-bootstrap-table-pagination-total">
                      Mostrando del { from } al { to }.<p>Total de Resultados <strong>{ size }</strong>.</p>
                    </span>
                  )
                }
              )
            }
          >{contentTable}
          </PaginationProvider>:<div style={{height: 200}}><SpinnerLoader position="absolute" /></div>}
        </Container>
      </>
    );
  
}
