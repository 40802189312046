import { instance_py } from "../../utils/InstancePy";

export const getTransactions = async (values) => {
  const { page, page_size, year, filter_value, view_validated } = values;
  const params = {
    page: page,
    page_size: page_size,
    year: year,
    filter_value: filter_value,
    view_validated: view_validated,
  };

  const queryString = Object.entries(params)
    .filter(
      ([key, value]) => value !== undefined && value !== null && value !== ""
    )
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  try {
    const response = await instance_py.get(
      `/v1/print_roll/transactions?${queryString}`
    );
    const { data } = response.data;
    return data;
  } catch (error) {
    return { error: error };
  }
};
