import axios from 'axios';

const instance = axios.create({
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    withCredentials: false,
    //baseURL: 'http://172.20.120.12/api-donacion/public',
    
    baseURL: 'https://gestionproyectos.manabi.gob.ec/api-donacion/public',
    
    //baseURL: '192.168.122.1'
    //baseURL: 'http://gestionproyectos.manabi.gob.ec/api-donacion/public'
});

instance.interceptors.request.use(
    config => {
        const configuration = localStorage.getItem('auth-jwt');
        if(configuration) {
          const auth = JSON.parse(configuration);
          if (auth) {
            config.headers['APP-TOKEN'] = auth;
          }
        }


        return config;
    },
    error => {
        const { response: { status } } = error;
        if (status === 401) {
            localStorage.clear('auth-jwt');
        } else {
            return Promise.reject(error);
        }
    },
);

export default instance;
