import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Donacion from './Donacion';
import DonacionPatio from "./DonacionPatio";
import NotFound from "./NotFound";
import Login from "./Login";
import ListaDonaciones from "./ListaDonaciones";
import PuntosEjesInternet from "./PuntosEjesInternet";
import { ProtectedRoute } from "./protected.route";
import EncuestaOcupacional from "./EncuestaOcupacional";
// import ListaResultadoEncuesta from './ListaResultadoEncuesta';
// import ListaResultadoTercilEncuesta from './ListaResultadoTercilEncuesta';
// import ListaConteoTotalizadoPorGrupo from './ListaConteoTotalizadoPorGrupo';
import Dinardap from "./Dinardap";
import ContactInformation from "./ContactInformation";
import Modules from "./Modules";
import ResultadoEncuesta from "./ResultadoEncuesta";
import ListaProcesosContratacion from "./ProcesosContratacion/ListaProcesosContratacion";
import ConsultaTramitesBPM from "./ConsultaTramitesBPM/ConsultaTramitesBPM";
import ExperienciaOferente from "./ExperienciaOferente";
import ConsultaRecaudacionCemvial from "./ConsultaRecaudacionCemvial/ConsultaRecaudacionCemvial";
import InstrumentMatrix from "./InstrumentMatrix/InstrumentMatrix";
import PsychosocialSurvey from "./EncuestaPsicosocial/PsychosocialSurvey";

import RolAssign from "./Administracion/RolAssign";

// import Asistencia from './Asistencia';
import FormularioOnline from "./FormularioOnline";
import "react-dropzone-uploader/dist/styles.css";
import ConsultationCemvalProvider from "./pages/Cemvial";
import CemvialTransactionQueries from "./pages/CemvialTransactionQueries";

export default class App extends Component {
  render() {
    return (
      <Router basename="/modulos-gpm">
        <div className="container">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/personalpatio" component={DonacionPatio} />
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/seminario/registro"
              component={FormularioOnline}
            />
            <ProtectedRoute exact path="/modulos" component={Modules} />
            <ProtectedRoute exact path="/dinardap" component={Dinardap} />
            <ProtectedRoute
              exact
              path="/informacion-contacto"
              component={ContactInformation}
            />
            <ProtectedRoute
              exact
              path="/encuesta-ocupacional"
              component={PsychosocialSurvey}
            />
            <ProtectedRoute
              exact
              path="/resultados-encuesta"
              component={ResultadoEncuesta}
            />
            <ProtectedRoute
              exact
              path="/lista-donaciones"
              component={ListaDonaciones}
            />
            <Route
              exact
              path="/puntos-ejes-internet"
              component={PuntosEjesInternet}
            />
            <ProtectedRoute
              exact
              path="/procesos-contratacion"
              component={ListaProcesosContratacion}
            />
            <ProtectedRoute
              exact
              path="/consulta-recaudacion-cemvial"
              component={ConsultaRecaudacionCemvial}
            />
            <ProtectedRoute
              exact
              path="/consulta-deudores-cemvial"
              component={ConsultationCemvalProvider}
            />
            <ProtectedRoute
              exact
              path="/consulta-transacciones-bancarias-cemvial"
              component={CemvialTransactionQueries}
            />
            <Route
              exact
              path="/matriz-instrumentos"
              component={InstrumentMatrix}
            />
            <Route exact path="/asignacion-roles" component={RolAssign} />
            <Route
              exact
              path="/consulta-de-tramites"
              component={ConsultaTramitesBPM}
            />
            <Route path="*" exact={true} component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}
