import React from "react";
import { Spinner } from "react-bootstrap";

import Overlay from "react-bootstrap/Overlay";

import Popover from "react-bootstrap/Popover";

const ConfirmationDialog = ({ show, target, setTarget, onclick, loading }) => {
  return (
    <Overlay show={show} target={target} placement="top" container={target}>
      <Popover
        style={{
          border: "none",
          boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
        }}
        id="popover-contained"
      >
        <Popover.Title
          style={{
            color: "black",
          }}
          as="h3"
        >
          ¿Está seguro de realizar está acción?
        </Popover.Title>
        <Popover.Content>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div
              className="d-flex justify-content-end"
              style={{
                gap: "10px",
              }}
            >
              <button
                className="btn btn-danger py-0 px-3"
                onClick={() => {
                  setTarget(null);
                }}
              >
                No
              </button>
              <button
                className="btn btn-success py-0 px-3"
                onClick={() => {
                  if (onclick) {
                    onclick();
                  }
                }}
              >
                Si
              </button>
            </div>
          )}
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

export default ConfirmationDialog;
