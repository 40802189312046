import axios from "axios";
import { decodeJWT_RS256 } from "./Utilities";

const instance_py = axios.create({
  headers: { "Content-Type": "application/json; charset=utf-8" },
  withCredentials: false,
  baseURL: "https://apiservices.manabi.gob.ec/api-gestion_proyectos",
  // baseURL: "https://092bc8pl-4040.use2.devtunnels.ms",
  //baseURL: 'http://localhost:3015',
});

const refreshAccessToken = async (refresh) => {
  const refreshToken = await axios
    .get("https://apiservices.manabi.gob.ec/api-gestion_proyectos/v1/refresh", {
      headers: {
        Authorization: "Bearer " + JSON.parse(refresh),
      },
    })
    .then(async (resp) => {
      // console.log(resp);
      const { access_token, refresh_token } = resp.data;
      localStorage.setItem("auth-jwt", JSON.stringify(access_token));
      localStorage.setItem("refresh-jwt", JSON.stringify(refresh_token));
      return { access_token: access_token, refresh_token: refresh_token };
    })
    .catch((error) => {
      console.log(error);
      localStorage.clear("auth-jwt");
      localStorage.clear("refresh-jwt");
    });
  return refreshToken;
};

async function validateModuleAccess(path) {
  var isValid = false;
  const auth = localStorage.getItem("auth-jwt");
  // instance_py.get("/v1/module/validateAccess", { params: { path: path } })
  // isValid = await axios.get("https://apiservices.manabi.gob.ec/api-gestion_proyectos/v1/module/validateAccess", {
  isValid = await instance_py
    .get("/v1/module/validateAccess", {
      params: { path: path },
      headers: {
        Authorization: "Bearer " + JSON.parse(auth),
      },
    })
    .then((resp) => {
      // console.log(resp);
      return resp.data.data;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
  return isValid;
}

async function logout(AccessToken, RefreshToken) {
  const response = await Promise.all([
    axios.delete(
      "https://apiservices.manabi.gob.ec/api-gestion_proyectos/v1/logout",
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(AccessToken),
        },
      }
    ),
    axios.delete(
      "https://apiservices.manabi.gob.ec/api-gestion_proyectos/v1/logout",
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(RefreshToken),
        },
      }
    ),
  ])
    .then(async function (values) {
      const response = {
        AccessTokenResp: values[0],
        RefreshTokenResp: values[0],
      };
      return response;
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.clear("auth-jwt");
        localStorage.clear("refresh-jwt");
      }
    });

  return response;
}

instance_py.interceptors.request.use(
  async (config) => {
    const configuration = localStorage.getItem("auth-jwt");
    const refresh = localStorage.getItem("refresh-jwt");
    if (configuration && refresh) {
      let auth = JSON.parse(configuration);
      // console.log(auth);
      if (auth) {
        config.headers["Authorization"] = "Bearer " + auth;
      }
    }
    return config;
  },
  (error) => {
    const {
      response: { status },
    } = error;
    if (status === 401) {
      localStorage.clear("auth-jwt");
      localStorage.clear("refresh-jwt");
    } else {
      return Promise.reject(error);
    }
  }
);

instance_py.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const refresh = localStorage.getItem("refresh-jwt");

    if (
      error.response !== undefined &&
      error.response.status !== undefined &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      refresh !== "undefined"
    ) {
      originalRequest._retry = true;
      const { access_token } = await refreshAccessToken(refresh);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return instance_py(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { instance_py, logout, validateModuleAccess };
