import React, { Component } from 'react';
import { Container, Form, Alert, Image, Col, Spinner, Button, Row } from 'react-bootstrap';
// import instance from './utils/Instance';
// import instance_py from './utils/InstancePy';
import axios from "axios";
import {isPermitEncuestaFlow} from './utils/Utilities';

import LogoGPM from './assets/imgs/logo.png';

export default class Login extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      isSubmitted: false,
      showModal: false,
      username: '',
      password: ''
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const { history } = this.props;
    const configuration = localStorage.getItem('auth-jwt');
    const auth = JSON.parse(configuration);
    if (auth) {
      const surveyOn = isPermitEncuestaFlow();
      if (surveyOn) {
        history.replace('/resultados-encuesta');
      } else {
        history.replace('/dinardap');
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { validated, username, password, isSubmitted } = this.state;
    return (
      <Container style={{ marginTop: 15, border: '1px solid rgb(21, 122, 68)', padding: 0, borderRadius: 5 }}>
        <Alert variant="success" style={{ backgroundColor: '#157a44' }}>
          <Alert.Heading style={{ justifyContent: 'center', textAlign: 'center' }}>
            <p style={{ color: 'white', fontSize: 'larger', fontWeight: '700' }}>Ingreso GPM</p>
          </Alert.Heading>
          <Image src={LogoGPM} style={{
            filter:"brightness(500%)", 
            marginLeft: "auto", 
            marginRight: "auto",
            display: "block"
            }} fluid />
          <hr />
        </Alert>
        <Form noValidate validated={validated} onSubmit={(event) => this.handleSubmit(event)} style={{ marginTop: 15 }}>
          <Form.Row style={{ marginRight: 0, marginLeft: 0 }}>
            <Form.Group as={Col} md={{ span: 4, offset: 4 }} controlId="Usuario">
              <Form.Label>Nombre de usuario:</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Ingrese usuario del BPM"
                defaultValue={username}
                onChange={event => this.setState({ username: event.target.value })}
                maxLength={50}
                minLength={5}
              //pattern="[A-Za-z ]{4,50}"
              />
              <Form.Control.Feedback type="invalid">
                Ingrese nombre de usuario.
                             </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={{ span: 4, offset: 4 }} controlId="Password">
              <Form.Label>Contraseña:</Form.Label>
              <Form.Control
                required
                placeholder="Ingrese contraseña del BPM"
                defaultValue={password}
                onChange={event => this.setState({ password: event.target.value })}
                maxLength={50}
                minLength={4}
                type="password"
              />
              <Form.Control.Feedback type="invalid">
                Ingrese una contraseña.
                             </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Row style={{ justifyContent: 'center' }}>
            <Button
              variant="success"
              type="submit"
              style={{ marginBottom: 15 }}
            >
              {!isSubmitted && "Enviar Datos"}
              {isSubmitted && <Spinner animation="border" variant="info" />}
            </Button>
          </Row>
        </Form>
      </Container>
    )
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    const { username, password } = this.state;
    const { history } = this.props;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });
    } else {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isSubmitted: true
      });

      const objectLogin = {
        username, password
      };      
      axios.post('https://apiservices.manabi.gob.ec/api-gestion_proyectos/v1/login', objectLogin)
        .then(resp => {
          
          const { access_token, refresh_token } = resp.data;
          // console.log(resp.data);
          if (access_token) {
            this.setState({
              isSubmitted: false,
              validated: false
            });
          
            localStorage.setItem('auth-jwt', JSON.stringify(access_token));
            localStorage.setItem('refresh-jwt', JSON.stringify(refresh_token));
            //console.log(access_token);

            if (this._isMounted) {
              /* history.push('/lista-donaciones'); */
              /* history.push('/encuesta-ocupacional'); */
              // history.push('/dinardap');

              // const surveyOn = isPermitEncuestaFlow();
              // if (surveyOn) {
              //   history.replace('/resultados-encuesta');
              // } else {
              //   history.replace('/dinardap');
              // }
              
              history.replace('/modulos');

            }
          } else {
            alert('Datos no validado de inicio de sesión.');
            this.setState({
              isSubmitted: false,
              validated: false
            });
          }
        })
        .catch(error => {
          // alert('Hubo un error');
          console.log(error);
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            if (error.response.status === 401){
              alert("Estimado Usuario(a). Los datos de Inicio de Sesión no son validos.");
            } else if (error.response.status === 400){
              alert("Estimado Usuario(a). Ha ocurrido un error al enviar la solicitud.");
            } else if (error.response.status === 500){
              alert("Estimado Usuario(a). Ha ocurrido un error al enviar la solicitud.");
            }
          } else if (error.request) {
            // The request was made but no response was received
            // console.log(error.request);
            alert("Estimado Usuario(a). Ha ocurrido un error al enviar la solicitud.");
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          this.setState({ isSubmitted: false, validated: false });
        });
    }
  }
}
