/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Tab, Card, Form, Row, Col, Nav } from "react-bootstrap";
import "../assets/styles/GroupedQuestionTabs.css";

function GroupedQuestionTabs({ data, selectedOptions, setSelectedOptions }) {
  const handleSelectChange = (questionId, value) => {
    // Encuentra el índice de la pregunta en el array selectedOptions
    const questionIndex = selectedOptions.findIndex(
      (option) => option.question_id === questionId
    );

    // Crea una copia del array selectedOptions
    const updatedSelectedOptions = [...selectedOptions];

    // Si la pregunta ya existe en el array, actualiza su respuesta, de lo contrario, agrega una nueva entrada.
    if (questionIndex !== -1) {
      updatedSelectedOptions[questionIndex].response = value;
    } else {
      updatedSelectedOptions.push({ question_id: questionId, response: value });
    }

    // Actualiza el estado con el nuevo array de selectedOptions
    setSelectedOptions(updatedSelectedOptions);
  };

  return (
    <Card>
      <Card.Body id="grouped-question-tabs-container">
        <Tab.Container
          id="grouped-question-tabs"
          variant="pills"
          defaultActiveKey={data[0].id}
        >
          <Row>
            <Col
              sm={"3"}
              style={{
                borderRight: "1px solid #dee2e6",
              }}
            >
              <Nav variant="pills" className="tab-rb flex-column">
                {data.map((group, idx) => (
                  <Nav.Item key={group.id}>
                    <Nav.Link eventKey={group.id}>
                      <Row>
                        <Col sm={"2"}>
                          <h3>{idx + 1}</h3>
                        </Col>
                        <Col sm={"10"}>{group.name}</Col>
                      </Row>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={"9"}>
              <Tab.Content>
                {data.map((group, groupIdx) => (
                  <Tab.Pane key={group.id} eventKey={group.id}>
                    {group.questions.map((question) => (
                      <div key={question.id} style={{ padding: "15px 10px" }}>
                        {question.name !== "Observaciones y comentarios." ? (
                          <Form.Group>
                            <Form.Label>{question.name}</Form.Label>
                            <Form.Control
                              as="select"
                              value={
                                selectedOptions.find(
                                  (option) => option.question_id === question.id
                                )?.response || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(question.id, e.target.value)
                              }
                              required
                            >
                              <option value="">Seleccionar</option>
                              <option value="4">
                                Completamente de Acuerdo
                              </option>
                              <option value="3">Parcialmente de Acuerdo</option>
                              <option value="2">Poco de acuerdo</option>
                              <option value="1">En desacuerdo</option>
                            </Form.Control>
                          </Form.Group>
                        ) : (
                          <Form.Group>
                            <Form.Label>{question.name}</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={
                                selectedOptions.find(
                                  (option) => option.question_id === question.id
                                )?.response || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(question.id, e.target.value)
                              }
                            />
                          </Form.Group>
                        )}
                      </div>
                    ))}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
}

export default GroupedQuestionTabs;
