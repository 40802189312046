import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Container,
  Image,
  Spinner,
  Button,
  Tab,
  Tabs,
  Card,
  Col,
  Row,
  Form,
  Toast,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone-uploader";

import LogoGPM from "../assets/imgs/logo.png";
import ValidateLoader from "../ValidateLoader";
import TableReactBootstrapNextMatrix from "../components/TableReactBootstrapNextMatrix";

import { instance_py } from "../utils/InstancePy";
import "./InstrumentMatrix.css";

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

const columns = [
  { dataField: "id", text: "ID", hidden: true },
  { dataField: "resolution", text: "Resolución" },
  { dataField: "description", text: "Descripción" },
  { dataField: "approval_date", text: "Fecha de Aprobación" },
  { dataField: "instrument_type", text: "Tipo" },
  { dataField: "requesting_unit", text: "Unidad Requirente" },
  {
    dataField: "files_id",
    text: "Archivo(s) Adjunto(s)",
    formatter: (cell, row) => {
      const handleDownload = (file) => {
        const downloadUrl = `/v1/instrument_matrix/file/${file.id}`;
        instance_py
          .get(downloadUrl, { responseType: "blob" })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.name);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error al descargar el archivo:", error);
            alert("Error al descargar el archivo !!!\n\n" + error);
          });
      };

      return (
        <div style={{ margin: "auto" }}>
          {cell instanceof Array &&
            cell.map((file, idx) => (
              <OverlayTrigger
                key={idx}
                placement="right"
                overlay={<Tooltip id="tooltip-right">{file.name}</Tooltip>}
              >
                <button
                  key={idx}
                  onClick={() => handleDownload(file)}
                  className="btn btn-success"
                  style={{
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    padding: "0px 10px",
                  }}
                >
                  <p style={{ fontSize: 10, margin: "auto" }}>
                    Descargar Adjunto {idx + 1}
                  </p>
                </button>
              </OverlayTrigger>
            ))}
        </div>
      );
    },
    style: {
      margin: "auto",
    },
  },
];

export default function InstrumentMatrix(props) {
  const [toastData, setToastData] = useState({
    show: false,
    message: "",
    title: "",
  });
  const [instrumentType, setInstrumentType] = useState([{}]);
  const [requestingUnit, setRequestingUnit] = useState([{}]);
  const [instrumentMatrixData, setInstrumentMatrixData] = useState([{}]);

  const [resolution, setResolution] = useState("");
  const [description, setDescription] = useState("");
  const [selectInstrumentType, setSelectInstrumentType] = useState(0);
  const [selectRequestingUnit, setSelectRequestingUnit] = useState(0);
  const [aproveDate, setAproveDate] = useState(new Date());
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const fileDropzoneRef = useRef();
  const config = {
    headers: {
      Authorization:
        "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY4OTg3NDc0NywianRpIjoiNzc5YzdmNDgtNGVhYy00NzFhLTgxNWQtNjEwMjMzNzNiMTAzIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJpZF91c3VhcmlvIjoiMTMxMDU5NTIyNiIsImRpcmVjY2lvbiI6IkRJUkVDQ0lPTiBERSBNRUpPUkEgQ09OVElOVUEsIElOTk9WQUNJXHUwMGQzTiBZIFRFQ05PTE9HXHUwMGNkQSIsIm5vbWJyZWNvbXBsZXRvIjoiTklDTyBCQUxERU1BUiBBTEFWQSBHSUxFUiIsImNvcnJlbyI6Im5hbGF2YUBtYW5hYmkuZ29iLmVjIiwiY2VkdWxhIjoiMTMxMDU5NTIyNiIsImNhcmdvIjoiQVNJU1RFTlRFIERFIFBST0NFU09TIn0sIm5iZiI6MTY4OTg3NDc0NywiZXhwIjoxNjg5ODc4MzQ3fQ.Jc4_EJHF98GHXskqTxD6WAN-fzgei04PCIX3w0b0EPOEsnFOM0zSPHjS-Oda_bjFvWxl3_KC3PulwDN-wjzMscPWrteN-GH7OdMmZwFISk18F5Sn7lvLkb9FY7CBQz1N6MxyoWbKZfvMe8S1MHekTmqAIZFhy5dhYLOqIq8w-sJfqnAWtuaa1UcZAZa4I9cTY3BhXrGAHq7aEoMahaxDEJ83cbBlEstMerp8cD2LEz0VcYjblp5cDLphkVFeC8Po0tip_6J8nmyVKaaHqHlU0SsaXp2LqOklgijsTydJ9J6AlXvvjuTmKcDdqh7y5pzHFwUuYRYlKXIqAKxAdPsJOg",
    },
  };

  useEffect(() => {
    const getInstrumentType = async () => {
      try {
        const response = await instance_py.get(
          "/v1/instrument_matrix/instrument_type",
          config
        );
        setInstrumentType(response.data.data);
      } catch (error) {
        console.log(error);
        setToastData({
          show: true,
          message: "Error al cargar los Tipos de instrumentos.",
          title: "Error",
        });
      }
    };

    const getRequestingUnit = async () => {
      try {
        const response = await instance_py.get(
          "/v1/instrument_matrix/requesting_unit",
          config
        );
        setRequestingUnit(response.data.data);
      } catch (error) {
        console.log(error);
        setToastData({
          show: true,
          message: "Error al cargar las Unidades Requirentes.",
          title: "Error",
        });
      }
    };
    getInstrumentType();
    getRequestingUnit();
    setIsLoadingTable(true);
    loadInstrumentMatrix();
  }, []);

  const loadInstrumentMatrix = async () => {
    instance_py
      .get("/v1/instrument_matrix", config)
      .then((response) => {
        setInstrumentMatrixData(response.data.data);
        setIsLoadingTable(false);
      })
      .catch((error) => {
        console.log(error);
        setToastData({
          show: true,
          message: "Error al cargar la Matriz de Instrumentos.",
          title: "Error",
        });
        setIsLoadingTable(false);
      });
  };

  const validateForm = () => {
    if (resolution.length === 0) {
      setToastData({
        show: true,
        message: "El campo RESOLUCIÓN es obligatorio",
        title: "Error",
      });
      return false;
    } else if (description.length === 0) {
      setToastData({
        show: true,
        message: "El campo DESCRIPCIÓN es obligatorio",
        title: "Error",
      });
      return false;
    } else if (selectInstrumentType === 0) {
      setToastData({
        show: true,
        message: "El campo TIPO es obligatorio",
        title: "Error",
      });
      return false;
    } else if (selectRequestingUnit === 0) {
      setToastData({
        show: true,
        message: "El campo UNIDAD REQUIRENTE es obligatorio",
        title: "Error",
      });
      return false;
    } else if (files.length === 0) {
      setToastData({
        show: true,
        message: "Debe de subir al menos un archivo.",
        title: "Error",
      });
      return false;
    }
    return true;
  };

  const clearForm = () => {
    setResolution("");
    setDescription("");
    setSelectInstrumentType(0);
    setSelectRequestingUnit(0);
    setAproveDate(new Date());
    setFiles([]);
    fileDropzoneRef.current.files = [];
  };

  const registerInstrument = async () => {
    let data = new FormData();
    data.append("resolution", resolution);
    data.append("description", description);
    data.append("instrument_type_id", selectInstrumentType);
    data.append("requesting_unit_id", selectRequestingUnit);
    data.append(
      "approval_date",
      `${aproveDate.getDate()}/${aproveDate.getMonth()}/${aproveDate.getFullYear()}`
    );
    files.forEach((file) => {
      data.append("file", file);
    });
    instance_py
      .post("/v1/instrument_matrix", data, config)
      .then((response) => {
        if (response.data.status === "success") {
          setIsLoading(false);
          clearForm();
          setToastData({
            show: true,
            message: "El instrumento se registró correctamente.",
            title: "Éxito",
          });
          setIsLoadingTable(true);
          loadInstrumentMatrix();
        } else {
          setIsLoading(false);
          //clearForm();
          setToastData({
            show: true,
            message: response.data.message,
            title: "Fallo",
          });
        }
      })
      .catch((error) => {
        if ("response" in error && error.response.status === 413) {
          setIsLoading(false);
          setToastData({
            show: true,
            message:
              "Los archivos que intenta subir son muy pesados.\nEl tamaño máximo es de 30 MB entre todos los archivos.",
            title: "Error",
          });
          return;
        }
        setIsLoading(false);
        setToastData({
          show: true,
          message: error.response.data.message
            ? error.response.data.message
            : "Ocurrió un error al registrar el instrumento.",
          title: "Error",
        });
      });
    // setTimeout(() => {
    //   setIsLoading(false);
    //   clearForm();
    //   setToastData({
    //     show: true,
    //     message: "El instrumento se registró correctamente.",
    //     title: "Éxito",
    //   });
    // }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    setIsLoading(true);
    await registerInstrument();
    // setToastData({
    //   show: true,
    //   message: "Ocurrió un error al registrar el instrumento.",
    //   title: "Error",
    // });
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setFiles([...files, file]);
    } else if (status === "removed") {
      setFiles(files.filter((f) => f.name !== file.name));
    }
  };

  const SpinnerLoader = (props) => {
    return (
      <Spinner
        animation="border"
        role="status"
        style={{
          position: props.position,
          top: "50%",
          left: "50%",
          marginTop: "-50px",
          marginLeft: "-50px",
          width: "100px",
          height: "100px",
          color: "#137b43",
        }}
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  };

  return (
    <>
      <ValidateLoader {...props} />
      <Container style={{ marginBottom: "55px" }}>
        <Toast
          className="position-fixed"
          show={toastData.show}
          onClose={() => setToastData({ show: false, message: "", title: "" })}
          delay={5000}
          autohide
          style={{
            display: toastData.show ? "block" : "none",
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: 9999,
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">{toastData.title}</strong>
          </Toast.Header>
          <Toast.Body>{toastData.message}</Toast.Body>
        </Toast>

        <Alert variant="success" style={{ backgroundColor: "#157a44" }}>
          <Alert.Heading
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <p
              style={{ color: "white", fontSize: "larger", fontWeight: "700" }}
            >
              MATRIZ DE INSTRUMENTOS GPM
            </p>
          </Alert.Heading>
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <Image
              style={{ textAlign: "center", filter: "brightness(500%)" }}
              src={LogoGPM}
              fluid
            />
          </div>
          <hr />
        </Alert>
        <Container>
          <Card>
            <Card.Body>
              <Tabs
                className="tab-rb"
                defaultActiveKey="register_instrument"
                id="uncontrolled-tab"
                style={{ margin: "0 auto" }}
              >
                <Tab
                  eventKey="register_instrument"
                  title="Registrar Instrumento"
                >
                  <Card style={{ marginBottom: "2px" }}>
                    <Card.Body>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label>Resolución</Form.Label>
                            <Form.Control
                              as="input"
                              required
                              type="text"
                              value={resolution}
                              onChange={(event) => {
                                setResolution(event.target.value.toUpperCase());
                              }}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control
                              as="select"
                              defaultValue="Seleccione..."
                              value={selectInstrumentType}
                              onChange={(event) => {
                                setSelectInstrumentType(event.target.value);
                              }}
                            >
                              <option value={0}>Seleccione...</option>
                              {instrumentType &&
                                instrumentType.map((type, idx) => (
                                  <option key={idx} value={type.id}>
                                    {type.name}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  value={description}
                                  onChange={(event) => {
                                    setDescription(event.target.value);
                                  }}
                                  required
                                  type="text"
                                  rows="5"
                                  cols="50"
                                  style={{ resize: "none" }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label>Unidad Requirente</Form.Label>
                            <Form.Control
                              as="select"
                              defaultValue="Seleccione..."
                              value={selectRequestingUnit}
                              onChange={(event) => {
                                setSelectRequestingUnit(event.target.value);
                              }}
                            >
                              <option value={0}>Seleccione...</option>
                              {requestingUnit &&
                                requestingUnit.map((type, idx) => (
                                  <option key={idx} value={type.id}>
                                    {type.name}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label>Fecha de Aprobación</Form.Label>
                            <DatePicker
                              style={{ zIndex: 100000 }}
                              dateFormat="dd/MM/yyyy"
                              selected={aproveDate}
                              onChange={(date) => setAproveDate(date)}
                              popperPlacement="top-end"
                              calendarStartDay={1}
                              locale="es"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Dropzone
                          ref={fileDropzoneRef}
                          inputContent={() => (
                            <h3
                              style={{
                                textAlign: "center",
                                margin: "auto",
                                color: "black",
                              }}
                            >
                              Cargue sus archivos aquí...
                            </h3>
                          )}
                          inputWithFilesContent={() => (
                            <p style={{ color: "black", margin: "auto" }}>
                              Agregar más archivos
                            </p>
                          )}
                          submitButtonContent={null}
                          onChangeStatus={handleChangeStatus}
                          accept="application/pdf"
                        />
                      </Row>
                    </Card.Body>
                    <Card.Footer
                      style={{
                        justifyContent: "space-around",
                        display: "flex",
                      }}
                    >
                      {!isLoading ? (
                        <Button variant="success" onClick={handleSubmit}>
                          Registrar
                        </Button>
                      ) : (
                        <Spinner animation="border" variant="success" />
                      )}
                    </Card.Footer>
                  </Card>
                </Tab>
                <Tab
                  eventKey="instrument_matrix"
                  title="Matriz de Instrumentos"
                >
                  {instrumentMatrixData.length > 0 && !isLoadingTable ? (
                    <>
                      <Card style={{ marginBottom: "2px" }}>
                        <Card.Body>
                          <TableReactBootstrapNextMatrix
                            columns={columns}
                            data={instrumentMatrixData}
                            keyField="id"
                            fileName="Matriz de Instrumentos"
                            responsive={false}
                            fileDownloadExcel={false}
                            fileDownloadCsv={true}
                            searchBar={true}
                            showPagination={true}
                          />
                        </Card.Body>
                      </Card>
                    </>
                  ) : isLoadingTable ? (
                    <div style={{ height: 200 }}>
                      <SpinnerLoader position="absolute" />
                    </div>
                  ) : null}
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Container>
      </Container>
      <div
        style={{
          backgroundColor: "rgb(34, 123, 67)",
          borderRadius: "3px",
          display: "block",
          position: "fixed",
          bottom: "0",
          marginTop: "52px",
          width: "100%",
          right: 0,
        }}
      >
        <p
          style={{
            color: "white",
            padding: ".6rem 1.4rem",
            margin: 0,
            fontWeight: "300",
            fontSize: "small",
          }}
        >
          ©Copyright Prefectura de Manabí. All rights reserved.
        </p>
      </div>
    </>
  );
}
