import React, { useState, useEffect } from 'react';
import { Container, Card, Form, Toast, Button, Spinner, Col, Row } from 'react-bootstrap';
import TableReactBootstrapNext from "../components/TableReactBootstrapNext";
import { instance_py } from '../utils/InstancePy';
import DatePicker from 'react-datepicker';
import NavBar from '../NavBar';
import ValidateLoader from '../ValidateLoader';
import Axios from 'axios';

const columns = [
  { dataField: 'Can_nombre', text: 'Ente recaudador' },
  {
    dataField: 'TOTAL', text: 'Total', formatter: (cell, row, rowIndex, formatExtraData) => {
      return <>$ {cell !== null ? parseFloat(cell).toLocaleString('en') : 0}</>;
    }
  },
];

export default function ConsultaRecaudacionCemvial(props) {

  const [listRecaudacion, setListRecaudacion] = useState([]);
  const [detalleRecaudacion, setDetalleRecaudacion] = useState([]);
  const [queryYear, setQueryYear] = useState(null);
  const [queryMonth, setQueryMonth] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [total, setTotal] = useState(0);
  const [queryType, setQueryType] = useState(0);
  const [toastData, setToastData] = useState({ show: false, message: '', title: '' });

  const handleQuery = () => {
    if (queryType === 1 && queryYear === null) {
      setToastData({ show: true, message: `Estimado usuario(a). Debe seleccionar un año valido.`, title: 'Información no valida.' });
      return;
    } else if (queryType === 2 && queryMonth === null) {
      setToastData({ show: true, message: `Estimado usuario(a). Debe seleccionar un mes valido.`, title: 'Información no valida.' });
      return;
    } else if (queryType === 3 && (endDate === null || startDate === null)) {
      setToastData({ show: true, message: `Estimado usuario(a). Debe seleccionar un rango de fechas.`, title: 'Información no valida.' });
      return;
    } else if (queryType === 3 && (startDate > endDate)) {
      setToastData({ show: true, message: `Estimado usuario(a). La fecha inicial debe ser menor a la fecha final.`, title: 'Información no valida.' });
      return;
    }
    setLoading(true);
    let url_query = '';
    if (queryType === 1) {
      url_query = `/v1/sistema_roles_pago/recaudacionCemvial?ini_date=01/01/${queryYear.getFullYear()}&fin_date=31/12/${queryYear.getFullYear()}`;
    } else if (queryType === 2) {
      url_query = `/v1/sistema_roles_pago/recaudacionCemvial?ini_date=01/${queryMonth.getMonth() + 1}/${queryMonth.getFullYear()}&fin_date=${new Date(queryMonth.getFullYear(), queryMonth.getMonth() + 1, 0).getDate()}/${queryMonth.getMonth() + 1}/${queryMonth.getFullYear()}`
    } else if (queryType === 3) {
      url_query = `/v1/sistema_roles_pago/recaudacionCemvial?ini_date=${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}&fin_date=${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`;
    }
    instance_py.get(url_query)
    //Axios.get('http://localhost:3015' + url_query)
      .then((resp) => {
        // const recaudacion = resp.data.data;
        setListRecaudacion(resp.data.data.recaudacion);
        setDetalleRecaudacion(resp.data.data.recaudacion_resumen);
        // var sumTotal = 0
        // recaudacion.map((item) => {
        //   console.log(item.TOTAL);
        //   console.log(typeof item.TOTAL);
        //   sumTotal += parseFloat(item.TOTAL);
        // })
        // setTotal(sumTotal);
        setLoading(false);
      }).catch(error => {
        if (error.response.status === 404) {
          setToastData({ show: true, message: `Estimado usuario(a). No se ha encontrado información con la fecha proporcionada.`, title: 'Información no encontrada.' });
        } else {
          console.log(error);
          alert("Error al consultar la recaudación: ", error);
        }
        setLoading(false);
      });
  };

  const SpinnerLoader = (props) => {
    return (
      <Spinner animation="border" role="status" style={{
        position: props.position,
        top: "50%",
        left: "50%",
        marginTop: "-50px",
        marginLeft: "-50px",
        width: "100px",
        height: "100px",
        color: "#137b43"
      }}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  return (
    <>
      <ValidateLoader {...props} />
      <NavBar {...props} />
      <Container style={{ marginTop: 15, marginBottom: listRecaudacion.length === 0 ? 185 : 10 }}>
        <Toast
          show={toastData.show}
          onClose={() => setToastData({ show: false, message: '', title: '' })}
          delay={5000}
          autohide
          style={{ display: toastData.show ? 'block' : 'none', position: 'absolute', top: '1rem', right: '1rem', zIndex: 9999 }}
        >
          <Toast.Header>
            <strong className="mr-auto">{toastData.title}</strong>
          </Toast.Header>
          <Toast.Body>{toastData.message}</Toast.Body>
        </Toast>
        <div style={{ padding: ".5rem 6rem" }}>
          <Card>
            <Card.Header>
              <Card.Title>
                <h2 style={{ textAlign: "center" }}>Recaudación de CEMVIAL</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <p style={{ textAlign: "center" }}>Consulta de la Recaudación registrada en el Sistema de Consolidación de Roles de Pago.</p>

              <Form.Group >
                <Form.Label>Seleccione el tipo de consulta que desea realizar.</Form.Label>
                <Form.Control as="select" value={queryType} onChange={(value) => {
                  setQueryType(parseInt(value.target.value));
                }}>
                  <option value={0}>SELECCIONE</option>
                  <option value={1}>POR AÑO</option>
                  <option value={2}>POR MES</option>
                  <option value={3}>POR RANGO DE FECHAS</option>
                </Form.Control>
              </Form.Group>

              <Form.Row style={{ justifyContent: "space-around", display: "flex" }}>
                {queryType === 1 &&
                  <Form.Group >
                    <Form.Label>Seleccione el año.</Form.Label>
                    <DatePicker style={{ zIndex: 10000 }} selected={queryYear} dateFormat="yyyy" showYearPicker minDate={new Date('02 Jan 2013 00:00:01 GMT')} maxDate={new Date()} onChange={(date) => setQueryYear(date)} />
                  </Form.Group>}

                {queryType === 2 &&
                  <Form.Group >
                    <Form.Label>Seleccione el mes.</Form.Label>
                    <DatePicker style={{ zIndex: 10000 }} selected={queryMonth} dateFormat="MM/yyyy" showMonthYearPicker minDate={new Date('02 Jan 2013 00:00:01 GMT')} maxDate={new Date()} onChange={(date) => setQueryMonth(date)} />
                  </Form.Group>
                }
                {queryType === 3 && <>
                  <Form.Group >
                    <Form.Label>Fecha Desde.</Form.Label>
                    <DatePicker style={{ zIndex: 10000 }} selected={startDate} dateFormat="dd/MM/yyyy" minDate={new Date('02 Jan 2013 00:00:01 GMT')} maxDate={endDate === null ? new Date() : endDate} onChange={(date) => setStartDate(date)} />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Fecha Hasta.</Form.Label>
                    <DatePicker style={{ zIndex: 10000 }} selected={endDate} dateFormat="dd/MM/yyyy" minDate={startDate === null ? "" : startDate} maxDate={new Date()} onChange={(date) => setEndDate(date)} />
                  </Form.Group>
                </>}
              </Form.Row>
            </Card.Body>
            <Card.Footer style={{ justifyContent: 'space-around', display: 'flex' }}>
              <Button variant="success" disabled={queryType === 0} type="submit" onClick={() => handleQuery()}>CONSULTAR</Button>
              <Button variant="success" type="submit" onClick={() => {
                setListRecaudacion([]);
                setQueryType(0);
                setQueryYear(null);
                setQueryMonth(null);
                setStartDate(null);
                setEndDate(null);
                // setTotal(0);
              }}>NUEVA CONSULTA</Button>
            </Card.Footer>
          </Card>
        </div>
        <Card style={{ display: listRecaudacion.length > 0 || loading ? "flex" : "none", justifySelf: "center" }}>
          <Card.Body style={{ display: 'flex', justifyContent: 'center' }}>
            {listRecaudacion.length > 0 && detalleRecaudacion.length > 0 && !loading ?
              <TableReactBootstrapNext
                columns={columns}
                data={listRecaudacion}
                keyField="Can_nombre"
                fileName="consultaRecaudaciónCEMVIAL"
                showPagination={false}
                showSearchBar={false}
              /> : loading ? <div style={{ height: 200 }}><SpinnerLoader position="absolute" /></div> : null
            }
          </Card.Body>
          {!loading && detalleRecaudacion.length > 0 && <Card.Footer>
            <div>

                  <h4>Detalle de Recaudación</h4>
                  <hr />
              <Row>
                <Col>
                  <h6>Número de Vehículos: {detalleRecaudacion[0].vehiculos}</h6>
                  <h6>Número de Motos: {detalleRecaudacion[0].Motos}</h6>
                  <h6>Otros: {detalleRecaudacion[0].otros}</h6>
                </Col>
                <Col>
                  <h6>Total de Pagos realizados: {detalleRecaudacion[0].Ntransacciones}</h6>
                  <h4>Total Recaudado: $ {detalleRecaudacion[0].TOTAL !== null ? parseFloat(detalleRecaudacion[0].TOTAL).toLocaleString('en') : 0}</h4>
                </Col>
              </Row>
              {/* <hr /> */}
            </div>
          </Card.Footer>}
        </Card>
      </Container>
    </>
  );
}

