import React, {useState, useEffect} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import instance from '../utils/Instance';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export default function ListaResultadoEncuesta(props) {

  const [arrResultadoEncuesta, setArrResultadoEncuesta] = useState([]);
  const [loader, setLoader] = useState(true);
  // const [dataFilter, setDataFilter] = useState({data: [], filter: ""});


  useEffect(() => {
    instance.get('cuestionarioencuesta/listar_resultadoencuesta')
      .then(resp => {
        const {response} = resp.data;
        setArrResultadoEncuesta(response);
        setLoader(false);
      })
      .catch(error => {
        alert('Hubo un error:\n\n', error);
        setLoader(false);
      });
  }, []);
  
  // const afterFilter = (newResult, newFilters)=>{
  //   console.log("newResult");
  //   console.log(newResult);

  //   console.log("newFilters");
  //   console.log(newFilters);
  //   setDataFilter({...dataFilter, data: newResult})
  // }

  const columns = [
    {dataField: 'id_resp_cuest', text: 'ID', hidden: true},
    {dataField: 'fecharesolucionencuesta', text: 'Fecha Envío'},
    {dataField: 'provincia', text: 'Provincia'},
    {dataField: 'ciudad', text: 'Ciudad'},
    {dataField: 'areatrabajo', text: 'Area de Trabajo'},
    {dataField: 'nivelinstruccion', text: 'Nivel de instrucción'},
    {dataField: 'tiempoantiguedadempresa', text: 'Tiempo Antigüedad Empresa'},
    {dataField: 'rangoedad', text: 'Rango de Edad'},
    {dataField: 'autoidentificacionetnica', text: 'Auto-identificación étnica'},
    {dataField: 'genero', text: 'Género'},
    {dataField: 'grupo1', text: 'Carga y ritmo de trabajo'},
    {dataField: 'grupo2', text: 'Desarrollo de competencias'},
    {dataField: 'grupo3', text: 'Liderazgo'},
    {dataField: 'grupo4', text: 'Margen de acción y control'},
    {dataField: 'grupo5', text: 'Organización del trabajo'},
    {dataField: 'grupo6', text: 'Recuperación'},
    {dataField: 'grupo7', text: 'Soporte y apoyo'},
    {dataField: 'grupo8', text: 'Otros puntos importantes'},
    {dataField: 'total', text: 'Puntaje Total'},
  ];

  const SpinnerLoader = (props) => {
    return (
      <Spinner animation="border" role="status" style={{
        position: props.position,
        top: "50%",
        left: "50%",
        marginTop: "-50px",
        marginLeft: "-50px",
        width: "100px",
        height: "100px",
        color: "#137b43"
      }}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const ExportCSVButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div>
        <button style={{marginTop: 5, backgroundColor: 'rgb(21, 122, 68)'}} className="btn btn-success"
                onClick={handleClick}>Exportar a CSV
        </button>
        <button style={{marginTop: 5, backgroundColor: 'rgb(21, 122, 68)', marginLeft: 10}}
                className="btn btn-success"
                //onClick={() => exportToCSV(dataFilter.data.length > 0 ? dataFilter.data : arrResultadoEncuesta, 'lista_empleados')}>Exportar a Excel
                onClick={() => exportToCSV(arrResultadoEncuesta, 'lista_empleados')}>Exportar a Excel
        </button>
      </div>
    );
  };

  const SearchBar = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };

    return (
      <div>
        <input
          className="form-control"
          style={{marginTop: 8}}
          ref={n => input = n}
          type="text"
          onChange={handleClick}
          placeholder='Realice su búsqueda...'
        />
      </div>
    );
  };

  const contentTable = ({paginationProps, paginationTableProps}) => (
    <div>
      <ToolkitProvider
        keyField="id_resp_cuest"
        columns={columns}
        data={arrResultadoEncuesta}
        search
        exportCSV={{
          fileName: 'encuesta-salud-ocupacional-gpm.csv',
          onlyExportFiltered: true,
          exportAll: false,
          noAutoBOM: false,
          blobType: "data:text/csv;charset=utf-8"
        }}
      >
        {
          toolkitprops => (
            <div>
              <ExportCSVButton {...toolkitprops.csvProps} />
              <SearchBar {...toolkitprops.searchProps} />
              <BootstrapTable
                bootstrap4
                classes='table-responsive'
                striped
                hover
                //filter={filterFactory(afterFilter)}
                filter={filterFactory()}
                pagination={ paginationFactory() }
                {...toolkitprops.baseProps}
                {...paginationTableProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );

  return (
    <>
    <Container style={{marginTop: 15}}>
      {!loader ? <PaginationProvider
        pagination={
          paginationFactory({
            page: 1,
            sizePerPage: 10,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            showTotal: true,
            paginationTotalRenderer: (from, to, size) => (
              <span className="react-bootstrap-table-pagination-total">
                Mostrando del { from } al { to }.<p>Total de Resultados <strong>{ size }</strong>.</p>
              </span>
            )
          })
        }
      >{contentTable}
      </PaginationProvider>:<div style={{height: 200}}><SpinnerLoader position="absolute" /></div>}
    </Container>
    </>
  );
}

