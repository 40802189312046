import { instance_py } from "../../utils/InstancePy";

export const getDebts = async (cedula) => {
  try {
    const response = await instance_py.get(
      `/v1/commercial/financier/facturasPendientesPago?identification=${cedula}`
    );
    const { data } = response.data;
    return data;
  } catch (error) {
    return { error: error };
  }
};
