import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Spinner,
  Table,
  Modal,
} from "react-bootstrap";
import ValidateLoader from "../ValidateLoader";
import NavBar from "../NavBar";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const data_test = [
  {
    bpm_id: "0904861358",
    direction: "DIRECCIÓN DE PARTICIPACIÓN CIUDADANA Y PROMOCIÓN DE DERECHOS",
    email: "aescala@manabi.gob.ec",
    id: "13",
    identification: "0904861358",
    lastname: "ESCALA VARAS ",
    name: "ARTURO JOSE",
    position: "",
  },
  {
    bpm_id: "1310595226",
    direction: "DIRECCIÓN DE TECNOLOGÍA",
    email: "nalava@manabi.gob.ec",
    id: "1",
    identification: "1310595226",
    lastname: "ALAVA GILER",
    name: "NICO BALDEMAR",
    position: "ASISTENTE DE PROCESOS",
  },
  {
    bpm_id: "0915524946",
    direction: "DIRECCIÓN ADMINISTRATIVA ",
    email: "dprado@manabi.gob.ec",
    id: "12",
    identification: "0915524946",
    lastname: "PRADO GONZALEZ ",
    name: "DANIEL EDUARDO",
    position: "",
  },
  {
    bpm_id: "1311420721",
    direction: "SUB-DIRECCIÓN DE TALENTO HUMANO ",
    email: "hwmendoza@manabi.gob.ec",
    id: "9",
    identification: "1311420721",
    lastname: "MENDOZA LOOR",
    name: "HOMERO WILMER",
    position: "",
  },
  {
    bpm_id: "1311099434",
    direction: "DIRECCION DE TALENTO HUMANO",
    email: "oveliz@manabi.gob.ec",
    id: "10",
    identification: "1311099434",
    lastname: "VELIZ GUTIERREZ",
    name: "OSCAR NELSON",
    position: "",
  },
];

export default function RolAssign(props) {
  const [roles, setRoles] = useState({});
  const [users, setUsers] = useState([]);
  // Modal
  const [selectUser, setSelectUSer] = useState({});
  const [modalShow, setModalShow] = useState(false);

  // const [user_roles, setUser_roles] = useState({});

  const col_user = [
    { dataField: "id", text: "ID" },
    { dataField: "bpm_id", text: "BPM ID" },
    { dataField: "identification", text: "Cédula" },
    { dataField: "name", text: "Nombres" },
    { dataField: "lastname", text: "Apellidos" },
    { dataField: "email", text: "Correo" },
    { dataField: "direction", text: "Dirección" },
    { dataField: "position", text: "Cargo" },
  ];

  const col_user_rol = [
    { dataField: "rol_id", text: "ID" },
    { dataField: "name", text: "Rol" },
  ];

  useEffect(() => {
    Axios.get("http://localhost:3015/v1/roles/getRoles")
      .then((resp) => {
        setRoles(resp.data.data.roles);
        // console.log(roles);
      })
      .catch((error) => console.log(error));

    Axios.get("http://localhost:3015/v1/user/getUsers")
      .then((resp) => {
        setUsers(resp.data.data.users);
        // console.log(resp.data.data.users);
        // console.log(users);
      })
      .catch((error) => console.log(error));
  }, []);

  const SearchBar = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };

    return (
      <div>
        <input
          className="form-control"
          style={{ marginTop: 8 }}
          ref={(n) => (input = n)}
          type="text"
          onChange={handleClick}
          placeholder="Realice su busqueda .."
        />
      </div>
    );
  };

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      // console.log(row);
      // alert(`clicked on row with index: ${rowIndex}\nNombre: ${row.fullname}\nBPM ID: ${row.bpm_id}`);
      setSelectUSer(row);
      setModalShow(true);
    },
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider keyField="id" columns={col_user} data={users} search>
        {(toolkitprops) => (
          <div>
            <SearchBar {...toolkitprops.searchProps} />
            <BootstrapTable
              bootstrap4
              classes="table-responsive"
              striped
              hover
              rowEvents={rowEvents}
              {...toolkitprops.baseProps}
              {...paginationTableProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );

  const SpinnerLoader = (props) => {
    return (
      <div
        style={{
          padding: "5% 50%",
          display: "flex",
        }}
      >
        <Spinner
          animation="border"
          role="status"
          style={{
            position: props.position,
            // top: "50%",
            // left: "50%",
            // marginTop: "-50px",
            // marginLeft: "-50px",
            width: "100px",
            height: "100px",
            color: "#137b43",
          }}
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  };

  // ************************************************************************************
  const RolAssignModalForm = (props) => {
    const initialStateRoles_test = [
      { rol_id: 1, name: "ADMIN", state: "" },
      { rol_id: 2, name: "DINARDAP", state: "" },
      { rol_id: 3, name: "TEST 1", state: "" },
      { rol_id: 4, name: "TEST 2", state: "" },
      { rol_id: 5, name: "TEST 3", state: "" },
    ];

    const [user_roles, setUser_roles] = useState({});
    const [roles_test, setRoles_test] = useState([]);
    const [selectRol, setSelectRol] = useState({});
    const [selectRolAdd, setSelectRolAdd] = useState({});

    const user = props.user;
    var deleteAction = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          variant="danger"
          onClick={() => {
            // setRoles_test([...roles_test]);
            setRoles_test([
              ...roles_test.map((value, index, array) => {
                // if ( (value.id === row.id && value.rol === row.rol) && value.state === 'new'){
                //     console.log("1er");
                //     // continue
                //     // value.state = 'deleted';
                //     return false;
                //}else
                if (value.id === row.id && value.rol === row.rol) {
                  value.state = "deleted";
                  return value;
                } else {
                  return value;
                }
              }),
            ]);
          }}
        >
          Eliminar
        </Button>
      );
    };

    useEffect(() => {
      Axios.get(`http://localhost:3015/v1/user_rol/getRolesByUser/${user.id}`)
        .then((response) => {
          const { data, status } = response.data;

          if (data.roles.length > 0) {
            setRoles_test([
              ...data.roles.map((value) => {
                value.state = "";
                return value;
              }),
            ]);
          }
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: RolAssign.js ~ line 241 ~ Axios.get ~ error",
            error
          );
          alert(`Error al Cargar los Roles del usuario ${user.bpm_id}`);
        });
      deleteAction = (cell, row, rowIndex, formatExtraData) => {
        return (
          <Button
            variant="danger"
            onClick={() => {
              //debugger
              // setRoles_test([...roles_test]);
              setRoles_test([
                ...roles_test.map((value, index, array) => {
                  console.log("array - iter %d", index);
                  console.log(array);
                  // if ( (value.id === row.id && value.rol === row.rol) && value.state === 'new'){
                  //     console.log("1er");
                  //     // continue
                  //     // value.state = 'deleted';
                  //     return false;
                  //}else
                  if (value.id === row.id && value.rol === row.rol) {
                    console.log("2er");
                    value.state = "deleted";
                    return value;
                  } else {
                    console.log("3er");
                    return value;
                  }
                }),
              ]);
              console.log(
                "********************************************************"
              );
            }}
          >
            Eliminar
          </Button>
        );
      };
    }, []);

    // function deleteAction (cell, row, rowIndex, formatExtraData) {
    //   return (
    //     <Button variant="danger"
    //       onClick={() => {
    //         debugger
    //         console.log("Delete ********************************************************");
    //         console.log("Row");
    //         console.log(row);
    //         // setRoles_test([...roles_test]);
    //         console.log("roles_test");
    //         console.log(roles_test);
    //         setRoles_test([
    //           ...roles_test.map((value, index, array)=>{
    //             console.log("array - iter %d", index);
    //             console.log(array);
    //             // if ( (value.id === row.id && value.rol === row.rol) && value.state === 'new'){
    //             //     console.log("1er");
    //             //     // continue
    //             //     // value.state = 'deleted';
    //             //     return false;
    //             //}else
    //             if(value.id === row.id && value.rol === row.rol){
    //               console.log("2er");
    //               value.state = 'deleted';
    //               return value;
    //             }else{
    //               console.log("3er");
    //               return value;
    //             }
    //           })
    //         ]);
    //         console.log("********************************************************");
    //       }}
    //     >
    //       Eliminar
    //     </Button>
    //   );
    // };

    const column_roles = [
      { dataField: "rol_id", text: "ID" },
      { dataField: "name", text: "ROL" },
      { dataField: "accion", text: "ACCIÓN", formatter: deleteAction },
    ];

    const rowStyle = (row, rowIndex) => {
      if (row.state === "new") {
        return { backgroundColor: "#47ff478c" };
      } else if (row.state === "deleted") {
        return { backgroundColor: "red" };
      } else {
        return {};
      }
    };

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable="true"
        centered
      >
        <Modal.Header closeButton onHide={() => {}}>
          <Modal.Title id="contained-modal-title-vcenter">
            Actualización de Roles
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Card style={{ marginTop: 15 }}>
              <Card.Header>DATOS DEL FUNCIONARIO</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>
                        NOMBRE COMPLETO:
                      </span>{" "}
                      {user.fullname}
                    </Card.Text>
                  </Col>
                  <Col sm={6}>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>CÉDULA:</span>{" "}
                      {user.identification}
                    </Card.Text>
                  </Col>
                  <Col sm={6}>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>DIRECCIÓN:</span>{" "}
                      {user.direction}
                    </Card.Text>
                  </Col>
                  <Col sm={6}>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>CORREO:</span>{" "}
                      {user.email}
                    </Card.Text>
                  </Col>
                  <Col sm={6}>
                    <Card.Text>
                      <span style={{ fontWeight: "bold" }}>CARGO:</span>{" "}
                      {user.position}
                    </Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: 15 }}>
              <Card.Header>ROLES DEL USUARIO</Card.Header>
              <Card.Body>
                {roles_test.length > 0 ? (
                  <>
                    <Row style={{ paddingBottom: "0.5rem" }}>
                      <Col sm={10}>
                        <Card.Text>
                          <span style={{ fontWeight: "bold" }}>
                            AÑADIR ROL:
                          </span>
                          <Form.Control
                            as="select"
                            value={selectRol}
                            onChange={(event) => {
                              if (event.target.value !== "") {
                                let aux = {
                                  id: parseInt(
                                    event.target.value.split("|")[0]
                                  ),
                                  rol: event.target.value.split("|")[1],
                                  state: "new",
                                }; //new | deleted
                                setSelectRolAdd(aux);
                              }
                              setSelectRol(event.target.value);
                            }}
                          >
                            <option></option>
                            <option value="1|ADMIN">ADMIN</option>
                            <option value="2|DINARDAP">DINARDAP</option>
                            <option value="3|PRUEBA 1">PRUEBA 1</option>
                            <option value="4|PRUEBA 2">PRUEBA 2</option>
                            <option value="5|PRUEBA 3">PRUEBA 3</option>
                            <option value="6|PRUEBA 4">PRUEBA 4</option>
                          </Form.Control>
                        </Card.Text>
                      </Col>
                      <Col style={{ paddingTop: "1.5rem" }} sm={2}>
                        <span style={{ fontWeight: "bold" }}></span>
                        <Button
                          onClick={() => {
                            if (Object.keys(selectRolAdd).length > 0) {
                              setRoles_test([...roles_test, selectRolAdd]);
                              setSelectRolAdd({});
                            }
                          }}
                        >
                          Agregar
                        </Button>
                      </Col>
                    </Row>
                    <BootstrapTable
                      bootstrap4
                      striped
                      hover
                      keyField="id"
                      rowStyle={rowStyle}
                      data={roles_test}
                      columns={column_roles}
                    />
                  </>
                ) : (
                  <SpinnerLoader />
                )}
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onHide();
              console.log("Guardar");
            }}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {/* <ValidateLoader {...props} /> */}
      <NavBar {...props} />
      <Container>
        <Card style={{ marginTop: 15 }}>
          <Card.Header>SELECCIONAR USUARIO</Card.Header>
          <Card.Body>
            {users !== {} && roles !== {} && (
              <PaginationProvider pagination={paginationFactory()}>
                {contentTable}
              </PaginationProvider>
            )}
          </Card.Body>
        </Card>
        {modalShow && (
          <RolAssignModalForm
            show={modalShow}
            onHide={() => {
              if (
                window.confirm(
                  `¿Desea cerrar la ventana?\nLos cambios no serán guardados!!!`
                )
              ) {
                setModalShow(false);
                setSelectUSer({});
              }
            }}
            user={selectUser}
          />
        )}
      </Container>
    </>
  );
}
