import React, { useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
// import axios from "axios";
import LogoGPM from "./assets/imgs/logo.png";
import { logout } from "./utils/InstancePy";
// import logout from './utils/InstancePy';
import { decodeJWT_RS256 } from "./utils/Utilities";
import { Link } from "react-router-dom";

export default function NavBar(props) {
  const [informationUser, setInformationUser] = useState({});
  const { history } = props;

  useEffect(() => {
    const configuration = localStorage.getItem("auth-jwt");
    if (configuration) {
      const objectJwt = decodeJWT_RS256(configuration);
      if (objectJwt.exp < Date.now() / 1000) {
        localStorage.clear("auth-jwt");
        localStorage.clear("refresh-jwt");
        history.push("/login");
      } else {
        setInformationUser(
          objectJwt.sub !== undefined ? objectJwt.sub : objectJwt.data
        );
      }
    }
  }, []);

  async function logoutSession() {
    const AccessToken = localStorage.getItem("auth-jwt");
    const RefreshToken = localStorage.getItem("refresh-jwt");
    const response = await logout(AccessToken, RefreshToken);

    localStorage.removeItem("auth-jwt");
    localStorage.removeItem("refresh-jwt");
    history.push("/login");

    // undefined -> Tokens en storage pero revocados en servidor
    // {AccessToken: undefined, RefreshToken: undefined} -> Tokens en storage y revocación exitosa en el servidor
  }

  return (
    <Navbar
      variant="dark"
      expand="lg"
      style={{ backgroundColor: "rgb(21, 122, 68)" }}
    >
      <Link to={"/modulos"}>
        <Navbar.Brand>
          <img
            alt=""
            src={LogoGPM}
            width="180"
            height="60"
            className="d-inline-block align-top"
            style={{ filter: "brightness(500%)" }}
          />{" "}
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          Nombre de Usuario: <a>{informationUser.nombrecompleto}</a>
        </Navbar.Text>
      </Navbar.Collapse>
      <Nav className="mr-auto">
        <NavDropdown title="Cuenta">
          <NavDropdown.Item
            onClick={() => {
              history.push("/modulos");
            }}
          >
            Módulos
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => {
              // localStorage.removeItem('auth-jwt');
              logoutSession();
              // history.push('/login');
            }}
          >
            Cerrar Sesión
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
}
