import React, { useState, useEffect, useCallback } from 'react';
import { Container, Modal, Button, Card, Col, Row, ModalTitle, Spinner, FormControl, Form, Tab, Tabs } from 'react-bootstrap';
// import instance from '../utils/Instance';
import { instance_py } from '../utils/InstancePy';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import TableReactBootstrapNext from '../components/TableReactBootstrapNext';
import NavBar from '../NavBar';
import ValidateLoader from '../ValidateLoader';
import './ListaProcesosContratacion.css'
import ListaProcesosContratacion from './ListaProcesosContratacion';
import Axios from 'axios';
import columnContext from 'react-bootstrap-table-next/lib/src/contexts/column-context'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

function formatAmount(cell, row, rowIndex, formatExtraData) {
  return cell !== null ? parseFloat(cell).toLocaleString('en') : 0;
};

function formatLongString(cell, row, rowIndex, formatExtraData) {
  return (
    <>
      {cell.length > 50 ?
        <div className="input-group">
          <input size={20} type="text" value={cell} className="form-control" data-toggle="tooltip" data-placement="bottom" title={cell} readonly
            style={{
              background: "transparent",
              border: "1px solid transparent",
              borderBottom: "1px solid #212529",
              color: "#212529",
            }} />
        </div>
        : <></>
      }
    </>
  );
}

const SpinnerLoader = (props) => {
  return (
    <Spinner animation="border" role="status" style={{
      position: props.position,
      top: "50%",
      left: "50%",
      marginTop: "-50px",
      marginLeft: "-50px",
      width: "100px",
      height: "100px",
      color: "#137b43"
    }}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  )
}

SpinnerLoader.defaultProps = {
  position: "absolute",
  // horizontal: false,
  //size: "xl"
};

export default function ModalPagosProveedores(props) {

  const [anticiposProcesos, setAnticiposProcesos] = useState([]);
  const [pagosPendientes, setPagosPendientes] = useState([]);
  const [loaderPagosPendientes, setLoaderPagosPendientes] = useState(true);
  const [loaderAnticiposProcesos, setLoaderAnticiposProcesos] = useState(true);
  const [montoTotalPendiente, setMontoTotalPendiente] = useState(0);
  const [montoTotalPagado, setMontoTotalPagado] = useState(0);
  const [activeTab, setActiveTab] = useState('pendientes');
  

  const columns_pagos = [
    { dataField: 'numero_comprobante', text: 'Número de Comprobante', sort: true, filter: textFilter() },
    { dataField: 'numero', text: 'Número', sort: true, filter: textFilter() },
    { dataField: 'descripcion', text: 'Descripción', formatter: formatLongString, filter: textFilter() },
    { dataField: 'fecha_solicitud', text: 'Fecha de Solicitud', sort: true,  },
    { dataField: 'fecha_pago', text: 'Fecha de Pago', sort: true, filter: textFilter() },
    { dataField: 'descripcion_asiento', text: 'Descripción de Asiento Contable', formatter: formatLongString, sort: true, filter: textFilter() },
    { dataField: 'monto', text: 'Monto', formatter: formatAmount, sort: true, filter: textFilter() },
    { dataField: 'estado', text: 'Estado', sort: true, filter: textFilter() },
    { dataField: 'numero_asiento', text: 'Número de Asiento', sort: true, filter: textFilter() },
    { dataField: 'numero_factura', text: 'Número de Factura', sort: true, filter: textFilter() },
    { dataField: 'fecha_factura', text: 'Fecha de Factura', sort: true, filter: textFilter() },
  ];

  const columns_pendientes = [
    { dataField: 'numero_factura', text: 'Número de Factura', filter: textFilter() },
    { dataField: 'description', text: 'Descripción', filter: textFilter(), formatter: formatLongString },
    { dataField: 'total_factura', text: 'Total de la Factura', filter: textFilter(), formatter: formatAmount },
    { dataField: 'retencion', text: 'Retención', filter: textFilter(), formatter: formatAmount },
    { dataField: 'monto_a_pagar', text: 'Monto a Pagar', filter: textFilter(), formatter: formatAmount },
    { dataField: 'monto_pagado_total', text: 'Monto Pagado', filter: textFilter(), formatter: formatAmount },
  ];

  useEffect(() => {    
      if (Object.keys(props.proceso).length > 0) {
        instance_py.get(`/v1/egob/financier/listAnticiposLineaPagos?id_partida=${props.proceso.id_party}`)
        //Axios.get(`http://localhost:3015/v1/egob/financier/listAnticiposLineaPagos?id_partida=${props.proceso.id_party}`, 
        // {headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth-jwt'))}})
          .then(resp => {
            const anticipos = resp.data.data.map((value, index, array) => {
              return { ...value, matchProceso: true }
            });

            // Anticipos del Proceso seleccionado
            // if (resp.data.data.length > 0) {
            //   setFindPay(true);
            // }

            instance_py.get(`/v1/egob/financier/listFacturasLineaPagos?id_partida=${props.proceso.id_party}`)
              .then(resp => {
                const total = [...anticipos, ...resp.data.data];
                setAnticiposProcesos([...total]);
                
                total.forEach((value, index, array)=>{
                  setMontoTotalPagado(prevMontoTotal => ( prevMontoTotal + parseFloat(value.monto)));
                })

                setLoaderAnticiposProcesos(false);
              })
              .catch(error => {
                alert(error);
              });

          })
          .catch(error => {
            alert(error);
          });
          
          instance_py.get(`/v1/egob/financier/facturasPendientesPago?id_proveedor=${props.proceso.id_party}`).then(
            (resp)=>{
              const data = resp.data.data;

              data.forEach((value, index, array)=>{
                setMontoTotalPendiente(prevMontoTotal => ( prevMontoTotal + parseFloat(value.monto_a_pagar)));
              })
              setPagosPendientes([...data]);
              setLoaderPagosPendientes(false);
            }).catch((error)=>{
              alert(error);
            });
      }      
    }, []);

  // const rowStyle = (row, rowIndex) => {
  //   if (row.matchProceso === true) {
  //     return { backgroundColor: 'rgb(152 255 152 / 55%)' };
  //   } else {
  //     return {}
  //   }
  // };

  // const exportToCSV = (csvData, fileName) => {
  //   const ws = XLSX.utils.json_to_sheet(csvData);
  //   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // }

  // const ExportCSVButton = (props) => {
  //   const handleClick = () => {
  //     props.onExport();
  //   };

  //   return (
  //     <div style={{ margin: "10px 0px"}}>
  //       <button style={{backgroundColor: 'rgb(21, 122, 68)' }} className="btn btn-success"
  //         onClick={handleClick}>Exportar a CSV
  //       </button>
  //       <button style={{backgroundColor: 'rgb(21, 122, 68)', marginLeft: 10 }}
  //         className="btn btn-success"
  //         onClick={() => exportToCSV(anticiposProcesos, `Anticipos_Facturas-${Object.keys(props.proceso).length > 0 ? props.proceso.numero_proceso : ""}`)}>Exportar a Excel
  //       </button>
  //     </div>
  //   );
  // };

  // const contentTableAnticipos = ({ paginationProps, paginationTableProps }) => {

  //   return (<div>
  //     <ToolkitProvider
  //       keyField="id"
  //       columns={columns_pagos}
  //       data={anticiposProcesos}
  //       exportCSV={{
  //         fileName: `Anticipos-${Object.keys(props.proceso).length > 0 ? props.proceso.numero_proceso : ""}.csv`,
  //         onlyExportFiltered: true,
  //         exportAll: false,
  //         noAutoBOM: false,
  //         blobType: "data:text/csv;charset=utf-8"
  //       }}
  //     >
  //       {
  //         toolkitprops => (
  //           <div>
  //             <ExportCSVButton {...toolkitprops.csvProps} />
  //             <BootstrapTable
  //             columns={columns_pagos} 
  //               bootstrap4
  //               classes='table-responsive'
  //               striped
  //               hover
  //               filter={ filterFactory() }
  //               rowStyle={ rowStyle }
  //               {...toolkitprops.baseProps}
  //               {...paginationTableProps}
  //             />
  //           </div>
  //         )
  //       }
  //     </ToolkitProvider>
  //   </div>)

  // };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable='true'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Pagos al Proveedor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <Col className={'row'} xs={12}>

              <Col xs={6}>
                <Card.Title style={{ fontSize: '1rem' }}>
                  <div style={{ fontWeight: 'bold' }}>Proveedor</div>
                  <hr style={{ marginTop: '0.5rem' }} />
                  <a>{props.proceso.proveedor}</a>
                </Card.Title>
              </Col>

              <Col xs={6}>
                <Card.Title style={{ fontSize: '1rem' }}>
                  <div style={{ fontWeight: 'bold' }}>Identificación</div>
                  <hr style={{ marginTop: '0.5rem' }} />
                  <a>{props.proceso.ced_ruc}</a>
                </Card.Title>
              </Col>
            </Col>
          </Card.Body>
        </Card>
        <Tabs className='tab-rb' activeKey={activeTab} style={{
          paddingTop: '1rem',
        }} onSelect={(eventKey)=>{
          setActiveTab(eventKey);
        }}>
          <Tab eventKey="pendientes" title="PAGOS PENDIENTES">
            { 
              loaderPagosPendientes ?
              <div style={{ height: "200px" }}>
                <SpinnerLoader position="relative" />
              </div>
              : pagosPendientes.length > 0 ? <div>
                <TableReactBootstrapNext 
                  keyField="id_factura"
                  columns={columns_pendientes}
                  data={pagosPendientes}
                  fileName={`PagosPendientes-${props.proceso.proveedor}`}
                  responsive
                />
              </div> :
                <>
                <ModalTitle style={{ textAlign: "center", padding: "2rem 0rem" }}>
                  <h3>
                    El Proceso no cuenta con pagos pendientes...
                  </h3>
                </ModalTitle>
              </>             
            
              }
          </Tab>
          <Tab eventKey="realizados" title="PAGOS REALIZADOS">
            {
              loaderAnticiposProcesos ?
              <div style={{ height: "200px" }}>
                <SpinnerLoader position="relative" />
              </div>
              :              
              anticiposProcesos.length > 0 ? <div>
                {/* {findPay && <div style={{ paddingTop: "10px" }}>
                  <span className='form-text text-muted'>El registro resaltado corresponde al pago del proceso seleccionado.</span>
                </div>} */}
                {/* <PaginationProvider
                  pagination={
                    paginationFactory()
                  }
                >{contentTableAnticipos}
                </PaginationProvider> */}
                <TableReactBootstrapNext 
                    keyField="id"
                    columns={columns_pagos}
                    data={anticiposProcesos}
                    fileName={`PagosRealizados-${props.proceso.proveedor}`}
                    responsive
                  />
              </div> :
                <>
                  <ModalTitle style={{ textAlign: "center", padding: "2rem 0rem" }}>
                    <h3>
                      El Proceso no cuenta con pagos realizados...
                    </h3>
                  </ModalTitle>
                </>
            }
          </Tab>
        </Tabs>        
      </Modal.Body>
      <Modal.Footer style={{justifyContent: "space-between"}}>
        {activeTab === "pendientes" ?
          <div>
          <h5><strong>TOTAL PENDIENTE</strong>: ${parseFloat(montoTotalPendiente).toLocaleString('en')}</h5> 
          </div>:
          <div>
            <h5><strong>TOTAL PAGADO</strong>: ${parseFloat(montoTotalPagado).toLocaleString('en')}</h5> 
          </div>
        }
        
        <Button onClick={() => {
          props.onHide();
          props.setProceso("");
        }}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}
