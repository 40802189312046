import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Container,
  Image,
  Spinner,
  Button,
  Table,
  Card,
  Col,
  Form,
  Toast,
} from "react-bootstrap";

import LogoGPM from "../assets/imgs/logo.png";
// import { currentDate } from './utils/Validator';
// import instance from './utils/Instance';
import Axios from "axios";
import "./ConsultaTramitesBPM.css";

export default function ConsultaTramitesBPM() {
  // const principalComponent = useRef(null);

  const [tramite, setTramite] = useState({});
  const [tipoConsulta, setTipoConsulta] = useState("");
  const [value, setValue] = useState("");
  const [validValue, setValidValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastData, setToastData] = useState({
    show: false,
    message: "",
    title: "",
  });

  function handleSubmit() {
    setLoading(true);
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    Axios.get(
      `http://localhost:3012/v1/bpm/buscarTramite?tipo_consulta=${tipoConsulta}&tramite=${value}`
    )
      .then((response) => {
        const { data } = response;
        console.log(
          "🚀 ~ file: ConsultaTramitesBPM.js ~ line 24 ~ .then ~ data.data.tramite",
          data.data.tramite
        );
        setTramite(data.data.tramite);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.status);
        if (error.response.status === 404) {
          // alert("Trámite no encontrado");
          setToastData({
            show: true,
            message: `El Trámite con Número/Código <${value}> no ha sido encontrado.`,
            title: "Trámite no encontrado",
          });
        } else {
          alert(
            "Hubo un error. Reportelo con el Administrador.\n" +
              JSON.stringify(error)
          );
        }
      });
    setLoading(false);
  }

  // useEffect(() => {
  //   console.log(principalComponent.current.offsetWidth)
  // }, []);

  // useEffect(() => {
  //   console.log('width', principalComponent.current ? principalComponent.current.offsetWidth : 0);
  // }, [principalComponent.current]);

  const SpinnerLoader = (props) => {
    return (
      <Spinner
        animation="border"
        role="status"
        style={{
          position: props.position,
          top: "50%",
          left: "50%",
          marginTop: "-50px",
          marginLeft: "-50px",
          width: "100px",
          height: "100px",
          color: "#137b43",
        }}
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  };

  function handleKeyPress(e) {
    if (e.key === "Enter" && validValue) {
      handleSubmit();
    }
  }

  return (
    <>
      <Container style={{ marginBottom: "55px" }}>
        <Toast
          show={toastData.show}
          onClose={() => setToastData({ show: false, message: "", title: "" })}
          delay={5000}
          autohide
          style={{
            display: toastData.show ? "block" : "none",
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: 9999,
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">{toastData.title}</strong>
          </Toast.Header>
          <Toast.Body>{toastData.message}</Toast.Body>
        </Toast>

        <Alert variant="success" style={{ backgroundColor: "#157a44" }}>
          <Alert.Heading
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <p
              style={{ color: "white", fontSize: "larger", fontWeight: "700" }}
            >
              SOLICITUDES CIUDADANAS
            </p>
          </Alert.Heading>
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <Image
              style={{ textAlign: "center", filter: "brightness(500%)" }}
              src={LogoGPM}
              fluid
            />
          </div>
          <hr />
        </Alert>
        <Container>
          <Card
            className="text-center"
            style={{ margin: "10px auto 2px auto", width: "90%" }}
          >
            <Card.Header style={{ textAlign: "center" }}>
              <h4>Consulta tú trámite</h4>
            </Card.Header>
            <Card.Body style={{ textAlign: "center" }}>
              Ingresa tu número o código de trámite en el siguiente recuadro y
              dale clic en buscar.
              <hr />
              <Form.Group
                as={Col}
                className="justify-content-md-center"
                md="6"
                style={{ margin: "10px auto 2px auto" }}
              >
                <Form.Control
                  as="input"
                  required
                  isInvalid={!validValue && value.length > 0}
                  type="text"
                  isValid={validValue}
                  placeholder={"Número/Código de Trámite"}
                  value={value}
                  onKeyPress={handleKeyPress}
                  onChange={(event) => {
                    let val = event.target.value.toUpperCase();
                    setValue(val);
                    if (val.length >= 3 && val.includes("GPM")) {
                      setValidValue(true);
                      setTipoConsulta("codigo_tramite");
                    } else if (/^\d+$/.test(val)) {
                      setValidValue(true);
                      setTipoConsulta("numero_tramite");
                    } else {
                      setTipoConsulta("");
                      setValidValue(false);
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Ingrese un número/código de Tramite valido.
                </Form.Control.Feedback>
                {tipoConsulta !== "" && value.length > 0 && (
                  <Form.Label
                    style={{ fontWeight: "initial", fontSize: "small" }}
                  >
                    Se buscará por:{" "}
                    <strong>
                      {tipoConsulta === "numero_tramite"
                        ? "Número de Tramite"
                        : tipoConsulta === "codigo_tramite"
                        ? "Código de Tramite"
                        : ""}
                    </strong>
                  </Form.Label>
                )}
              </Form.Group>
            </Card.Body>
            <Card.Footer
              style={{ justifyContent: "space-around", display: "flex" }}
            >
              <Button
                variant="success"
                disabled={!validValue}
                onClick={() => {
                  validValue
                    ? handleSubmit()
                    : alert("Ingrese un valor valido !!!");
                }}
              >
                Buscar
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  setTramite({});
                  setValue("");
                }}
              >
                Nueva Consulta
              </Button>
            </Card.Footer>
          </Card>
        </Container>
        <Container style={{ marginTop: "10px", paddingTop: "10px" }}>
          {loading && (
            <div style={{ height: "20rem" }}>
              <SpinnerLoader position="relative" />
            </div>
          )}
          {Object.keys(tramite).length > 0 && (
            <Table style={{ padding: "0 !important" }}>
              <tbody>
                {"codigo_aflow" in tramite && (
                  <tr>
                    <td className="label-table-header">Código del Trámite</td>
                    <td colSpan="2">
                      {"codigo_aflow" in tramite
                        ? tramite.codigo_aflow
                        : "NULL"}
                    </td>
                  </tr>
                )}
                {"solicitante" in tramite && (
                  <tr>
                    <td className="label-table-header">Solicitante</td>
                    <td colSpan="2">
                      {"solicitante" in tramite ? tramite.solicitante : "NULL"}
                    </td>
                  </tr>
                )}
                {/* {'tipo' in tramite && <tr>
                <td className="label-table-header">Tipo</td>
                <td colSpan="2">{'tipo' in tramite ? tramite.tipo : 'NULL'}</td>
              </tr>} */}
                {"fecha_documento" in tramite && (
                  <tr>
                    <td className="label-table-header">Fecha Documento</td>
                    <td colSpan="2">
                      {"fecha_documento" in tramite
                        ? tramite.fecha_documento
                        : "NULL"}
                    </td>
                  </tr>
                )}
                {"fecha_ingreso_sistema" in tramite && (
                  <tr>
                    <td className="label-table-header">
                      Fecha Ingreso Sistema
                    </td>
                    <td colSpan="2">
                      {"fecha_ingreso_sistema" in tramite
                        ? tramite.fecha_ingreso_sistema
                        : "NULL"}
                    </td>
                  </tr>
                )}
                {"canton" in tramite && (
                  <tr>
                    <td className="label-table-header">Canton</td>
                    <td colSpan="2">
                      {"canton" in tramite ? tramite.canton : "NULL"}
                    </td>
                  </tr>
                )}
                {"parroquia" in tramite && (
                  <tr>
                    <td className="label-table-header">Parroquia</td>
                    <td colSpan="2">
                      {"parroquia" in tramite ? tramite.parroquia : "NULL"}
                    </td>
                  </tr>
                )}
                {"departamento" in tramite && (
                  <tr>
                    <td className="label-table-header">Departamento</td>
                    <td colSpan="2">
                      {"departamento" in tramite
                        ? tramite.departamento
                        : "NULL"}
                    </td>
                  </tr>
                )}
                {"estado" in tramite && (
                  <tr>
                    <td className="label-table-header">Estado</td>
                    <td colSpan="2">
                      {"estado" in tramite ? tramite.estado : "NULL"}
                    </td>
                  </tr>
                )}
                {"ingresado_por" in tramite && (
                  <tr>
                    <td className="label-table-header">Ingresado por</td>
                    <td colSpan="2">
                      {"ingresado_por" in tramite
                        ? tramite.ingresado_por
                        : "NULL"}
                    </td>
                  </tr>
                )}
                {"resumen" in tramite && (
                  <tr>
                    <td className="label-table-header">Resumen</td>
                    <td colSpan="2">
                      {"resumen" in tramite ? tramite.resumen : "NULL"}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Container>
      </Container>
      <div
        style={{
          backgroundColor: "rgb(34, 123, 67)",
          borderRadius: "3px",
          display: "block",
          position: "fixed",
          bottom: "0",
          marginTop: "52px",
          width: "100%",
          right: 0,
        }}
      >
        <p
          style={{
            color: "white",
            padding: ".6rem 1.4rem",
            margin: 0,
            fontWeight: "300",
            fontSize: "small",
          }}
        >
          ©Copyright Prefectura de Manabí. All rights reserved.
        </p>
      </div>
    </>
  );
}
