import React, { useEffect } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import ListaResultadoEncuesta from "./EncuestaOcupacionalResultados/ListaResultadoEncuesta";
import ListaConteoTotalizadoPorGrupo from "./EncuestaOcupacionalResultados/ListaConteoTotalizadoPorGrupo";
import ListaResultadoTercilEncuesta from "./EncuestaOcupacionalResultados/ListaResultadoTercilEncuesta";
import ListaFuncionariosEncuestados from "./EncuestaOcupacionalResultados/ListaFuncionariosEncuestados";
import NavBar from "./NavBar";
import ValidateLoader from "./ValidateLoader";
import { decodeJWT_RS256 } from "./utils/Utilities";
import "./assets/styles/resultadoEncuesta.css";

export default function ResultadoEncuesta(props) {
  useEffect(() => {
    const configuration = localStorage.getItem("auth-jwt");
    if (configuration) {
      const objectJwt = decodeJWT_RS256(configuration);
      if (objectJwt.exp < Date.now() / 1000) {
        localStorage.clear("auth-jwt");
        props.history.push("/login");
      }
    }
  }, []);

  return (
    <>
      <ValidateLoader {...props} />
      <NavBar {...props} />
      <Container style={{ marginTop: 15 }}>
        <Tabs className="tab-rb" defaultActiveKey="general" fill>
          <Tab eventKey="general" title="Resultados Generales">
            <ListaResultadoEncuesta {...props} />
          </Tab>
          <Tab eventKey="tercil" title="Resultados por Grupos">
            <ListaResultadoTercilEncuesta {...props} />
          </Tab>
          <Tab eventKey="grupo" title="Resultados por Terciles">
            <ListaConteoTotalizadoPorGrupo {...props} />
          </Tab>
          <Tab eventKey="servidores" title="Servidores Encuestados">
            <ListaFuncionariosEncuestados {...props} />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}
