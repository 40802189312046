import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
// import Button from 'react-bootstrap/Button';

// import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

import LogoNotFound from './assets/imgs/not-found.png';

export default class NotFound extends Component {

    render() {

        return (
            <Container style={{ marginTop: "10rem", marginBottom: "10rem" }}>

                <Jumbotron style={{ textAlign: 'center' }}>
                    <h1>Página no encontrada!</h1>
                    <p>
                        Lo sentimos, ha ocurrido un error, ¡No se ha encontrado la página solicitada !
                            </p>
                    <Image src={LogoNotFound} width="300" />
                    {/* <p>
                        <Link to="/">
                            <Button variant="success">Inicio</Button>
                        </Link>
                    </p> */}
                </Jumbotron>

            </Container>
        );
    }

}