/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import LoadingAnimated from "./assets/imgs/timer.gif";
import EmptyAnimated from "./assets/imgs/empty.gif";
import { instance_py } from "./utils/InstancePy";
import axios from "axios";
import { isIdentificationValid, isPlateValid } from "./utils/Validator";
import { decodeJWT_RS256 } from "./utils/Utilities";
import NavBar from "./NavBar";
import ValidateLoader from "./ValidateLoader";

const optionsInstitucion = [
  {
    value: "IESS",
    descripcion: "IESS - INSTITUTO ECUATORIANO DE SEGURIDAD SOCIAL",
  },
  { value: "MDT", descripcion: "MDT - MINISTERIO DE TRABAJO" },
  { value: "RC", descripcion: "REGISTRO CIVIL" },
  { value: "MIES", descripcion: "MINISTERIO DE INCLUSIÓN ECONÓMICA Y SOCIAL " },
  { value: "MSP", descripcion: "MSP - MINISTERIO DE SALUD PÚBLICA" },
  { value: "ANT", descripcion: "ANT - AGENCIA NACIONAL DE TRÁNSITO" },
  { value: "SRI", descripcion: "SRI - SERVICIO DE RENTAS INTERNAS" },
];

const optionsTipoConsulta = [
  { institucion: "IESS", value: "HIST_LAB", descripcion: "HISTORIAL LABORAL" },
  {
    institucion: "IESS",
    value: "STATUS_AFILIADO",
    descripcion: "ESTADO AFILIADO",
  },
  { institucion: "MDT", value: "IMPEDI", descripcion: "IMPEDIMENTO LABORAL" },
  {
    institucion: "MIES",
    value: "BONO",
    descripcion: "BONO DE DESARROLLO HUMANO",
  },
  {
    institucion: "MSP",
    value: "DISCAP",
    descripcion: "PORCENTAJE DISCAPACIDAD",
  },
  { institucion: "RC", value: "BIOMETRICO", descripcion: "DATOS BIOMETRICOS" },
  { institucion: "RC", value: "DEMOGRAF", descripcion: "DATOS DEMOGRAFICOS" },
  { institucion: "ANT", value: "DAT_VEHIC", descripcion: "DATOS VEHICULARES" },
  {
    institucion: "ANT",
    value: "DAT_VEHIC_MATRI",
    descripcion: "DATOS MATRICULACIÓN",
  },
  {
    institucion: "ANT",
    value: "DAT_LICEN_VEHIC",
    descripcion: "DATOS LICENCIA VEHICULAR",
  },
  {
    institucion: "ANT",
    value: "HIST_VEHIC",
    descripcion: "HISTORIAL VEHICULAR",
  },
  { institucion: "SRI", value: "AVAL_VEHIC", descripcion: "AVALUO VEHICULAR" },
  {
    institucion: "SRI",
    value: "SRI_ULT_PAGO_VEH",
    descripcion: "DATOS ULTIMO PAGO VEHICULAR",
  },
  {
    institucion: "SRI",
    value: "SRI_CONT_RUC",
    descripcion: "DATOS DE CONTACTO - RUC",
  },
];

const URL_BASE = "https://apiservices.manabi.gob.ec";

export default function Dinardap(props) {
  const [informationUser, setInformationUser] = useState([]);
  const [valid, isValid] = useState(false);
  const [submit, isSubmit] = useState(false);
  const [justificacion, setJustificacion] = useState("");
  const [institucion, setInstitucion] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [tipoconsulta, setTipoconsulta] = useState("");
  const [listTipoconsulta, setLisTipoconsulta] = useState([]);
  const [responseService, setResponseService] = useState({});
  const [identificacionvalid, isIdentificacionValid] = useState(true);
  const [personInformation, setPersonInformation] = useState({});
  const { history } = props;

  useEffect(() => {
    const configuration = localStorage.getItem("auth-jwt");
    if (configuration) {
      // console.log(configuration);
      const objectJwt = decodeJWT_RS256(configuration);
      if (objectJwt.exp < Date.now() / 1000) {
        localStorage.clear("auth-jwt");
        history.push("/login");
      } else {
        setInformationUser(
          objectJwt.sub !== undefined ? objectJwt.sub : objectJwt.data
        );
      }
    }
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let SERVICES_DINARDAP = "";
    if (tipoconsulta === "IMPEDI") {
      SERVICES_DINARDAP = `persona/mdt/impedimentolaboral/${identificacion}`;
    } else if (tipoconsulta === "BONO") {
      SERVICES_DINARDAP = `persona/beneficiario/bonodesarrollo/${identificacion}`;
    } else if (tipoconsulta === "DISCAP") {
      SERVICES_DINARDAP = `persona/msp/verificardiscapacidad/${identificacion}`;
    } else if (tipoconsulta === "BIOMETRICO") {
      SERVICES_DINARDAP = `persona/registrocivil/biometrico/${identificacion}`;
    } else if (tipoconsulta === "DEMOGRAF") {
      SERVICES_DINARDAP = `persona/registrocivil/datosdemograficos/${identificacion}`;
    } else if (tipoconsulta === "HIST_LAB") {
      SERVICES_DINARDAP = `persona/segurosocial/historiallaboral/${identificacion}`;
    } else if (tipoconsulta === "STATUS_AFILIADO") {
      SERVICES_DINARDAP = `persona/segurosocial/estadoafiliado/${identificacion}`;
    }
    // else if (tipoconsulta === 'DAT_VEHIC') {
    //   SERVICES_DINARDAP = `consultavehicular/datosvehiculares/${identificacion}`;
    // }
    else if (
      tipoconsulta === "DAT_VEHIC_MATRI" ||
      tipoconsulta === "DAT_VEHIC"
    ) {
      SERVICES_DINARDAP = `consultavehicular/ant/matriculacion/${identificacion}`;
    }
    //-------------------
    else if (tipoconsulta === "SRI_ULT_PAGO_VEH") {
      SERVICES_DINARDAP = `consultavehicular/sri/ultimopago/${identificacion}`;
    } else if (tipoconsulta === "HIST_VEHIC") {
      SERVICES_DINARDAP = `consultavehicular/ant/historialvehicular/${identificacion}`;
    } else if (tipoconsulta === "SRI_CONT_RUC") {
      SERVICES_DINARDAP = `persona/sri/contactos/${identificacion}`;
    } else if (tipoconsulta === "DAT_LICEN_VEHIC") {
      SERVICES_DINARDAP = `persona/ant/datoslicencia/${identificacion}`;
    } else if (tipoconsulta === "AVAL_VEHIC") {
      SERVICES_DINARDAP = `consultavehicular/sri/avaluovehicular/${identificacion}`;
    }

    const form = event.currentTarget;

    if (institucion === "" || tipoconsulta === "" || identificacion === "") {
      if (form.checkValidity() === false) {
        isValid(true);
      }
    } else {
      isSubmit(true);
      axios
        .get(`${URL_BASE}/${SERVICES_DINARDAP}`)
        .then((resp) => {
          const { data, status } = resp;
          if (status === 200) {
            // console.log(data);
            if (tipoconsulta === "BONO" && Object.keys(data).length === 0) {
              setResponseService({ estado: "NO TIENE" });
            } else {
              setResponseService(data);
            }
          }
          isSubmit(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function toInputUppercase(event) {
    event.target.value = ("" + event.target.value).toUpperCase();
  }

  function addLisTipoConsulta(event) {
    const filterList = optionsTipoConsulta.filter(
      (tipoconsulta) => tipoconsulta.institucion === event.target.value
    );
    setLisTipoconsulta(filterList);
  }

  function toPDF() {
    if (identificacion !== "" && tipoconsulta !== "") {
      //axios
      instance_py
        .get(
          `/v1/dinardap/report-pdf?query=${tipoconsulta}&query_parameter=${identificacion}`,
          {
            //.get(`localhost:3015/v1/dinardap/report-pdf?query=${tipoconsulta}&query_parameter=${identificacion}`, {
            responseType: "arraybuffer",
            headers: {
              Authorization:
                "Bearer " +
                localStorage.getItem("auth-jwt").replaceAll(`"`, ""),
            },
          }
        )
        .then((response) => {
          const filename = response.headers["content-disposition"]
            .split(";")[1]
            .split("=")[1];
          const file = new File([response.data], filename, {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = filename;
          link.click();
        })
        .catch((error) => {
          console.log(error);
          alert("Error al generar el Documento !!");
        });
    }
  }

  //---------------------------------------
  function renderUltimoPagoVehicularSRI() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "SRI_ULT_PAGO_VEH" &&
      ("institucionFinanciera" in responseService &&
      responseService.institucionFinanciera !== "" ? (
        <>
          <Col className={"row"} xs={12}>
            <Col sm={5}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>INSTITUCIÓN FINANCIERA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.institucionFinanciera}</a>
              </Card.Title>
            </Col>
            <Col sm={4}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>FECHA Y HORA DE PAGO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.fechaPago}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>VALOR</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>$ {responseService.valor}</a>
              </Card.Title>
            </Col>
          </Col>
        </>
      ) : (
        <h6
          style={{
            textAlign: "center",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          No se encontró un vehículo registrado con esta placa. Intente
          nuevamente.
        </h6>
      ))
    );
  }

  function renderHistorialVehicularANT() {
    let aux = [];
    //console.log(responseService);
    //console.log(Object.getPrototypeOf(responseService).constructor.name);
    if (
      Object.keys(responseService).length > 0 &&
      Object.getPrototypeOf(responseService).constructor.name === "Array" &&
      "parameters" in responseService[0] &&
      responseService[0].parameters !== undefined &&
      tipoconsulta === "HIST_VEHIC"
    ) {
      aux = responseService[0].parameters.sort((a, b) =>
        a[4].valor > b[4].valor ? 1 : b[4].valor > a[4].valor ? -1 : 0
      );

      return (
        <Col xs={12}>
          <h6
            style={{
              textAlign: "center",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            Historial Vehicular
          </h6>
          <Table responsive="lg">
            <thead>
              <tr>
                <th>Secuencia</th>
                <th>Nombre</th>
                <th>Identificación</th>
                <th>Fecha de Inicio</th>
                <th>Fecha de Fin</th>
              </tr>
            </thead>
            <tbody>
              {aux.map((contacto, index) => {
                return (
                  <tr key={index}>
                    <td>{contacto[4].valor}</td>
                    <td>{contacto[3].valor}</td>
                    <td>{contacto[2].valor}</td>
                    <td>{contacto[1].valor}</td>
                    <td>{contacto[0].valor}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      );
    }
  }

  function renderContactosRUCSRI() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "SRI_CONT_RUC" && (
        <Col xs={12}>
          <h6
            style={{
              textAlign: "center",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            Datos de Contacto - RUC
          </h6>
          <Table responsive="lg">
            <thead>
              <tr>
                <th>Tipo de Medio de Contacto</th>
                <th>Medio de Contacto</th>
              </tr>
            </thead>
            <tbody>
              {responseService[0].parameters.map((contacto, index) => (
                <tr key={index}>
                  {/* {contacto.map((column, index) => (
                <td key={index}>{column.valor}</td>
              ))} */}
                  <td>{contacto[0].valor}</td>
                  <td>{contacto[3].valor}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      )
    );
  }

  function renderDatosLicenciaVehicularANT() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "DAT_LICEN_VEHIC" &&
      (Object.keys(responseService).length > 0 &&
      responseService.tipoLicen !== "" ? (
        <>
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>APELLIDOS Y NOMBRES</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>
                  {responseService.apellidos + " " + responseService.nombres}
                </a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TIPO DE LICENCIA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.tipoLicen}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>FECHA DE EMISIÓN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.fechaPrimeraVez}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>FECHA DE CADUCIDAD</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.caducidad}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>PUNTOS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>
                  {(parseInt(responseService.puntos) / 100).toString()} / 30 Pts
                </a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NUM DE INFRACCIONES</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.infracciones}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>RESTRICCIÓN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.restriccion}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TIPO DE SANGRE</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.tipoSangre}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={4}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>DIRECCIÓN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.direccion}</a>
              </Card.Title>
            </Col>
            <Col sm={4}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CORREO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.correo}</a>
              </Card.Title>
            </Col>
            <Col sm={4}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TELÉFONO(S)</div>
                <hr style={{ marginTop: "0.5rem" }} />
                {responseService.telefono.split(";").map((num) => {
                  if (num.length >= 7) {
                    return (
                      <>
                        <a>{num}</a>
                        <br></br>
                      </>
                    );
                  }
                })}
                <a>{responseService.cedulaPadre}</a>
              </Card.Title>
            </Col>
          </Col>
        </>
      ) : (
        <h6
          style={{
            textAlign: "center",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          El ciudadano(a) no cuenta con licencia Vehicular.
        </h6>
      ))
    );
  }

  function renderAvaluoVehicularANT() {
    // if (Object.keys(responseService).length > 0 && tipoconsulta === 'AVAL_VEHIC') {

    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "AVAL_VEHIC" && (
        <>
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>PLACA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService[0].parameters[0][0].valor}</a>
              </Card.Title>
            </Col>
            <Col sm={4}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>RAMV - CPN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService[0].parameters[0][1].valor}</a>
              </Card.Title>
            </Col>
            <Col sm={5}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NUM. CHASIS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService[0].parameters[0][2].valor}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>FECHA DE COMPRA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService[0].parameters[0][3].valor}</a>
              </Card.Title>
            </Col>
            <Col sm={4}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>ULTIMO AÑO PAGADO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService[0].parameters[0][4].valor}</a>
              </Card.Title>
            </Col>
          </Col>
          <Col>
            <Table responsive="lg">
              <thead>
                <tr>
                  <th>Año Desde - Año Hasta</th>
                  <th>Avaluo Vehicular</th>
                </tr>
              </thead>
              <tbody>
                {responseService[0].parameters.map((contacto, index) => (
                  <tr key={index}>
                    {/* {contacto.map((column, index) => (
            <td key={index}>{column.valor}</td>
          ))} */}
                    <td>{contacto[6].valor + " - " + contacto[7].valor}</td>
                    <td>$ {contacto[5].valor}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </>
      )
    );

    // }
  }
  //--------------------------------

  function renderStatusIESS() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "STATUS_AFILIADO" &&
      responseService.estado_afiliado !== undefined && (
        <Col xs={12}>
          <h6
            style={{
              textAlign: "center",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            Estado Afiliado IESS
          </h6>
          <h4>{responseService.estado_afiliado[0].valor}</h4>
        </Col>
      )
    );
  }

  function renderDatosBiometrico() {
    //if (Object.keys(responseService).length === 0 && tipoconsulta === 'BIOMETRICO') return <h6 style={{textAlign: 'center', fontWeight: '700', textTransform: 'uppercase'}}>No posees foto y firma digital</h6>;
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "BIOMETRICO" && (
        <Row lg={12}>
          <Col lg={6}>
            <h6
              style={{
                textAlign: "center",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              FOTO DIGITAL
            </h6>
            <img
              src={`data:image/jpeg;base64,${responseService.foto}`}
              alt={"FOTO DIGITAL"}
            />
          </Col>
          <Col lg={6}>
            <h6
              style={{
                textAlign: "center",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              FIRMA DIGITAL
            </h6>
            <img
              src={`data:image/jpeg;base64,${responseService.firma}`}
              alt={"FIRMA DIGITAL"}
            />
          </Col>
        </Row>
      )
    );
  }

  function renderMinisteriodeTrabajo() {
    //console.log(Object.keys(responseService[1].parameters));
    return Object.keys(responseService).length > 0 &&
      tipoconsulta === "IMPEDI" ? (
      <>
        {responseService.length > 0 && (
          <>
            <Col className={"row"} xs={12}>
              {responseService[0].parameters !== undefined &&
                responseService[0].parameters[0].map(
                  ({ campo, valor }, index) => {
                    return (
                      <>
                        <Col sm={3}>
                          <Card.Title style={{ fontSize: "1rem" }}>
                            <div style={{ fontWeight: "bold" }}>
                              {getCampo(campo).toUpperCase()}
                            </div>
                            <hr style={{ marginTop: "0.5rem" }} />
                            <a>
                              {valor.trim() !== "" &&
                              valor.trim() !== "null" &&
                              valor.trim() !== undefined
                                ? valor.trim()
                                : "N/A"}
                            </a>
                          </Card.Title>
                        </Col>
                      </>
                    );
                  }
                )}
            </Col>
            {responseService[1].parameters !== undefined &&
            Object.keys(responseService[1].parameters).length !== 0 ? (
              responseService[1].parameters.map((impedimento, index) => {
                return (
                  <>
                    <Col className={"row"} xs={12}>
                      <Table responsive="lg">
                        <thead>
                          <tr>
                            <th>Institución</th>
                            <th>Tipo de Impedimento</th>
                            <th>Fecha de Impedimento</th>
                            <th>Código de Impedimento</th>
                            <th>Número de Oficio</th>
                            <th>Base Legal</th>
                            <th>Observación</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={index}>
                            <td>{impedimento[6].valor}</td>
                            <td>{impedimento[7].valor}</td>
                            <td>{impedimento[3].valor}</td>
                            <td>
                              {impedimento[5].valor.trim() !== "" &&
                              impedimento[5].valor.trim() !== "null" &&
                              impedimento[5].valor.trim() !== undefined
                                ? impedimento[2].valor.trim()
                                : "N/A"}
                            </td>
                            <td>
                              {impedimento[1].valor.trim() !== "" &&
                              impedimento[1].valor.trim() !== "null" &&
                              impedimento[1].valor.trim() !== undefined
                                ? impedimento[2].valor.trim()
                                : "N/A"}
                            </td>
                            <td>
                              {impedimento[4].valor.trim() !== "" &&
                              impedimento[4].valor.trim() !== "null" &&
                              impedimento[4].valor.trim() !== undefined
                                ? impedimento[2].valor.trim()
                                : "N/A"}
                            </td>
                            <td>
                              {impedimento[2].valor.trim() !== "" &&
                              impedimento[2].valor.trim() !== "null" &&
                              impedimento[2].valor.trim() !== undefined
                                ? impedimento[2].valor.trim()
                                : "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className="col-sm-12">
                <hr style={{ margin: "0.5rem 0rem" }} />
                <h6
                  style={{
                    textAlign: "center",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  El ciudadano(a) no posee ningún impedimento laboral
                </h6>
              </div>
            )}
          </>
        )}
      </>
    ) : (
      <></>
    );
    // <h6 style={{textAlign: 'center', fontWeight: '700', textTransform: 'uppercase'}}>El ciudadano(a) no posee ningun
    //   impedimento laboral</h6>;
  }

  function getCampo(campo) {
    switch (campo) {
      case "apellidoP":
        return "apellidos";
      case "numeroOficio":
        return "número de oficio";
      case "fechaImpedimento":
        return "fecha de impedimento";
      case "baseLegal":
        return "base legal";
      case "codiImpe":
        return "código de impedimento";
      case "nombreLargo":
        return "institución";
      case "tipoImpedimento":
        return "tipo de impedimento";
      default:
        return campo;
    }
  }

  function renderPorcentajeDiscapacidad() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "DISCAP" &&
      (Object.keys(responseService).length > 0 ? (
        <>
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CÓDIGO CONADIS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.codigoConadis}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>GRADO DISCAPACIDAD</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.gradoDiscapacidad}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  PORCENTAJE DE DISCAPACIDAD
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{`${responseService.porcentajeDiscapacidad || 0} %`}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  TIPO DISCAPACIDAD PREDOMINA
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.tipoDiscapacidadPredomina}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>OBSERVACIÓN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.mensaje}</a>
              </Card.Title>
            </Col>
          </Col>
        </>
      ) : (
        <h6
          style={{
            textAlign: "center",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          El ciudadano(a) no registra ninguna discapacidad
        </h6>
      ))
    );
  }

  function renderBonoDesarrolloHumano() {
    let validIdent =
      tipoconsulta === "BONO" &&
      isIdentificationValid(identificacion) &&
      Object.keys(personInformation).length !== 0;
    // if (tipoconsulta === 'BONO' && isIdentificationValid(identificacion)){
    //   validIdent = Object.keys(personInformation).length !== 0
    // }

    if (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "BONO" &&
      Object.keys(responseService).includes("cedulaBeneficiario")
    ) {
      return (
        <>
          <Col className={"row"} xs={12}>
            <Col sm={4}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NÚMERO DE CÉDULA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.cedulaBeneficiario}</a>
              </Card.Title>
            </Col>
            <Col sm={5}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NOMBRES COMPLETOS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{`${responseService.apellidoBeneficiario} ${responseService.nombreBeneficiario}`}</a>
              </Card.Title>
            </Col>
            {/* <Col sm={3}>
                <Card.Title style={{ fontSize: '1rem' }}>
                  <div style={{ fontWeight: 'bold' }}>FECHA NACIMIENTO</div>
                  <hr style={{ marginTop: '0.5rem' }} />
                  <a>{responseService.fechaNacimiento}</a>
                </Card.Title>
              </Col> */}
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TIPO SUBSIDIO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.subsidio}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>ESTADO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a
                  style={{
                    color:
                      responseService.estado === "INACTIVO" ? "red" : "green",
                  }}
                >
                  {responseService.estado}
                </a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>MOTIVO ESTADO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.descripcionExc}</a>
              </Card.Title>
            </Col>
          </Col>
        </>
      );
    } else if (
      tipoconsulta === "BONO" &&
      isIdentificationValid(identificacion) &&
      validIdent
    ) {
      return (
        <h6
          style={{
            textAlign: "center",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          El ciudadano(a) no registra bono de desarrollo social.
        </h6>
      );
    }
  }

  function renderDatosDemograficos() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "DEMOGRAF" &&
      (Object.keys(responseService).length > 0 ? (
        <>
          <Col className={"row"} xs={12}>
            <Col sm={12}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>APELLIDOS Y NOMBRES</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.nombre}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CÓDIGO DACTILAR</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.individualDactilar}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>INSTRUCCIÓN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.instruccion}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NACIONALIDAD</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.nacionalidad}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>SEXO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.sexo}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>FECHA NACIMIENTO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.fechaNacimiento}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>ACTA DE DEFUNCION</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.actaDefuncion}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CÉDULA CONYUGUE</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.cedulaConyuge}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NOMBRE CONYUGUE</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.conyuge}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  DOMICILIO - CALLES/SECTOR - NÚMERO CASA
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.domicilio}</a>
                <br />
                <a>{responseService.callesDomicilio}</a>
                <br />
                <a>CASA #: {responseService.numeroCasa}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  CÉDULA Y NOMBRES COMPLETO DE LA MADRE
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.cedulaMadre}</a>
                <br />
                <a>{responseService.nombreMadre}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  CÉDULA Y NOMBRES COMPLETO DEL PADRE
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.cedulaPadre}</a>
                <br />
                <a>{responseService.nombrePadre}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CONDICIÓN CIUDADANO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.condicionCiudadano}</a>
              </Card.Title>
            </Col>
          </Col>
        </>
      ) : (
        <h6
          style={{
            textAlign: "center",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          El ciudadano(a) no logró ser encontrado, por favor inténtelo
          nuevamente
        </h6>
      ))
    );
  }

  function renderHistorialLaboral() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "HIST_LAB" && (
        <Col xs={12}>
          <h6
            style={{
              textAlign: "center",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            Imposiciones Detalladas
          </h6>
          <Table responsive="lg">
            <thead>
              <tr>
                <th>Empleador</th>
                <th>Ruc</th>
                <th>Número de días</th>
                <th>Número imposiciones</th>
                <th>Origen</th>
                <th>Período desde</th>
                <th>Período hasta</th>
                <th>Sector</th>
              </tr>
            </thead>
            <tbody>
              {responseService.historial_laboral.map((periodos, index) => (
                <tr>
                  {periodos.map((historial) => (
                    <td>{historial.valor}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <div className={"row"}>
            <Col xs={2}></Col>
            <Col xs={8}>
              <h6
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  textTransform: "uppercase",
                }}
              >
                Resumen Imposiciones
              </h6>
              <Table responsive="lg">
                <thead>
                  <tr>
                    <th>Número de días</th>
                    <th>Número imposiciones</th>
                    <th>Origen</th>
                  </tr>
                </thead>
                <tbody>
                  {responseService.historiallaboral_resumen_imposiciones.map(
                    (periodos, index) => (
                      <tr>
                        {periodos.map((historial) => (
                          <td>{historial.valor}</td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </Col>
            <Col xs={2}></Col>
          </div>
        </Col>
      )
    );
  }

  function settingInformationCustomer(identificacion) {
    axios
      .get(
        `${URL_BASE}/persona/registrocivil/datosdemograficos/${identificacion}`
      )
      .then((resp) => {
        const { data, status } = resp;
        if (status === 200) {
          if (data) {
            const { nombre, domicilio, callesDomicilio } = data;
            setPersonInformation({
              nombre,
              domicilio,
              callesDomicilio,
              identificacion,
            });
          }
        }
        isSubmit(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function renderDatosVehicularesMatricula() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "DAT_VEHIC_MATRI" &&
      (Object.keys(responseService).length > 0 ? (
        <>
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  NÚMERO DE CÉDULA PROPIETARIO
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.docPropietario}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  NOMBRES COMPLETOS PROPIETARIO
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.propietario}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CORREO ELECTRÓNICO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.correo}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>DIRECCIÓN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.direccion}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  NÚMERO DE CÉDULA PROPIETARIO ANTERIOR
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.cedulaPropAnterior}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  NOMBRES COMPLETOS PROPIETARIO ANTERIOR
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.nombrePropAnterior}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NÚMERO DE PLACA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.placa}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CANVCP</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.canvcp}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CARROCERIA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.carroceria}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CHASIS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.chasis}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CILINDRAJE</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.cilindraje}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CLASE VEHÍCULO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.claseVehiculo}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>COLOR</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.color}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>COMBUSTIBLE</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.combustible}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CONTRATO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.contrato}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>FECHA CADUCIDAD</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.fechaCaducidad}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>FECHA MATRÍCULA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.fechaMatricula}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>MARCA</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.marca}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>MODELO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.modelo}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>MOTOR</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.motor}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>EJES</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.numEjes}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NÚMERO DE RUEDAS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.numRuedas}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NÚMERO REVISADO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.numeroRevisado}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>NÚMERO TRASPASO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.numeroTraspaso}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>PASAJEROS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.pasajeros}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TELEFONOS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.telefono}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TIPO PESO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.tipoPeso}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TIPO VEHÍCULO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.tipoVehiculo}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TONELAJE</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.tonelaje}</a>
              </Card.Title>
            </Col>
          </Col>
        </>
      ) : (
        <h6
          style={{
            textAlign: "center",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          El numero de placa ingresado no registra datos vehiculares
        </h6>
      ))
    );
  }

  function renderDatosVehiculares() {
    return (
      Object.keys(responseService).length > 0 &&
      tipoconsulta === "DAT_VEHIC" &&
      (Object.keys(responseService).length > 0 ? (
        <>
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  NÚMERO DE CÉDULA PROPIETARIO
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.docPropietario}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>
                  NOMBRES COMPLETOS PROPIETARIO
                </div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.propietario}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CORREO ELECTRÓNICO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.correo}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TELEFONOS</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.telefono}</a>
              </Card.Title>
            </Col>
          </Col>
          <br />
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>MODELO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.modelo}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>COLOR</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.color}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CLASE VEHICULO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.claseVehiculo}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CLASE TRAN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.claseTran}</a>
              </Card.Title>
            </Col>
          </Col>
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>CILINDRAJE</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.cilindraje}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>AVALÚO COMERCIAL</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.avaluoComercial}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>AÑO MATRICULADO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.anioMatriculado}</a>
              </Card.Title>
            </Col>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>DIRECCIÓN</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.direccion}</a>
              </Card.Title>
            </Col>
          </Col>
          <Col className={"row"} xs={12}>
            <Col sm={3}>
              <Card.Title style={{ fontSize: "1rem" }}>
                <div style={{ fontWeight: "bold" }}>TIPO DE SERVICIO</div>
                <hr style={{ marginTop: "0.5rem" }} />
                <a>{responseService.tipoServicio}</a>
              </Card.Title>
            </Col>
          </Col>
        </>
      ) : (
        <h6
          style={{
            textAlign: "center",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          El numero de placa ingresado no registra datos vehiculares
        </h6>
      ))
    );
  }

  const dowloadURI = `https://gestionproyectos.manabi.gob.ec/api-donacion/public/usuario/docpdf?user=${encodeURIComponent(
    JSON.stringify(informationUser)
  )}&personaconsultada=${encodeURIComponent(
    JSON.stringify(personInformation)
  )}&filter=${encodeURIComponent(
    JSON.stringify({ institucion, tipoconsulta, identificacion, justificacion })
  )}&result=${encodeURIComponent(JSON.stringify(responseService))}`;
  let placeHolderIdentificacion = "";
  let labelIdentificacion = "";
  if (
    tipoconsulta === "DAT_VEHIC" ||
    tipoconsulta === "DAT_VEHIC_MATRI" ||
    tipoconsulta === "HIST_VEHIC" ||
    tipoconsulta === "AVAL_VEHIC"
  ) {
    placeHolderIdentificacion = "INGRESE NÚMERO DE PLACA";
    labelIdentificacion = "NÚMERO DE PLACA";
  } else if (tipoconsulta === "SRI_CONT_RUC") {
    placeHolderIdentificacion = "INGRESE EL RUC";
    labelIdentificacion = "RUC";
  } else if (tipoconsulta === "SRI_ULT_PAGO_VEH") {
    placeHolderIdentificacion = "INGRESE NÚMERO DE PLACA O RAMV";
    labelIdentificacion = "NÚMERO DE PLACA O RAMV";
  }

  return (
    <Container fluid>
      <ValidateLoader {...props} />
      <NavBar {...props} />
      <Card style={{ marginTop: 15 }}>
        <Card.Header>DATOS DEL FUNCIONARIO</Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>NOMBRE COMPLETO:</span>{" "}
                {informationUser.nombrecompleto}
              </Card.Text>
            </Col>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>CÉDULA:</span>{" "}
                {informationUser.cedula}
              </Card.Text>
            </Col>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>DIRECCIÓN:</span>{" "}
                {informationUser.direccion}
              </Card.Text>
            </Col>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>CORREO:</span>{" "}
                {informationUser.correo}
              </Card.Text>
            </Col>
            <Col sm={6}>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>CARGO:</span>{" "}
                {informationUser.cargo}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card style={{ marginTop: 15 }}>
        <Card.Header>INTEROPERABILIDAD DINARDAP</Card.Header>
        <Form
          noValidate
          validated={valid}
          onSubmit={handleSubmit}
          style={{ marginTop: 15 }}
        >
          <Card.Body>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  INSTITUCION:
                </Form.Label>
                <Form.Control
                  as="select"
                  required
                  value={institucion}
                  onChange={(event) => {
                    setInstitucion(event.target.value);
                    addLisTipoConsulta(event);
                    setResponseService({});
                    setIdentificacion("");
                  }}
                >
                  <option></option>
                  {optionsInstitucion.map((option, idx) => {
                    return (
                      <option key={idx} value={option.value}>
                        {option.descripcion}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Seleccione una Institución.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  TIPO DE CONSULTA:
                </Form.Label>
                <Form.Control
                  as="select"
                  required
                  value={tipoconsulta}
                  onChange={(event) => {
                    setTipoconsulta(event.target.value);
                    setResponseService({});
                    setIdentificacion("");
                  }}
                >
                  <option></option>
                  {listTipoconsulta.map((option, idx) => {
                    return (
                      <option key={idx} value={option.value}>
                        {option.descripcion}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Seleccione Tipo de Consulta.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  {labelIdentificacion || "NÚMERO DE CÉDULA"}:
                </Form.Label>
                <Form.Control
                  as="input"
                  required
                  isInvalid={!identificacionvalid}
                  type="text"
                  isValid={
                    identificacionvalid &&
                    (!labelIdentificacion
                      ? identificacion.length === 10
                      : identificacion.length === 6
                      ? true
                      : identificacion.length === 7)
                  }
                  placeholder={
                    placeHolderIdentificacion || "INGRESE NÚMERO DE CÉDULA"
                  }
                  value={identificacion}
                  onChange={(event) => {
                    setIdentificacion(event.target.value.toUpperCase());
                    if (
                      event.target.value.length === 10 &&
                      (!labelIdentificacion || labelIdentificacion === "RUC")
                    ) {
                      isIdentificacionValid(
                        isIdentificationValid(event.target.value.trim())
                      );
                      //setPersonInformation(event.target.value.trim());
                      settingInformationCustomer(event.target.value.trim());
                    } else if (
                      event.target.value.length >= 6 &&
                      event.target.value.length <= 7
                    ) {
                      isIdentificacionValid(
                        isPlateValid(event.target.value.trim())
                      );
                    }
                  }}
                  maxLength={13}
                  minLength={7}
                />
                <Form.Control.Feedback type="invalid">
                  {!labelIdentificacion
                    ? identificacionvalid
                      ? `Ingrese el número de cédula`
                      : `El número de cédula es incorrecto, por favor verifique`
                    : `El parametro ingresado no es valido.`}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  DETALLE DE JUSTIFICACIÓN:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  required
                  type="text"
                  placeholder="INGRESE DETALLE DE LA JUSTIFICACIÓN ..."
                  defaultValue={justificacion}
                  onChange={(event) => setJustificacion(event.target.value)}
                  onInput={toInputUppercase}
                  maxLength={300}
                  minLength={15}
                />
                <Form.Control.Feedback type="invalid">
                  Ingrese detalle de la justificación.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Card.Body>
          <Card.Footer className="text-muted">
            <Row
              style={{ justifyContent: "space-evenly" }}
              className="btn-toolbar"
            >
              <Button
                variant="primary"
                type="submit"
                style={{ marginBottom: 15 }}
                disabled={submit}
              >
                {!submit && "ENVIAR DATOS"}
                {submit && <Spinner animation="border" variant="info" />}
              </Button>
              {[
                "AVAL_VEHIC",
                "HIST_VEHIC",
                "SRI_CONT_RUC",
                "DAT_LICEN_VEHIC",
                "SRI_ULT_PAGO_VEH",
                "IMPEDI",
                "BONO",
              ].includes(tipoconsulta) ? (
                // tipoconsulta === 'AVAL_VEHIC' || tipoconsulta === 'HIST_VEHIC' || tipoconsulta === 'SRI_CONT_RUC' || tipoconsulta === 'DAT_LICEN_VEHIC' || tipoconsulta === 'SRI_ULT_PAGO_VEH' || tipoconsulta === 'IMPEDI' ?
                <Button
                  variant="danger"
                  type="button"
                  style={{ marginBottom: 15 }}
                  onClick={() => toPDF()}
                >
                  GENERAR PDF
                </Button>
              ) : (
                <a
                  className="btn btn-danger"
                  download="Solicitud_Dinardap_GPM.pdf"
                  style={
                    Object.keys(personInformation).length <= 0
                      ? {
                          pointerEvents: "none",
                          cursor: "default",
                          marginBottom: 15,
                        }
                      : { marginBottom: 15 }
                  }
                  href={dowloadURI}
                  target="_blank"
                >
                  GENERAR PDF
                </a>
              )}
            </Row>
          </Card.Footer>
        </Form>
      </Card>
      <Card style={{ marginTop: 15 }}>
        <Card.Header>RESULTADO DE LA BUSQUEDA</Card.Header>
        <Card.Body>
          <>
            {submit && (
              <img
                className="mx-auto d-block"
                src={LoadingAnimated}
                alt="Cargando..."
              />
            )}
            {Object.keys(responseService).length === 0 && !submit && (
              <>
                {institucion === "IESS" && (
                  <h5>
                    LOS SERVICIOS DEL IESS HABITUALMENTE PRESENTAN
                    INTERRUPCIONES EN SUS SERVICIOS, LO QUE GENERA INTERMITENCIA
                    EN EL ACCESO A SUS CANALES DE INFORMACIÓN, LE RECOMENDAMOS
                    QUE POR FAVOR VUELVA A REALIZAR LA BÚSQUEDA
                  </h5>
                )}
                {institucion === "ANT" && (
                  <h5>
                    LOS SERVICIOS DEL ANT HABITUALMENTE PRESENTAN INTERRUPCIONES
                    EN SUS SERVICIOS, LO QUE GENERA INTERMITENCIA EN EL ACCESO A
                    SUS CANALES DE INFORMACIÓN, LE RECOMENDAMOS QUE POR FAVOR
                    VUELVA A REALIZAR LA BÚSQUEDA
                  </h5>
                )}
                <img
                  className="mx-auto d-block"
                  src={EmptyAnimated}
                  alt="No se ha encontrado resultados..."
                />
              </>
            )}
            {renderHistorialLaboral()}
            {renderStatusIESS()}
            {renderMinisteriodeTrabajo()}
            {renderDatosBiometrico()}
            {renderDatosDemograficos()}
            {renderBonoDesarrolloHumano()}
            {renderPorcentajeDiscapacidad()}
            {renderDatosVehiculares()}
            {renderDatosVehicularesMatricula()}
            {renderUltimoPagoVehicularSRI()}
            {renderHistorialVehicularANT()}
            {renderContactosRUCSRI()}
            {renderDatosLicenciaVehicularANT()}
            {renderAvaluoVehicularANT()}
          </>
        </Card.Body>
      </Card>
      <br></br>
    </Container>
  );
}
