import React, { useEffect, useState } from "react";
import { Container, Card, Col, Row } from "react-bootstrap";
import NavBar from "./NavBar";
import { decodeJWT_RS256 } from "./utils/Utilities";
import { Link } from "react-router-dom";
import { instance_py } from "./utils/InstancePy";

export default function Modules(props) {
  const [modules_gp, setModules_gp] = useState([]);
  const { history } = props;

  useEffect(() => {
    const configuration = localStorage.getItem("auth-jwt");
    if (configuration) {
      const objectJwt = decodeJWT_RS256(configuration);
      if (objectJwt.exp < Date.now() / 1000) {
        localStorage.clear("auth-jwt");
        history.push("/login");
      }
      instance_py
        .get("/v1/user/getModules", {
          // headers: {
          //   Authorization: "Bearer " + JSON.parse(configuration)
          // }
        })
        .then((resp) => {
          if ("data" in resp.data) {
            setModules_gp(resp.data.data);
          } else {
            setModules_gp([]);
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);

  console.log({
    props,
  });

  return (
    <>
      <Container>
        <NavBar {...props} />
        <Card style={{ marginTop: 10, paddingBottom: 10 }}>
          <Card.Header>
            <h4>Módulos</h4>
          </Card.Header>
          <Container>
            <Row>
              {modules_gp.length > 0
                ? modules_gp.map((module, index) => {
                    return (
                      <Col sm={4} key={index}>
                        <Link to={module.route} style={{ color: "black" }}>
                          <Card style={{ marginTop: 10 }}>
                            <Card.Header>
                              <h6>{module.name}</h6>
                            </Card.Header>
                            <Card.Body>{module.description}</Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    );
                  })
                : null}
            </Row>
          </Container>
        </Card>
      </Container>
    </>
  );
}
