/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Button,
  Container,
  Toast,
  Alert,
  Image,
  Row,
  Card,
  Spinner,
  Modal,
} from "react-bootstrap";
import LogoGPM from "../assets/imgs/logo.png";

import { formatDateToDDMMYYYY } from "../utils/Utilities.js";
import {
  validateDocument,
  getDepartments,
  getPollGroupQuestions,
  sendPsychosocialSurvey,
} from "../utils/instance_psc";
import GroupedQuestionTabs from "../components/GroupedQuestionTabs";

const cities = [
  "24 DE MAYO",
  "BOLÍVAR",
  "CHONE",
  "EL CARMEN",
  "FLAVIO ALFARO",
  "JAMA",
  "JARAMIJO",
  "JIPIJAPA",
  "JUNÍN",
  "MANTA",
  "MONTECRISTI",
  "OLMEDO",
  "PAJÁN",
  "PEDERNALES",
  "PICHINCHA",
  "PORTOVIEJO",
  "PUERTO LÓPEZ",
  "ROCAFUERTE",
  "SAN VICENTE",
  "SANTA ANA",
  "SUCRE",
  "TOSAGUA",
];

const PsychosocialSurvey = () => {
  const [toastData, setToastData] = useState({
    show: false,
    message: "",
    title: "",
  });

  const initialFormData = {
    document: "",
    date: formatDateToDDMMYYYY(new Date()),
    province: "MANABÍ",
    city: "",
    work_area: "",
    department: "",
    academic_background: "",
    years_in_the_institution: "",
    age: "",
    ethnicity: "",
    sex: "",
    questions_responses: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [publicServantValid, setPublicServantValid] = useState(false);
  const [publicServantValidLoading, setPublicServantValidLoading] =
    useState(false);
  const [departments, setDepartments] = useState([]);
  const [questionsAndGroups, setQuestionsAndGroups] = useState([]);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [showSubmitPollMessageModal, setShowSubmitPollMessageModal] =
    useState(false);
  const [submitPollLoader, setSubmitPollLoader] = useState(false);

  useEffect(() => {
    getDepartments()
      .then((response) => {
        setDepartments(response.data.data.departments);
      })
      .catch((error) => console.log(error));

    getPollGroupQuestions()
      .then((response) => {
        setQuestionsAndGroups(response.data.data.group);
      })
      .catch((error) => console.log(error));
  }, [publicServantValid]);

  const validatePublicServant = () => {
    setPublicServantValidLoading(true);
    if (formData.document.length < 10) {
      setToastData({
        show: true,
        message:
          "Estimado usuario, el número de cédula ingresado no es válido.\nVerifique el número de cédula ingresado.",
        title: "Validación de Cédula",
      });
      setPublicServantValidLoading(false);
      return;
    }
    validateDocument(formData.document)
      .then((response) => {
        if (!response.data.data.valid) {
          setToastData({
            show: true,
            message:
              "Estimado usuario, el número de cédula ingresado no es válido.\nVerifique el número de cédula ingresado.",
            title: "Validación de Cédula",
          });
        } else if (!response.data.data.is_public_servant) {
          setToastData({
            show: true,
            message:
              "Estimado usuario, el número de cédula ingresado no pertenece a un Servidor Público del GPM.\nVerifique el número de cédula ingresado.",
            title: "Validación de Servidor Público",
          });
        } else if (response.data.data.is_completed_survey) {
          setToastData({
            show: true,
            message:
              "Estimado usuario, usted ya ha completado la encuesta para este periodo.\nSi considera que es un error, comuníquese con el administrador.",
            title: "Validación de Encuesta",
          });
        } else {
          setFormData({
            ...formData,
            academic_background:
              response.data.data.public_servant_data.academic_background,
            age: response.data.data.public_servant_data.age,
            years_in_the_institution:
              response.data.data.public_servant_data.antiquity,
            ethnicity: response.data.data.public_servant_data.ethnicity,
            sex: response.data.data.public_servant_data.gender,
          });
          setPublicServantValid(true);
        }
        setPublicServantValidLoading(false);
      })
      .catch((error) => {
        setToastData({
          show: true,
          message:
            "Estimado usuario, ha ocurrido un error al validar el documento.\n Intente nuevamente más tarde o comuníquese con el administrador.",
          title: "Error",
        });
        console.log(error);
        setPublicServantValidLoading(false);
      })
      .finally(() => {
        setPublicServantValidLoading(false);
      });
  };

  const LoaderSubmitPoll = () => {
    return (
      <Modal centered show={submitPollLoader} scrollable size="sm">
        <Modal.Header>
          <Modal.Title>Enviando encuesta...</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: "auto", justifyContent: "center" }}>
          <Spinner
            animation="border"
            variant="success"
            style={{ width: "60px", height: "60px" }}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const SubmitPollMessageModal = () => {
    const [seconds, setSeconds] = useState(5);
    const [initCount, setInitCount] = useState(false);

    useEffect(() => {
      if (initCount) {
        setFormData(initialFormData);
        setPublicServantValid(false);
      }
    }, [initCount]);

    useEffect(() => {
      if (initCount) {
        if (seconds > 0) {
          setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
          window.location.reload();
        }
      }
    }, [seconds, initCount]);

    return (
      <Modal
        centered
        show={showSubmitPollMessageModal}
        scrollable
        onEntered={() => {
          setInitCount(true);
        }}
      >
        <Modal.Header>
          <Modal.Title>Encuesta enviada correctamente!!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ padding: "1rem" }}>
            <Card.Text>
              Estimado usuario, su encuesta ha sido enviada con éxito.
              Agradecemos su colaboración.
            </Card.Text>
            <Card.Text>
              La ventana se recargará automáticamente en {seconds} segundos.
            </Card.Text>
          </Card>
        </Modal.Body>
      </Modal>
    );
  };

  const validatePublicServantModal = () => {
    return (
      <Modal
        size="lg"
        centered
        show={!publicServantValid && !showSubmitPollMessageModal}
      >
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center" }}>
            <h4>
              CUESTIONARIO DE EVALUACIÓN PSICOSOCIAL EN ESPACIOS LABORALES
            </h4>
            <h5>Validación del Servidor Público</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Estimado profesional, con el fin de comprobar que pertenece a la
            institución y acceder a la encuesta, debe ingresar y verificar su
            número de cédula.
          </p>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Ingrese su número de cédula"
              maxLength={10}
              minLength={10}
              onChange={(e) =>
                setFormData({ ...formData, document: e.target.value })
              }
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  validatePublicServant();
                }
              }}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          {!publicServantValidLoading ? (
            <Button
              variant="success"
              onClick={validatePublicServant}
              style={{ marginRight: "1rem", width: "100%" }}
            >
              Validar
            </Button>
          ) : (
            <Spinner animation="border" variant="success" />
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  const toastMessage = () => {
    return (
      <Toast
        className="position-fixed"
        show={toastData.show}
        onClose={() => setToastData({ show: false, message: "", title: "" })}
        delay={5000}
        autohide
        style={{
          display: toastData.show ? "block" : "none",
          position: "absolute",
          top: "1rem",
          right: "1rem",
          zIndex: 9999,
        }}
      >
        <Toast.Header style={{ justifyContent: "center" }}>
          <strong className="mr-auto">{toastData.title}</strong>
        </Toast.Header>
        <Toast.Body>{toastData.message}</Toast.Body>
      </Toast>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitPollLoader(true);
    sendPsychosocialSurvey(formData)
      .then((response) => {
        if (response.status === 201) {
          setShowSubmitPollMessageModal(true);
        } else {
          console.log(response);
          setToastData({
            show: true,
            message:
              "Estimado usuario, ha ocurrido un error al enviar la encuesta.\nIntente nuevamente más tarde o comuníquese con el administrador.",
            title: "Error",
          });
        }
      })
      .catch((error) => {
        setToastData({
          show: true,
          message:
            "Estimado usuario, ha ocurrido un error al enviar la encuesta.\nIntente nuevamente más tarde o comuníquese con el administrador.",
          title: "Error",
        });
        console.log(error);
      })
      .finally(() => {
        setSubmitPollLoader(false);
      });
  };

  return (
    <>
      {SubmitPollMessageModal()}
      {validatePublicServantModal()}
      {toastMessage()}
      {publicServantValid && (
        <>
          <Container
            style={{
              marginBottom: "60px",
              marginTop: "15px",
            }}
            fluid={"sm"}
          >
            <LoaderSubmitPoll />
            <Alert variant="success" style={{ backgroundColor: "#157a44" }}>
              <Alert.Heading
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                <h2
                  style={{
                    color: "white",
                    fontSize: "larger",
                    fontWeight: "700",
                  }}
                >
                  ENCUESTA PSICOSOCIAL
                </h2>
              </Alert.Heading>
              <div style={{ justifyContent: "center", textAlign: "center" }}>
                <Image
                  style={{ textAlign: "center", filter: "brightness(500%)" }}
                  src={LogoGPM}
                  fluid
                />
              </div>
              <hr />
              <Card>
                <Card.Body style={{ color: "black" }}>
                  <div>
                    <strong>
                      Instrucciones para completar el cuestionario:
                    </strong>
                    <ol>
                      <li>
                        El cuestionario es anónimo es decir no se solicita
                        información personal sobre el participante.
                      </li>
                      <li>
                        La información obtenida es confidencial es decir que se
                        ha de guardar, mantener y emplear con estricta cautela
                        la información obtenida.
                      </li>
                      <li>
                        Completar todo el cuestionario requiere entre 15 a 20
                        minutos.
                      </li>
                      <li>
                        Antes de responder, leer detenidamente cada pregunta y
                        opción de respuesta. En este punto es necesario
                        identificar y valorar todos aquellos factores del ámbito
                        psicosocial que pueden representar un riesgo para la
                        salud y el bienestar laboral.
                      </li>
                      <li>
                        Marcar la respuesta que considere que describe mejor su
                        situación. Es obligatorio contestar todos los ítems del
                        cuestionario, en caso de error en la respuesta encerrar
                        en un círculo la misma y seleccionar nuevamente la
                        respuesta.
                      </li>
                      <li>No existen respuestas correctas o incorrectas.</li>
                      <li>
                        Evitar distracciones mientras completa el cuestionario,
                        en caso de inquietud, solicitar asistencia al
                        facilitador.
                      </li>
                      <li>
                        El cuestionario tiene una sección denominada
                        “observaciones y comentarios”, que puede ser utilizada
                        por los participantes en caso de sugerencias u
                        opiniones.
                      </li>
                      <li>
                        Los resultados finales de la evaluación serán
                        socializados oportunamente a los participantes.
                      </li>
                    </ol>

                    <strong>Muchas gracias por su colaboración</strong>
                  </div>
                </Card.Body>
              </Card>
            </Alert>
            <Container>
              <Form
                onSubmit={handleSubmit}
                validated={firstSubmit}
                onInvalid={() => {
                  setToastData({
                    show: true,
                    message:
                      "Estimado usuario, complete todos los campos obligatorios.\nVerifique cada uno de los Grupos.",
                    title: "Validación de Campos",
                  });
                }}
              >
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Fecha:</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        defaultValue={formData.date}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Provincia:</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        defaultValue={formData.province}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Ciudad:</Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formData.city}
                        onChange={(e) =>
                          setFormData({ ...formData, city: e.target.value })
                        }
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {cities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Dirección:</Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formData.department}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            department: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {departments.map((department) => (
                          <option key={department} value={department}>
                            {department}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Área de Trabajo:</Form.Label>
                      <Form.Control
                        as={"select"}
                        defaultValue={formData.work_area}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            work_area: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="ADMINISTRATIVA">ADMINISTRATIVA</option>
                        <option value="OPERATIVA">OPERATIVA</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Nivel más alto de instrucción:</Form.Label>
                      <Form.Control
                        as={"select"}
                        defaultValue={formData.academic_background}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            academic_background: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="NINGUNO">NINGUNO</option>
                        <option value="EDUCACIÓN BÁSICA">
                          EDUCACIÓN BÁSICA
                        </option>
                        <option value="EDUCACIÓN MEDIA">EDUCACIÓN MEDIA</option>
                        <option value="BACHILLERATO">BACHILLERATO</option>
                        <option value="TÉCNICO/TECNOLÓGICO">
                          TÉCNICO/TECNOLÓGICO
                        </option>
                        <option value="TERCER NIVEL">TERCER NIVEL</option>
                        <option value="CUARTO NIVEL">CUARTO NIVEL</option>
                        <option value="OTRO">OTRO</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Antigüedad, años de experiencia dentro de la empresa o
                        institución:
                      </Form.Label>
                      <Form.Control
                        as={"select"}
                        defaultValue={formData.years_in_the_institution}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            years_in_the_institution: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="0-2 AÑOS">0-2 AÑOS</option>
                        <option value="3-10 AÑOS">3-10 AÑOS</option>
                        <option value="11-20 AÑOS">11-20 AÑOS</option>
                        <option value="IGUAL O SUPERIOR A 21">
                          IGUAL O SUPERIOR A 21
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Edad del trabajador o servidor:</Form.Label>
                      <Form.Control
                        as={"select"}
                        defaultValue={formData.age}
                        onChange={(e) =>
                          setFormData({ ...formData, age: e.target.value })
                        }
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="16-24 AÑOS">16-24 AÑOS</option>
                        <option value="25-34 AÑOS">25-34 AÑOS</option>
                        <option value="35-43 AÑOS">35-43 AÑOS</option>
                        <option value="44-52 AÑOS">44-52 AÑOS</option>
                        <option value="IGUAL O SUPERIOR A 53">
                          IGUAL O SUPERIOR A 53
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Auto-identificación étnica:</Form.Label>
                      <Form.Control
                        as={"select"}
                        defaultValue={formData.ethnicity}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            ethnicity: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="INDÍGENA">INDÍGENA</option>
                        <option value="MESTIZO/A">MESTIZO/A</option>
                        <option value="MONTUBIO/A">MONTUBIO/A</option>
                        <option value="AFRO - ECUATORIANO">
                          AFRO - ECUATORIANO
                        </option>
                        <option value="BLANCO/A">BLANCO/A</option>
                        <option value="OTRO">OTRO</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Sexo del trabajador o servidor:</Form.Label>
                      <Form.Control
                        as={"select"}
                        defaultValue={formData.sex}
                        onChange={(e) =>
                          setFormData({ ...formData, sex: e.target.value })
                        }
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="HOMBRE">HOMBRE</option>
                        <option value="MUJER">MUJER</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                {questionsAndGroups.length > 0 && (
                  <GroupedQuestionTabs
                    data={questionsAndGroups}
                    selectedOptions={formData.questions_responses}
                    setSelectedOptions={(value) =>
                      setFormData({
                        ...formData,
                        questions_responses: [...value],
                      })
                    }
                    required
                  />
                )}
                <div style={{ textAlign: "center", justifyContent: "center" }}>
                  <Button
                    style={{ marginTop: "1rem" }}
                    variant="success"
                    type="submit"
                    size="lg"
                    onClick={() => setFirstSubmit(true)}
                  >
                    Enviar Información
                  </Button>
                </div>
              </Form>
            </Container>
          </Container>
          <div
            style={{
              backgroundColor: "rgb(34, 123, 67)",
              borderRadius: "3px",
              display: "block",
              position: "fixed",
              bottom: "0",
              marginTop: "52px",
              width: "100%",
              right: 0,
              zIndex: 9999,
            }}
          >
            <p
              style={{
                color: "white",
                padding: ".6rem 1.4rem",
                margin: 0,
                fontWeight: "300",
                fontSize: "small",
              }}
            >
              ©Copyright <strong>Prefectura de Manabí</strong>. All rights
              reserved.
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default PsychosocialSurvey;
